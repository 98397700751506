import { useCallback, useEffect, useState } from 'react';

import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { ToastType, notice } from 'components/ToastNotification';
import { ROUTES } from 'constants/routes';
import { useBoolean, useRouter, useUserProfile } from 'hooks';

export const useRecentlyLoggedInUsersState = () => {
  const { setSelectedRecentlyLoggedInUser, recentlyLoggedInUsers, setRecentlyLoggedInUsers } = useAppStore(
    store => ({
      recentlyLoggedInUsers: store.recentlyLoggedInUsers,
      setRecentlyLoggedInUsers: store.setRecentlyLoggedInUsers,
      setSelectedRecentlyLoggedInUser: store.setSelectedRecentlyLoggedInUser,
    }),
    shallow
  );

  const { cognitoUser } = useUserProfile();

  const { navigate, back } = useRouter();

  const [selectedEmail, setSelectedEmail] = useState<string | null>(null);
  const [isRemoveDialogOpen, openRemoveDialog, closeRemoveDialog] = useBoolean();

  const openRemoveDialogHandler = useCallback((email: string) => {
    setSelectedEmail(email);
    openRemoveDialog();
  }, []);

  const onClickRemoveUserHandler = () => {
    const updatedUsers = recentlyLoggedInUsers.filter(user => user.email !== selectedEmail);
    setRecentlyLoggedInUsers(updatedUsers);
    closeRemoveDialog();
    notice(ToastType.SUCCESS, 'User has been removed successfully');
    if (recentlyLoggedInUsers.length === 1) navigate(ROUTES.signIn, { replace: true });
  };

  const onClickUserItemHandler = useCallback(
    (email: string) => {
      const selectedUser = recentlyLoggedInUsers.find(user => user.email === email);
      setSelectedRecentlyLoggedInUser(selectedUser);

      navigate(ROUTES.recentlyLoggedInUsersSignIn);
    },
    [recentlyLoggedInUsers]
  );

  useEffect(() => {
    if (!recentlyLoggedInUsers.length || cognitoUser) back();
  }, []);

  const onClickChangeUserHandler = () => navigate(ROUTES.signIn);

  return {
    isRemoveDialogOpen,
    recentlyLoggedInUsers,
    selectedEmail,
    closeRemoveDialog,
    onClickRemoveUserHandler,
    openRemoveDialogHandler,
    onClickUserItemHandler,
    onClickChangeUserHandler,
  };
};
