import { FC } from 'react';

import { Box, Grid } from '@mui/material';

import { MDTabs } from 'components/MDTabs';

import { TABS } from './config';
import { GlobalLibrary } from './pages';
import { PracticeLibrary } from './pages';
import { useCatalogState } from './useCatalog.state';

function renderTabValueContent(tabValue: number) {
  switch (tabValue) {
    case 1:
      return <PracticeLibrary />;

    default:
      return <GlobalLibrary />;
  }
}

export const Catalog: FC = () => {
  const { handleSetTabValue, tabValue } = useCatalogState();

  return (
    <Box component="section">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ maxWidth: 400, width: 1 }}>
            <MDTabs styleVariant="folder" value={tabValue} tabsData={TABS} handleSetTabValue={handleSetTabValue} />
          </Box>
          {renderTabValueContent(tabValue)}
        </Grid>
      </Grid>
    </Box>
  );
};
