import { FC } from 'react';

import { ButtonGroup } from '@mui/material';

import { Icon } from 'components/Icon';
import MDButton from 'components/MDButton';

export const ActionMenu: FC<{
  onCopy?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  className?: string;
  sx?: Parameters<typeof Icon>[0]['sx'];
}> = ({ className, onCopy, onDelete, onEdit, sx }) => {
  return (
    <ButtonGroup
      size="small"
      className={className}
      sx={theme => ({
        transition: theme.transitions.create('all', {
          duration: theme.transitions.duration.short,
          easing: theme.transitions.easing.easeOut,
        }),
        '& button': {
          maxWidth: 45,
          bgcolor: theme.palette.common.white,
          ':hover': {
            opacity: 1,
            bgcolor: theme.palette.grey[100],
          },
        },
        opacity: 0,
      })}
    >
      <MDButton size="small" variant="outlined" color="dark" onClick={onDelete}>
        <Icon type="trashBin" width={14} height={14} />
      </MDButton>
    </ButtonGroup>
  );
};
