import { FC } from 'react';

import { Box, Skeleton, Stack } from '@mui/material';
import { PromptItemResponse } from 'apiServices';

import { FormActions } from 'components/Form';
import { RHFTextField } from 'components/HookForm';
import { Scrollbar } from 'components/ScrollBar';
import { TagEditor } from 'pages/PromptLibrary/components';

import { useUpdatePromptTabContentState } from './useUpdatePromptTabContent.state';

export type UpdatePromptTabContentProps = { isLoading: boolean; prompt: PromptItemResponse };

export const UpdatePromptTabContent: FC<UpdatePromptTabContentProps> = ({ isLoading, prompt }) => {
  const { control, isDirty, isSubmitting, errors, isSubmitButtonDisabled, onFormSubmitHandler, register } =
    useUpdatePromptTabContentState({ prompt });

  return (
    <>
      <Box
        component="form"
        sx={{
          position: 'relative',
          flexGrow: 1,
        }}
        onSubmit={onFormSubmitHandler}
      >
        <Stack
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: 1,
            height: 1,
            overflowY: 'auto',
          }}
        >
          {isLoading ? (
            <Stack height={1} p={2.5} spacing={2}>
              <Skeleton variant="rounded" width="100%" height={56} />
              <Skeleton variant="rounded" width="100%" height={56} sx={{ flexGrow: 1 }} />
              <Skeleton variant="rounded" width="100%" height={112} />
            </Stack>
          ) : (
            <Scrollbar sx={{ height: 1 }}>
              <Stack height={1} p={2.5} spacing={2}>
                <RHFTextField
                  register={register}
                  registerName="name"
                  registerErrors={errors.name?.message}
                  fullWidth
                  placeholder="Enter Prompt Name"
                  label="Prompt Name"
                />

                <TagEditor isRequired control={control} name="text" placeholder="Enter Text" label="Text" />

                <RHFTextField
                  register={register}
                  registerName="comment"
                  registerErrors={errors.comment?.message}
                  fullWidth
                  placeholder="Enter Comment"
                  label="Comment"
                  multiline
                  minRows={4}
                  maxRows={6}
                />
              </Stack>
            </Scrollbar>
          )}
        </Stack>
      </Box>
      <FormActions
        isDisabled={isSubmitButtonDisabled}
        isLoading={isSubmitting}
        onFormSubmitHandler={onFormSubmitHandler}
        isDirtyForm={isDirty}
        backRoute="auto"
      />
    </>
  );
};
