import { FC } from 'react';

import { MoreHorizOutlined } from '@mui/icons-material';
import { Divider, DrawerProps, Stack } from '@mui/material';

import { Icon } from 'components/Icon';
import MDButton from 'components/MDButton';
import { ActiveStatusChip } from 'components/MDChip';
import { ActionMenuItem, ActionMenuList } from 'components/MenuPopover';
import { SideMenuRoot } from 'components/SideMenu';
import { usePopover } from 'hooks';

type RelatedVisitsSideMenuProps = DrawerProps & {
  children: React.ReactNode;
  onClickCloseButton: () => void;
  menuItems: ActionMenuItem[];
};

export const RelatedVisitsSideMenu: FC<RelatedVisitsSideMenuProps> = ({
  onClickCloseButton,
  children,
  menuItems,
  ...restProps
}) => {
  const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

  return (
    <>
      <SideMenuRoot
        anchor="right"
        slotProps={{
          backdrop: {
            sx: {
              opacity: '0 !important',
            },
          },
        }}
        customWidth={550}
        {...restProps}
      >
        <Stack
          height={54}
          px={{ xs: 2, sm: 3 }}
          py={1.5}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <ActiveStatusChip isActive />
          <Stack
            gap={1}
            direction="row"
            alignItems="center"
            sx={{
              '& button': {
                minWidth: 'unset',
                maxWidth: 30,
                maxHeight: 32,
              },
            }}
          >
            {!!menuItems.length && (
              <MDButton size="small" variant="outlined" color="dark" onClick={handleOpenPopover} fullWidth>
                <MoreHorizOutlined fontSize="medium" />
              </MDButton>
            )}

            <MDButton size="small" variant="outlined" color="dark" fullWidth onClick={onClickCloseButton}>
              <Icon
                type="closeIcon"
                sx={{
                  width: 14,
                  height: 14,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              />
            </MDButton>
          </Stack>
        </Stack>

        <Divider />

        {children}
      </SideMenuRoot>

      <ActionMenuList onClose={handleClosePopover} open={openPopover} menuItems={menuItems} />
    </>
  );
};
