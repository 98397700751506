import React, { FC, useEffect, useMemo, useState } from 'react';

import { Autocomplete, AutocompleteInputChangeReason, Box, FormControlLabel, Stack, Switch } from '@mui/material';
import { DictionaryCityItem, DictionaryItem } from 'apiServices';

import { MDInput } from 'components/MDInput';
import { FilterMenuPopoverButtons } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import { useGeoData } from 'hooks/useGeoData';
import { usePatientEnums } from 'hooks/usePatientEnums';
import { getFilterApplyButtonStatus, getFilterResetButtonStatus } from 'utils/helpers';

import { FiltersInitialState, FiltersMenuContentProps } from '../types';
import { FiltersInitialStateValue } from '../usePractices.state';

export const FiltersMenuContent: FC<FiltersMenuContentProps> = ({
  appliedFilters,
  setAppliedFilters,
  closeMenuHandler,
}) => {
  const [filters, setFilters] = useState<FiltersInitialState>(appliedFilters);

  const { fetchPatientGenderEnums, fetchPatientEthnicGroupsEnums, fetchPatientBioGenderEnums } = usePatientEnums();

  useEffect(() => {
    fetchPatientEthnicGroupsEnums();
    fetchPatientGenderEnums();
    fetchPatientBioGenderEnums();
  }, []);

  const {
    countries,
    countryStates,
    onChangeCityInputValueHandler,
    onChangeCountryInputValueHandler,
    onSelectCountryHandler,
    stateCities,
    isCountriesLoading,
    isCountryStatesLoading,
    isStateCitiesLoading,
    onSelectStateHandler,
  } = useGeoData({ currentStateISOcode: filters.state_iso_code?.isoCode || '' });

  const handleChange =
    (field: keyof FiltersInitialState) =>
    (_: React.SyntheticEvent<Element, Event>, value: DictionaryCityItem | DictionaryItem | null) => {
      setFilters(prev => ({ ...prev, [field]: value }));
    };

  const onChageCountryValueHandler = async (_: React.SyntheticEvent<Element, Event>, value: DictionaryItem) => {
    setFilters(prev => ({ ...prev, country_iso_code: value }));
    await onSelectCountryHandler(value);
  };

  const onChageStateValueHandler = async (_: React.SyntheticEvent<Element, Event>, value: DictionaryItem) => {
    setFilters(prev => ({ ...prev, state_iso_code: value }));
    await onSelectStateHandler(value);
  };

  const onChangeSwitchHandler = (_: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    setFilters(prev => ({ ...prev, isShowDeactivated: checked }));
  };

  const onClickResetFiltersButtonHandler = () => setFilters(FiltersInitialStateValue);

  const onClickApplyFiltersButtonHandler = async () => {
    setAppliedFilters(filters);
    closeMenuHandler();
  };

  const filterEntries = Object.entries(filters);

  const isApplyButtonDisabled = useMemo(
    () => getFilterApplyButtonStatus({ appliedFilters, filterEntries }),
    [filters, appliedFilters]
  );

  const isResetButtonDisabled = useMemo(
    () => getFilterResetButtonStatus({ filterEntries, defaultFilters: FiltersInitialStateValue }),
    [filters]
  );

  const renderAutocomplete = <TOption,>({
    getOptionLabel,
    isLoading,
    isOptionEqualToValue,
    label,
    onChange,
    onInputChange,
    options,
    placeholder,
    value,
    isDisabled,
  }: {
    value: TOption;
    onChange: (event: React.SyntheticEvent<Element, Event>, value: TOption) => void;
    options: TOption[];
    label: string;
    placeholder: string;
    isLoading: boolean;
    isOptionEqualToValue: (option: TOption, value: TOption) => boolean;
    getOptionLabel: (option: TOption) => string;
    onInputChange?: (
      _: React.SyntheticEvent<Element, Event>,
      value: string,
      reason: AutocompleteInputChangeReason
    ) => Promise<void>;
    isDisabled?: boolean;
  }) => (
    <Autocomplete
      value={value}
      onChange={onChange}
      onInputChange={onInputChange}
      getOptionLabel={getOptionLabel}
      options={options}
      freeSolo={false}
      isOptionEqualToValue={isOptionEqualToValue}
      disableClearable={false}
      disabled={isDisabled}
      renderInput={params => (
        <MDInput
          isLoading={isLoading}
          {...params}
          label={label}
          placeholder={placeholder}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      )}
    />
  );

  return (
    <Stack position="relative">
      <Scrollbar style={{ maxHeight: 410 }}>
        <Stack height={1} spacing={2} py={1}>
          <Stack spacing={2.5} mt={2}>
            <Box>
              <FormControlLabel
                onChange={onChangeSwitchHandler}
                checked={filters.isShowDeactivated}
                control={<Switch />}
                label="Show deactivated"
                labelPlacement="start"
              />
            </Box>
            {renderAutocomplete({
              value: filters.country_iso_code,
              options: countries?.items || [],
              isLoading: isCountriesLoading,
              getOptionLabel: option => option.isoName,
              isOptionEqualToValue: (option, value) => option.isoCode === value.isoCode,
              onChange: onChageCountryValueHandler,
              onInputChange: onChangeCountryInputValueHandler,
              label: 'Country',
              placeholder: 'Select a country',
            })}

            {renderAutocomplete({
              value: filters.state_iso_code,
              options: countryStates,
              isLoading: isCountryStatesLoading,
              getOptionLabel: option => option.isoName,
              isOptionEqualToValue: (option, value) => option.isoCode === value.isoCode,
              onChange: onChageStateValueHandler,
              label: 'State',
              placeholder: !filters.country_iso_code ? 'First select a country' : 'Select state',
              isDisabled: !filters.country_iso_code,
            })}

            {renderAutocomplete({
              value: filters.city_id,
              options: stateCities,
              isLoading: isStateCitiesLoading,
              getOptionLabel: option => option.name,
              isOptionEqualToValue: (option, value) => option.id === value.id,
              onChange: handleChange('city_id'),
              onInputChange: onChangeCityInputValueHandler,
              label: 'City',
              placeholder: !filters.state_iso_code ? 'First select a state' : 'Select a city',
              isDisabled: !filters.state_iso_code,
            })}
          </Stack>
        </Stack>
      </Scrollbar>
      <FilterMenuPopoverButtons
        isClearButtonDisabled={isResetButtonDisabled}
        onClickClearButtonHandler={onClickResetFiltersButtonHandler}
        isApplyButtonDisabled={isApplyButtonDisabled}
        onClickApplyButtonHandler={onClickApplyFiltersButtonHandler}
      />
    </Stack>
  );
};
