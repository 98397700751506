import { ROUTES } from 'constants/routes';
import { useBoolean } from 'hooks/useBoolean';
import { useRouter } from 'hooks/useRouter';

type UseDiscardChangesDialogWindowProps = {
  isDirty: boolean;
  backRoute: ROUTES | 'auto';
};

export const useDiscardChangesDialogWindow = ({ backRoute, isDirty }: UseDiscardChangesDialogWindowProps) => {
  const { navigate, back } = useRouter();
  const [isOpenDiscardDialogWindow, openDiscardDialogWindow, closeDiscardDialogWindow] = useBoolean();

  const onClickCancelButtonHandler = () => {
    if (isDirty) return openDiscardDialogWindow();

    onClickDiscardButtonHandler();
  };

  const onClickDiscardButtonHandler = () => {
    if (backRoute === 'auto') return back();

    navigate(backRoute);
  };

  return {
    isOpenDiscardDialogWindow,
    openDiscardDialogWindow,
    onClickDiscardButtonHandler,
    closeDiscardDialogWindow,
    onClickCancelButtonHandler,
  };
};
