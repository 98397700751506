import { FC } from 'react';

import { Box, Card, Grid } from '@mui/material';
import { create_gl_ba_album_pair, upload_image_gl_ba_album_pair } from 'apiServices';

import beforeAfterAlbumFallback from 'assets/icons/before_after_album_fallback.svg';
import { FormActions } from 'components/Form';
import { FormProvider } from 'components/HookForm';
import { getActivateMenuItem } from 'components/MenuPopover';
import { EntityHeader, EntityHeaderSkeleton } from 'components/Widgets';
import { ROUTES } from 'constants/routes';
import {
  ActivateDeactivateDialogWindow,
  AddBeforeAfterImagesDialogWindow,
  AlbumProfileFormSection,
  BACardItem,
  BeforeAfterPhotos,
} from 'pages/BeforeAfter';

import { useGLBeforeAfterAlbumProfileState } from './useGLBeforeAfterAlbumProfile.state';

export const GlobalLibraryBAProfile: FC = () => {
  const {
    b_a_gl_album,
    isLoading,
    closeActivateDialog,
    closeDeactivateDialog,
    isActionPending,
    openActivateDialog,
    openDeactivateDialog,
    formMethods,
    isActivateDialogOpen,
    isDeactivateDialogOpen,
    onActivateAlbumHandler,
    onDeactivateAlbumHandler,
    b_a_gl_album_pairs,
    isAlbumPairLoading,
    ref,
    isFetchMoreLoading,
    onDeleteAvatarHandler,
    onUploadAvatarHandler,
    onFormSubmitHandler,
    checkedIds,
    onCheckItemHandler,
    onResetChekedIds,
    getMenuItems,
    isOpenAddAlbumImagesDialogWindow,
    openAddAlbumImagesDialogWindow,
    closeAddAlbumImagesDialogWindow,
    albumId,
    fetchAlbumPairs,
    albumPairActionMethods,
    deleteAlbumPairHandler,
    isRoleAdmin,
  } = useGLBeforeAfterAlbumProfileState();

  return (
    <Box
      component="section"
      sx={{
        display: 'flex',
        height: 1,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card
            sx={{
              ...(!isLoading && {
                height: 1,
              }),
            }}
          >
            {isLoading ? (
              <EntityHeaderSkeleton isActiveStatus={isRoleAdmin} avatarVariant="rounded" isActionButton={isRoleAdmin} />
            ) : (
              <EntityHeader
                title={b_a_gl_album?.name}
                subtitle={b_a_gl_album?.description}
                activeStatusProps={
                  isRoleAdmin && {
                    isActive: b_a_gl_album?.isActive,
                  }
                }
                actionMenuItems={
                  isRoleAdmin
                    ? [
                        getActivateMenuItem(
                          b_a_gl_album?.isActive,
                          b_a_gl_album?.isActive ? openDeactivateDialog : openActivateDialog
                        ),
                      ]
                    : []
                }
                avatarProps={{
                  src: b_a_gl_album?.coverThumbUrl,
                  fallbackSrc: beforeAfterAlbumFallback,
                  initialPhotoUrl: b_a_gl_album?.coverUrl,
                  onCreateFileHandler: onUploadAvatarHandler,
                  onDeleteFileHandler: onDeleteAvatarHandler,
                  formDataImageName: 'cover_image',
                  variant: 'rounded',
                  cropShape: 'rect',
                  isDisabled: !isRoleAdmin,
                }}
              />
            )}
            <FormProvider methods={formMethods} onSubmit={onFormSubmitHandler}>
              <AlbumProfileFormSection isLoading={isLoading} isViewOnly={!isRoleAdmin} />
              <FormActions
                isDisabled={formMethods.formState.isSubmitting}
                isLoading={formMethods.formState.isSubmitting}
                onFormSubmitHandler={onFormSubmitHandler}
                isDirtyForm={formMethods.formState.isDirty}
                backRoute={ROUTES.beforeAfter}
                sx={{
                  borderRadius: 0,
                  ...(formMethods.formState.isDirty && {
                    borderBottom: ({ borders }) => `${borders.borderWidth[1]} solid ${borders.borderColor}`,
                  }),
                }}
              />
            </FormProvider>

            <BeforeAfterPhotos
              data={b_a_gl_album_pairs.items}
              totalItems={b_a_gl_album_pairs.total}
              isLoading={isAlbumPairLoading}
              isLoadingNext={isFetchMoreLoading}
              checkedImagePairIdSize={checkedIds.size}
              onResetImagePairChekedIds={onResetChekedIds}
              openAddAlbumImagesDialogWindow={isRoleAdmin && openAddAlbumImagesDialogWindow}
              albumPairActionMethods={albumPairActionMethods}
              deleteAlbumPairHandler={deleteAlbumPairHandler}
              renderItem={(item, index) => {
                return (
                  <BACardItem
                    id={item?.id}
                    title={item?.name}
                    coverRightThumbURL={item?.imageRightThumbUrl}
                    coverLeftThumbURL={item?.imageLeftThumbUrl}
                    isActive={item?.isActive}
                    onChangeCheckboxHandler={isRoleAdmin && onCheckItemHandler}
                    checkedIds={checkedIds}
                    getMenuItems={getMenuItems}
                    // TODO: click on card
                    onCardClickHandler={() => {}}
                    lastAlbumCardRef={b_a_gl_album_pairs.items.length - 1 === index ? ref : null}
                  />
                );
              }}
            />
          </Card>
        </Grid>
      </Grid>

      {isOpenAddAlbumImagesDialogWindow && (
        <AddBeforeAfterImagesDialogWindow
          isOpenAddAllbumDialogWindow={isOpenAddAlbumImagesDialogWindow}
          closeAddAlbumDialogWindow={closeAddAlbumImagesDialogWindow}
          onFetchAlbumsHandler={fetchAlbumPairs}
          uploadImageAPIrequest={upload_image_gl_ba_album_pair}
          createBAimagePairAPIrequest={create_gl_ba_album_pair}
          albumId={albumId}
        />
      )}

      <ActivateDeactivateDialogWindow
        actionType={isDeactivateDialogOpen ? 'deactivate' : 'activate'}
        name={b_a_gl_album?.name}
        isOpen={isDeactivateDialogOpen || isActivateDialogOpen}
        isActionPending={isActionPending}
        onClose={isDeactivateDialogOpen ? closeDeactivateDialog : closeActivateDialog}
        onApprove={isDeactivateDialogOpen ? onDeactivateAlbumHandler : onActivateAlbumHandler}
        title="Album"
      />
    </Box>
  );
};
