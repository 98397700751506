export const YYYY_MM_DD_DATE_FORMAT = 'yyyy-MM-dd';

export const MM_DD_YYYY_DATE_FORMAT = 'MM/dd/yyyy';

export const DATE_OF_BIRTH_DATE_FORMAT = 'M/d/yyyy';

export const CREATED_AT_DATE_FORMAT = 'MMMM d, yyyy hh:mm a';

export const CREATED_AT_DATE_SHORT_FORMAT = 'MMM d, yyyy hh:mm a';

export const MMMM_YYYY = 'MMMM-yyyy';
