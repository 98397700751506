import { AxiosError } from 'axios';
import { Path, UseFormSetError } from 'react-hook-form';

import { ToastType, notice } from 'components/ToastNotification';
import { ERROR_MESSAGES } from 'constants/strings';

import { APIerror } from './types';

type BaseBackendErrorHandlerConfig<T> = {
  customErrorHandler?: (error: unknown) => void;
  formError?: { setError: UseFormSetError<T> };
};

export const formErrorHandler = <T extends object>({
  error,
  config,
  customErrorMessage,
}: {
  error: unknown;
  customErrorMessage?: string;
  config?: BaseBackendErrorHandlerConfig<T>;
}) => {
  const { customErrorHandler, formError } = config || {};
  const { setError } = formError || { setError: () => {} };

  console.error(error);

  if (customErrorHandler) return customErrorHandler(error);

  const isAxiosError = error instanceof AxiosError;

  if (!isAxiosError) return notice(ToastType.ERROR, customErrorMessage || ERROR_MESSAGES.smt_went_wrong);

  const errorResponse = error?.response?.data as APIerror;

  if (!errorResponse || !('detail' in errorResponse))
    return notice(ToastType.ERROR, customErrorMessage || ERROR_MESSAGES.smt_went_wrong);

  if (error?.response?.status === 422 && Array.isArray(errorResponse.detail)) {
    return errorResponse.detail.forEach(err => {
      if (err?.loc?.[0] === 'body') {
        const propertyPathes = err?.loc
          .slice(1)
          .map(path => {
            if (typeof path === 'number') return `[${path}]`;

            return `.${path}`;
          })
          .join('')
          .substring(1);

        setError(propertyPathes as Path<T>, { message: err?.msg || '' }, { shouldFocus: true });
      }
    });
  }

  if (typeof errorResponse.detail === 'string') {
    return notice(ToastType.ERROR, errorResponse.detail);
  } else return notice(ToastType.ERROR, customErrorMessage || ERROR_MESSAGES.smt_went_wrong);
};
