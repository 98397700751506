import { useState } from 'react';

import { ToastType, notice } from 'components/ToastNotification';

type UseCopyToClipboardProps = {
  delay?: number;
  isShowToast?: boolean;
};

export const useCopyToClipboard = ({ delay = 2000, isShowToast }: UseCopyToClipboardProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async (text: string) => {
    if (isCopied) return;
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);

      if (isShowToast) notice(ToastType.SUCCESS, 'Successfully copied!');

      setTimeout(() => {
        setIsCopied(false);
      }, delay);
    } catch (error) {
      notice(ToastType.ERROR, 'Failed to copy, please try again.');
    }
  };

  return { isCopied, copyToClipboard };
};
