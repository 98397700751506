import { FC, PropsWithChildren } from 'react';

import { Navigate } from 'react-router-dom';
import { useAppStore } from 'store';

import { ROUTES } from 'constants/routes';
import { useUserProfile } from 'hooks/useUserProfile';

export const PrivateRouter: FC<PropsWithChildren> = ({ children }) => {
  const { cognitoUser } = useUserProfile();

  const recentlyLoggedInUsers = useAppStore(store => store.recentlyLoggedInUsers);

  if (cognitoUser) return <>{children}</>;

  const authRoute = recentlyLoggedInUsers?.length ? ROUTES.recentlyLoggedInUsers : ROUTES.signIn;

  return <Navigate to={authRoute} replace />;
};
