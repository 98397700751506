import { StateCreator } from 'zustand';

import { RecentlyLoggedInUsersInitialState, RecentlyLoggedInUsersSliceType } from './types';

const initialState: RecentlyLoggedInUsersInitialState = {
  recentlyLoggedInUsers: [],
  selectedRecentlyLoggedInUser: null,
};

export const RecentlyLoggedInUsersSlice: StateCreator<
  RecentlyLoggedInUsersSliceType,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  RecentlyLoggedInUsersSliceType
> = set => {
  return {
    ...initialState,
    setRecentlyLoggedInUsers: users => {
      set(state => {
        state.recentlyLoggedInUsers = users;
      });
    },
    setSelectedRecentlyLoggedInUser: user => {
      set(state => {
        state.selectedRecentlyLoggedInUser = user;
      });
    },
  };
};
