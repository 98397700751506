import { useState } from 'react';

import {
  ExpertRoleEnum,
  PracticeEnum,
  PracticeEnums,
  getAllPracticeEnums,
  getPracticePersonTitlesEnum,
  getPracticeRolesEnum,
} from 'apiServices';

import { ToastType, notice } from 'components/ToastNotification';

export const usePracticeEnums = () => {
  const [practiceEnums, setPracticeEnums] = useState<PracticeEnums[]>([]);
  const [isAllPracticeEnumsLoading, setIsAllPracticeEnumsLoading] = useState(false);

  const fetchAllPracticeEnums = async () => {
    try {
      setIsAllPracticeEnumsLoading(true);
      const { data } = await getAllPracticeEnums();
      setPracticeEnums(data);
      setIsAllPracticeEnumsLoading(false);
    } catch (err) {
      console.error(err);
      notice(ToastType.ERROR, 'Failed to get practice enums, please try again!');
    } finally {
      setIsAllPracticeEnumsLoading(false);
    }
  };

  const [practicePersonTitlesEnum, setPracticePersonTitlesEnum] = useState<PracticeEnum[]>([]);
  const [isPracticePersonTitlesEnumLoading, setIsPracticePersonTitlesEnumLoading] = useState(false);

  const fetchPracticePersonTitlesEnum = async () => {
    try {
      setIsPracticePersonTitlesEnumLoading(true);

      const { data } = await getPracticePersonTitlesEnum();

      setPracticePersonTitlesEnum(data);
      setIsPracticePersonTitlesEnumLoading(false);
    } catch (err) {
      console.error(err);
      notice(ToastType.ERROR, 'Failed to get practice person titles, please try again!');
    } finally {
      setIsPracticePersonTitlesEnumLoading(false);
    }
  };

  const [practiceRolesEnum, setPracticeRolesEnum] = useState<ExpertRoleEnum[]>([]);
  const [isPracticeRolesEnumLoading, setIsPracticeRolesEnumLoading] = useState(false);

  const fetchPracticeRolesEnum = async () => {
    try {
      setIsPracticeRolesEnumLoading(true);

      const { data } = await getPracticeRolesEnum();

      setPracticeRolesEnum(data);

      setIsPracticeRolesEnumLoading(false);
    } catch (err) {
      console.error(err);
      notice(ToastType.ERROR, 'Failed to get practice roles, please try again!');
    } finally {
      setIsPracticeRolesEnumLoading(false);
    }
  };

  return {
    isAllPracticeEnumsLoading,
    practiceEnums,
    fetchAllPracticeEnums,
    isPracticePersonTitlesEnumLoading,
    practicePersonTitlesEnum,
    fetchPracticePersonTitlesEnum,
    practiceRolesEnum,
    isPracticeRolesEnumLoading,
    fetchPracticeRolesEnum,
  };
};
