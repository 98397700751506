import { FC } from 'react';

import { Box, Divider, Skeleton, Stack } from '@mui/material';

import { ActiveStatusChipSkeleton } from 'components/MDChip';

type EntityHeaderSkeletonProps = {
  tabsAmount?: number;
  isNote?: boolean;
  isActiveStatus?: boolean;
  isActionButton?: boolean;
  avatarVariant?: 'circular' | 'rounded';
  isAvatar?: boolean;
};

export const EntityHeaderSkeleton: FC<EntityHeaderSkeletonProps> = ({
  tabsAmount,
  isNote,
  isActiveStatus,
  isActionButton,
  avatarVariant = 'circular',
  isAvatar = true,
}) => {
  return (
    <>
      <Box sx={{ p: 2 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Skeleton variant="rounded" width={80} height={24} />
          <Stack direction="row" alignItems="center" gap={1}>
            {isActiveStatus && <ActiveStatusChipSkeleton />}
            {isActionButton && <Skeleton variant="rounded" width={30} height={32} />}
          </Stack>
        </Stack>

        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          alignItems={{ xs: 'center', sm: 'flex-start' }}
          gap={2}
          width={1}
          justifyContent="space-between"
        >
          <Stack direction={{ xs: 'column', sm: 'row' }} gap={2} alignItems={{ xs: 'center', sm: 'flex-start' }}>
            {isAvatar && <Skeleton variant={avatarVariant} width={70} height={70} />}

            <Stack sx={{ position: 'relative', flexGrow: 1 }} spacing={1} justifyContent="space-between">
              <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" gap={1}>
                <Skeleton variant="rounded" width={200} height={24} />
                <Skeleton variant="rounded" width={120} height={28} />
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent={{ xs: 'center', sm: 'flex-start' }}
                spacing={1}
                mt={{ xs: 1, sm: 0 }}
              >
                <Skeleton variant="rounded" width={150} height={20} />
              </Stack>
            </Stack>
          </Stack>

          {isNote && (
            <Skeleton
              variant="rounded"
              sx={{ width: { xs: '100%', lg: 200 }, borderRadius: 1, height: { xs: 100, sm: 50 } }}
            />
          )}
        </Stack>

        {tabsAmount ? (
          <Stack direction="row" gap={1} mt={2}>
            {Array.from({ length: tabsAmount }).map((_, index) => (
              <Skeleton variant="rounded" width={100} height={32} sx={{ borderRadius: 1 }} key={index} />
            ))}
          </Stack>
        ) : null}
      </Box>
      <Divider />
    </>
  );
};
