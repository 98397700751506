import { FC, memo } from 'react';

import { Stack, Typography } from '@mui/material';

import { Scrollbar } from 'components/ScrollBar';

import { RelatedVisitDetailsSection } from './RelatedVisitDetailsSection';

export const ViewRelatedVisitSideMenuContent: FC = memo(() => {
  return (
    <Scrollbar>
      <Stack gap={1} p={{ xs: 2, sm: 3 }}>
        <RelatedVisitDetailsSection />

        <Stack gap={0.75}>
          <Typography variant="body2" fontSize={14} fontWeight={400}>
            Summary
          </Typography>
          <Typography ml={1} variant="body2" fontSize={14} fontWeight={400}>
            Aesthetic Results: Achieve a harmonious and natural appearance in the upper third of the face, addressing
            client concerns such as wrinkle reduction, brow lifting, or symmetry correction. Functionality: Preserve or
            enhance facial expressions without creating a sense of stiffness or limiting mobility. Safety of Procedure:
            Employ modern techniques and technologies to minimize risks and side effects, such as bruising, swelling, or
            infection. Comfortable Recovery: Ensure a swift and smooth recovery process, with clear guidance to help
            clients return to their routine quickly. Emotional Confidence: Instill confidence in clients regarding their
            decision, providing psychological support before and after the procedure. Personalized Approach: Develop a
            tailored surgical plan considering individual anatomy, age, gender, and personal preferences. Long-Lasting
            Results: Deliver outcomes that are durable over time, accounting for the natural aging process.
          </Typography>
        </Stack>

        <Stack gap={0.75}>
          <Typography variant="body2" fontSize={14} fontWeight={400}>
            Follow-up Question
          </Typography>
          <Typography ml={1} variant="body2" fontSize={14} fontWeight={400}>
            Aesthetic Results: Achieve a harmonious and natural appearance in the upper third of the face, addressing
            client concerns such as wrinkle reduction, brow lifting, or symmetry correction. Functionality: Preserve or
            enhance facial expressions without creating a sense of stiffness or limiting mobility. Safety of Procedure:
            Employ modern techniques and technologies to minimize risks and side effects, such as bruising, swelling, or
            infection.
          </Typography>
        </Stack>
      </Stack>
    </Scrollbar>
  );
});
