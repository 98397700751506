import { FC } from 'react';

import { RatingProps, Stack, Typography } from '@mui/material';

import { MDRating } from 'components/MDRating';

type RatingItemProps = RatingProps & {
  title: string;
};

export const RatingItem: FC<RatingItemProps> = ({ title, value, ...otherRatingProps }) => {
  return (
    <Stack alignItems="flex-start">
      <Typography variant="caption" color="text.secondary" fontWeight={500}>
        {title}
      </Typography>

      <MDRating name="accuracy" value={value || 0} precision={0.5} readOnly {...otherRatingProps} />
    </Stack>
  );
};
