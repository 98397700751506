import { PropsWithChildren } from 'react';

import { Close } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';

import { Scrollbar } from 'components/ScrollBar';

import { SideMenuRoot } from './SideMenuRoot';

type SideMenuProps = PropsWithChildren & {
  isOpen: boolean;
  title: string;
  titleDescription?: string;
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
  customWidth?: number;
};

export const SideMenu = ({ onClose, isOpen, title, titleDescription, children, customWidth = 400 }: SideMenuProps) => {
  return (
    <SideMenuRoot anchor="right" open={isOpen} onClose={onClose} customWidth={customWidth}>
      <Scrollbar sx={{ height: 1, display: 'flex', '.simplebar-content': { height: 1, display: 'flex' } }}>
        <Stack py={1.5} px={2} flex={1} spacing={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={0.5}>
            <Stack>
              <Typography variant="h5" sx={{ whiteSpace: 'initial', mr: 5 }}>
                {title}
              </Typography>
              {titleDescription && (
                <Typography
                  variant="body2"
                  color="text"
                  sx={{
                    wordBreak: 'break-all',
                    whiteSpace: 'initial',
                  }}
                >
                  {titleDescription}
                </Typography>
              )}
            </Stack>

            <IconButton onClick={onClose} size="medium">
              <Close fontSize="medium" sx={{ color: theme => theme.palette.black.main }} />
            </IconButton>
          </Stack>
          {children}
        </Stack>
      </Scrollbar>
    </SideMenuRoot>
  );
};
