import { StateCreator } from 'zustand';

import { sliceResetFns } from '../../Store';
import { PromptLibraryInitialState, PromptLibrarySliceType } from './types';

const initialState: PromptLibraryInitialState = {
  prompts: {
    items: [],
    pagination: {
      page: null,
      total: null,
      size: null,
    },
  },
};

export const PromptLibrarySlice: StateCreator<
  PromptLibrarySliceType,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  PromptLibrarySliceType
> = set => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setPrompts: prompts => {
      set(state => {
        state.prompts = prompts;
      });
    },
  };
};
