export type CreateConcernFormSchema = {
  desiredOutcome: string;
  aestheticIssue: string;
  anatomicalIssue: string;
};

export const concernFormDefaultValues: CreateConcernFormSchema = {
  desiredOutcome: '',
  aestheticIssue: '',
  anatomicalIssue: '',
};
