import { TextFieldProps } from '@mui/material';
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  Path,
  RegisterOptions,
} from 'react-hook-form';
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-material-ui';

import { MDInput, MDInputPhoneRoot } from 'components/MDInput';
import { genericMemo } from 'utils/helpers';

import 'react-phone-input-material-ui/lib/bootstrap.css';

type RHFPhoneTextFieldProps<TField extends FieldValues> = Omit<PhoneInputProps, 'component'> & {
  control: Control<TField>;
  registerOptions?: RegisterOptions<TField, any>;
  registerName: Path<TField>;
  registerErrors: FieldErrors<TField> | Merge<FieldError, FieldErrorsImpl>;
  isPasswordField?: boolean;
  MUITextFieldProps?: TextFieldProps;
};

export const RHFPhoneTextField = genericMemo(
  <TField extends FieldValues>({
    value,
    label,
    onChange,
    country = 'us',
    disabled,
    MUITextFieldProps,
    control,
    registerErrors,
    registerName,
    registerOptions,
    ...rest
  }: RHFPhoneTextFieldProps<TField>): JSX.Element => {
    const errorMessage = registerName.includes('.')
      ? registerErrors?.[registerName.split('.')?.[1]]?.message
      : registerErrors?.[registerName]?.message;

    function validateAndFormatPhone(phone: string): string {
      if (!phone) return phone;

      if (phone?.startsWith('+1')) return phone;

      const restOfPhone = phone?.slice(phone?.indexOf('+') + 1);
      const updatedPhone = `+1${restOfPhone?.slice(1)}`;

      return updatedPhone;
    }

    return (
      <MDInputPhoneRoot isDisabled={disabled} isLabel={!!label}>
        <Controller
          control={control}
          name={registerName}
          render={({ field, fieldState: { error } }) => {
            return (
              <ReactPhoneInput
                label={label}
                {...field}
                country={country}
                value={validateAndFormatPhone(field.value) ?? null}
                preferredCountries={['us']}
                inputProps={{
                  InputLabelProps: {
                    shrink: true,
                  },
                  label,
                  helperText: errorMessage,
                  error: !!error,
                  ...MUITextFieldProps,
                }}
                enableSearch
                disabled={disabled}
                component={MDInput}
                countryCodeEditable={false}
                disableDropdown={true}
                {...rest}
              />
            );
          }}
        />
      </MDInputPhoneRoot>
    );
  }
);
