import { useEffect, useState } from 'react';

import {
  PLServicesSearchParams,
  activatePLService,
  deactivatePLService,
  deletePLService,
  getPLServices,
} from 'apiServices';
import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { useUserProfile } from 'hooks';
import { useTableState } from 'hooks/useTableState';
import { CATALOG_SERVICE_MESSAGES } from 'pages/Catalog/constants';
import { backendErrorHandler } from 'utils/errorHanders';

export const usePLServicesState = () => {
  const {
    PLServices: { items, total },
    setPLServices,
  } = useAppStore(
    store => ({
      PLServices: store.catalogPLData.PLServices,
      setPLServices: store.setPLServices,
    }),
    shallow
  );

  const {
    userRoles: { isRolePracticeUser, isRoleAdmin },
  } = useUserProfile();

  const [isLoading, setIsLoading] = useState(true);

  const onGetServicesHandler = async (params: Partial<PLServicesSearchParams>) => {
    !isLoading && setIsLoading(true);

    try {
      const { data } = await getPLServices({
        ...(debouncedSearchValue && { search: debouncedSearchValue }),
        is_active: !appliedFilters.isShowDeactivated,
        size: rowsPerPage,
        page,
        ...params,
      });

      setPLServices({
        items: data.items,
        page: data.page,
        pages: data.pages,
        size: data.size,
        total: data.total,
      });

      if (params?.page === 1 && page !== 1) setPage(1);
    } catch (error) {
      console.error(error);
      backendErrorHandler({
        error,
        config: { customErrorMessage: 'Failed to get practice library services, please try again!' },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const {
    onChangeSearchInputValue,
    isOpenDeleteDialogWindow,
    isActivateDialogOpen,
    isDeactivateDialogOpen,
    onChangeRowsPerPage,
    onChangePage,
    page,
    searchValue,
    rowsPerPage,
    debouncedSearchValue,
    appliedFilters,
    isResettingPage,
    onDeactivateServiceHandler,
    onDeleteServiceHandler,
    isActionPending,
    closeAddItemDialog,
    closeDeactivateDialog,
    closeFiltersMenu,
    onApplyFilters,
    isFiltersApplied,
    onActivateServiceHandler,
    actionState,
    isFiltersMenuOpen,
    openFilterMenu,
    closeDeleteDialogWindow,
    setPage,
    resetPageHandler,
    isAddItemDialogOpen,
    openAddItemDialog,
    onClickActionOptionHandler,
    closeActivateDialog,
  } = useTableState({
    fetchItems: onGetServicesHandler,
    activateItem: activatePLService,
    deleteItem: deletePLService,
    deactivateItem: deactivatePLService,
    messages: {
      activationError: CATALOG_SERVICE_MESSAGES.activateFailed,
      activationSuccess: CATALOG_SERVICE_MESSAGES.activateSuccess,
      deactivationError: CATALOG_SERVICE_MESSAGES.inactivateFailed,
      deactivationSuccess: CATALOG_SERVICE_MESSAGES.inactivateSuccess,
      deletionError: CATALOG_SERVICE_MESSAGES.deleteFailed,
      deletionSuccess: CATALOG_SERVICE_MESSAGES.deleteSuccess,
    },
  });

  useEffect(() => {
    onGetServicesHandler({ ...(isResettingPage.current && { page: 1 }) });

    resetPageHandler();
  }, [debouncedSearchValue, appliedFilters, rowsPerPage]);

  return {
    searchValue,
    onChangeSearchInputValue,
    isOpenDeleteDialogWindow,
    closeDeleteDialogWindow,
    closeFiltersMenu,
    openFilterMenu,
    isFiltersMenuOpen,
    items,
    isLoading,
    total,
    onChangeRowsPerPage,
    rowsPerPage,
    onChangePage,
    page,
    appliedFilters,
    isActionPending,
    isAddServiceDialogOpen: isAddItemDialogOpen,
    openAddServiceDialog: openAddItemDialog,
    closeAddServiceDialog: closeAddItemDialog,
    onGetServicesHandler,
    onActivateServiceHandler,
    onDeactivateServiceHandler,
    onClickActionOptionHandler,
    isDeactivateDialogOpen,
    isActivateDialogOpen,
    closeDeactivateDialog,
    closeActivateDialog,
    actionState,
    onApplyFilters,
    isFiltersApplied,
    onDeleteServiceHandler,
    isRolePracticeUser,
    isRoleAdmin,
  };
};
