import { CSSObject, ChipProps, alpha } from '@mui/material';
import { grey } from '@mui/material/colors';

import colors from 'assets/theme/base/colors';

import borders from '../base/borders';

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    soft: true;
    linkedContent: true;
  }
}

const COLORS: Extract<TypeColor, 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error'>[] = [
  'primary',
  'secondary',
  'info',
  'success',
  'warning',
  'error',
];

function getSoftColorStyles(ownerState: ChipProps): CSSObject {
  if (ownerState.color === 'default') {
    return {
      backgroundColor: alpha(colors.grey[500], 0.1),
      color: grey[800],
      border: `${borders.borderWidth[1]} solid ${alpha(grey[800], 0.25)}`,
      ...(ownerState.clickable && {
        ':hover': {
          backgroundColor: alpha(colors.grey[500], 0.2),
        },
      }),
    };
  }

  const matchedColor = COLORS.find(c => c === ownerState.color);
  if (!matchedColor) return {};

  const colorValue = colors[matchedColor]?.main || colors.primary.main;
  return {
    backgroundColor: alpha(colorValue, 0.1),
    color: colorValue,
    border: `${borders.borderWidth[1]} solid ${alpha(colorValue, 0.25)}`,
    ...(ownerState.clickable && {
      ':hover': {
        backgroundColor: alpha(colorValue, 0.2),
      },
    }),
  };
}

// TODO: rodo it. use it only for linked content: like in TAG MANAGEMENT
function getLinkedContentOverrides(): CSSObject {
  return {
    backgroundColor: 'transparent',
    border: 'none',
  };
}

function rootStyle(ownerState: ChipProps): CSSObject[] {
  const isSoft = ownerState.variant === 'soft' || ownerState.variant === 'linkedContent';
  const isLinked = ownerState.variant === 'linkedContent';

  const defaultStyles: CSSObject = {
    borderWidth: borders.borderWidth[1],
    fontSize: 14,
    fontWeight: 500,
  };

  const softVariantStyles: CSSObject = isSoft ? getSoftColorStyles(ownerState) : {};

  const linkedContentStyles: CSSObject = isLinked ? getLinkedContentOverrides() : {};

  return [defaultStyles, softVariantStyles, linkedContentStyles];
}

const chip = {
  styleOverrides: {
    root: ({ ownerState }: { ownerState: ChipProps }) => rootStyle(ownerState),
  },
};

export default chip;
