import { useEffect, useMemo, useState } from 'react';

import {
  GlobalLibrarySearchParams,
  TagListSortFieldName,
  deleteGlobalLibraryTagCategory,
  getGlobalLibraryTagCategories,
  importTagCategoryToPL,
  updateGlobalLibraryTagCategory,
} from 'apiServices';
import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { ToastType, notice } from 'components/ToastNotification';
import { useActionDialogManagement } from 'hooks/useActionDialogManagement';
import { useBoolean } from 'hooks/useBoolean';
import { useDebounce } from 'hooks/useDebounce';
import { usePopover } from 'hooks/usePopover';
import { useTable } from 'hooks/useTable';
import { useUserProfile } from 'hooks/useUserProfile';
import { FiltersInitialState, FiltersInitialStateValue } from 'pages/TagManagement';
import { backendErrorHandler } from 'utils/errorHanders';
import { checkIsFilterApplied, resolveFilterCondition } from 'utils/helpers';

export const useGlobalLibraryTagListState = () => {
  const {
    globalLibraryTagCategories: { data, total },
    setGlobalLibraryTagCategories,
  } = useAppStore(
    store => ({
      globalLibraryTagCategories: store.globalLibraryTagCategories,
      setGlobalLibraryTagCategories: store.setGlobalLibraryTagCategories,
    }),
    shallow
  );

  const {
    userRoles: { isRoleAdmin, isRolePracticeAdmin },
    practiceId,
  } = useUserProfile();

  const isViewOnly = !isRoleAdmin && !isRolePracticeAdmin;

  const { order, orderBy, onSort, page, rowsPerPage, onChangeRowsPerPage, setPage, isResettingPage, resetPageHandler } =
    useTable<TagListSortFieldName>({ defaultOrderBy: 'name', defaultOrder: 'asc' });

  const [searchValue, setSearchValue] = useState('');

  const [appliedFilters, setAppliedFilters] = useState<FiltersInitialState>(FiltersInitialStateValue);

  const debouncedSearchValue = useDebounce(searchValue, 200);

  const {
    handleClosePopover: closeFiltersMenu,
    handleOpenPopover: openFilterMenu,
    openPopover: isFiltersMenuOpen,
  } = usePopover();

  const [isAddTagCategoryDialogOpen, openAddTagCategoryDialogMenu, closeAddTagCategoryDialogMenu] = useBoolean();

  const [isLoading, setIsLoading] = useState(true);

  const {
    setIsActionPending,
    isActionPending,
    isActivateDialogOpen,
    isDeactivateDialogOpen,
    closeActivateDialog,
    closeDeactivateDialog,
    onClickActionOptionHandler,
    actionState,
    closeCopyDialogWindow,
    isOpenCopyDialogWindow,
    isOpenDeleteDialogWindow,
    closeDeleteDialogWindow,
  } = useActionDialogManagement();

  const [categoryName, setCategoryName] = useState<string>(actionState.name);

  useEffect(() => {
    setCategoryName(actionState.name);
  }, [actionState.name]);

  const handleChangeCategoryName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCategoryName(event.target.value);
  };

  const onDeactivateTagCategoryHandler = async (_: React.MouseEvent<HTMLElement>) => {
    setIsActionPending(true);
    const { id } = actionState;

    try {
      await deleteGlobalLibraryTagCategory(id);

      notice(ToastType.SUCCESS, 'Tag category has been successfully inactivated!');

      await onGetTagCategoriesHandler({ isResetPage: true, params: { page: 1 } });

      closeDeactivateDialog();
    } catch (error) {
      console.error(error);
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to inactivate tag category, please try again!',
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  const onActivateTagCategoryHandler = async (_: React.MouseEvent<HTMLElement>) => {
    setIsActionPending(true);
    const { id } = actionState;
    try {
      await updateGlobalLibraryTagCategory(id, { isActive: true, isCore: true, isPublished: false });

      notice(ToastType.SUCCESS, 'Tag category has been successfully activated!');

      await onGetTagCategoriesHandler({ isResetPage: true, params: { page: 1 } });

      closeActivateDialog();
    } catch (error) {
      console.error(error);
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to activate tag category, please try again!',
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  const onGetTagCategoriesHandler = async ({
    params,
    isResetPage,
  }: {
    params?: GlobalLibrarySearchParams;
    isResetPage?: boolean;
  }) => {
    !isLoading && setIsLoading(true);

    try {
      const { data } = await getGlobalLibraryTagCategories({
        ...(debouncedSearchValue && { search: debouncedSearchValue }),
        size: rowsPerPage,
        page,
        order: [`${orderBy},${order}`],
        is_active: resolveFilterCondition(appliedFilters.isActivated, appliedFilters.isDeactivated),
        is_published: resolveFilterCondition(appliedFilters.isPublished, appliedFilters.isUnpublished),
        ...params,
      });

      setGlobalLibraryTagCategories({
        data: data.items,
        page: data.page,
        pages: data.pages,
        size: data.size,
        total: data.total,
      });

      if (isResetPage && page !== 1) setPage(1);
    } catch (error) {
      console.error(error);
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get tag categories, please try again!' } });
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeSearchInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);

    isResettingPage.current = true;
  };

  const onChangePage = async (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const newPage = page + 1;
    setPage(newPage);
    await onGetTagCategoriesHandler({ params: { page: newPage } });
  };

  const onApplyFilters = (newFilters: FiltersInitialState) => {
    setAppliedFilters(newFilters);
    isResettingPage.current = true;
    closeFiltersMenu();
  };

  useEffect(() => {
    onGetTagCategoriesHandler({ params: { ...(isResettingPage.current && { page: 1 }) } });

    resetPageHandler();
  }, [debouncedSearchValue, appliedFilters, rowsPerPage, order, orderBy]);

  const isFiltersApplied = useMemo(
    () => checkIsFilterApplied(FiltersInitialStateValue, appliedFilters),
    [appliedFilters]
  );

  const onCopyTagCategoryHandler = async () => {
    if (!practiceId) return;

    setIsActionPending(true);
    try {
      await importTagCategoryToPL({ sourceId: actionState.id, practiceId, alternativeName: categoryName });
      closeCopyDialogWindow();
      notice(ToastType.SUCCESS, 'Tag category has been successfully copied!');
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to copy tag category, please try again!',
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  return {
    onCopyTagCategoryHandler,
    isOpenCopyDialogWindow,
    searchValue,
    onChangeSearchInputValue,
    isOpenDeleteDialogWindow,
    closeDeleteDialogWindow,
    closeFiltersMenu,
    openFilterMenu,
    isFiltersMenuOpen,
    data,
    isLoading,
    total,
    onChangeRowsPerPage,
    rowsPerPage,
    onGetTagCategoriesHandler,
    onChangePage,
    page,
    appliedFilters,
    onActivateTagCategoryHandler,
    isActionPending,
    onDeactivateTagCategoryHandler,
    onClickActionOptionHandler,
    isDeactivateDialogOpen,
    isActivateDialogOpen,
    closeDeactivateDialog,
    closeActivateDialog,
    actionState,
    onApplyFilters,
    isFiltersApplied,
    isRoleAdmin,
    onSort,
    orderBy,
    order,
    isAddTagCategoryDialogOpen,
    openAddTagCategoryDialogMenu,
    closeAddTagCategoryDialogMenu,
    closeCopyDialogWindow,
    categoryName,
    handleChangeCategoryName,
    isViewOnly,
  };
};
