import { FC } from 'react';

import { MoreHorizOutlined } from '@mui/icons-material';
import { Box, ButtonGroup, Checkbox, Divider, Stack, Typography, alpha } from '@mui/material';

import beforeAfterAlbumFallback from 'assets/icons/before_after_album_fallback.svg';
import beforeAfterAlbumImageFallback from 'assets/icons/before_after_album_image_fallback.svg';
import { Icon, TIconType } from 'components/Icon';
import { LazyImage } from 'components/LazyImage';
import MDButton from 'components/MDButton';
import { ActionMenuList } from 'components/MenuPopover';
import { usePopover } from 'hooks/usePopover';

import { BACardItemHeaderProps } from './types';

const IMAGE_ALT_MESSAGE = 'before & after image cover';

export const BACardItemHeader: FC<BACardItemHeaderProps> = ({
  children,
  coverThumbUrl,
  isActive,
  sx,
  id,
  onChangeCheckboxHandler,
  checkedIds,
  title,
  getMenuItems,
  coverLeftThumbURL,
  coverRightThumbURL,
  pairsCount,
  ...boxProps
}) => {
  const menuItems = getMenuItems({ isActive, id, name: title });

  const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

  const handleDotsMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    openPopover ? handleClosePopover() : handleOpenPopover(event);
  };

  const onClosePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleClosePopover();
  };

  const renderButtonGroup = () => (
    <ButtonGroup
      size="small"
      className="button-group-before-after"
      sx={theme => ({
        transition: theme.transitions.create('all', {
          duration: theme.transitions.duration.short,
          easing: theme.transitions.easing.easeOut,
        }),
        '& button': {
          maxWidth: 45,
          bgcolor: theme.palette.common.white,
          ':hover': {
            opacity: 1,
            bgcolor: alpha(theme.palette.common.white, 0.9),
          },
        },
        opacity: openPopover ? 1 : 0,
        position: 'absolute',
        top: 10,
        right: 10,
      })}
    >
      {menuItems.length > 0 && (
        <MDButton size="small" variant="outlined" color="dark" onClick={handleDotsMenuClick}>
          <MoreHorizOutlined fontSize="medium" />
        </MDButton>
      )}
    </ButtonGroup>
  );

  const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    event.stopPropagation();

    onChangeCheckboxHandler(event.currentTarget.id, checked);
  };

  const renderImages = () => {
    if (coverLeftThumbURL || coverRightThumbURL) {
      return (
        <Stack direction="row" width={1} height={1}>
          <LazyImage
            src={coverLeftThumbURL}
            fallbackImage={beforeAfterAlbumImageFallback}
            alt={IMAGE_ALT_MESSAGE}
            imageSX={{
              borderRadius: ({ borders }) => `${borders.borderRadius.lg} 0 0 ${borders.borderRadius.lg}`,
            }}
            imageContainerSX={{
              '& .MuiSkeleton-root': {
                borderRadius: ({ borders }) => `${borders.borderRadius.lg} 0 0 ${borders.borderRadius.lg}`,
              },
            }}
          />
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderRightWidth: ({ borders }) => borders.borderWidth[2],
              borderColor: ({ palette }) => palette.common.white,
            }}
          />
          <LazyImage
            src={coverRightThumbURL}
            fallbackImage={beforeAfterAlbumImageFallback}
            alt={IMAGE_ALT_MESSAGE}
            imageSX={{
              transform: 'rotateY(180deg)',
              borderRadius: ({ borders }) => `${borders.borderRadius.lg} 0 0 ${borders.borderRadius.lg}`,
            }}
            imageContainerSX={{
              '& .MuiSkeleton-root': {
                borderRadius: ({ borders }) => `0 ${borders.borderRadius.lg} ${borders.borderRadius.lg} 0`,
              },
            }}
          />
        </Stack>
      );
    }

    return (
      <LazyImage
        src={coverThumbUrl}
        fallbackImage={beforeAfterAlbumFallback}
        alt={IMAGE_ALT_MESSAGE}
        imageSX={{ borderRadius: theme => theme.borders.borderRadius.lg }}
      />
    );
  };

  return (
    <>
      <Box
        height={210}
        sx={{
          position: 'relative',
          ...(!isActive && {
            filter: 'grayscale(1)',
          }),
          ...sx,
        }}
        {...boxProps}
      >
        {renderImages()}

        {onChangeCheckboxHandler && (
          <Checkbox
            id={id}
            className="checkbox-before-after"
            checked={checkedIds.has(id)}
            onChange={onChangeCheckbox}
            onClick={event => event.stopPropagation()}
            sx={{
              opacity: 0,
              bgcolor: theme => theme.palette.common.white,
              position: 'absolute',
              zIndex: 100,
              top: 8,
              left: 8,
            }}
          />
        )}
        {renderButtonGroup()}
        {children}
        {pairsCount !== undefined && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 8,
              left: 8,
            }}
          >
            {renderLinkedItem(true, 'beforeAfterFilled', pairsCount)}
          </Box>
        )}
        {/* TODO after linked items backend will be ready */}
        {/* {renderLinkedItems(isActive)} */}
      </Box>
      <ActionMenuList onClose={onClosePopover} open={openPopover} menuItems={menuItems} />
    </>
  );
};

// TODO: use when linked items backend will be ready
// const renderLinkedItems = (isActive: boolean) => (
//   <Stack
//     sx={{ position: 'absolute', bottom: 8, right: 8 }}
//     direction="row"
//     justifyContent="space-between"
//     alignItems="center"
//     spacing={1}
//   >
//     {renderLinkedItem(isActive, 'linkedBuilding', '2')}
//     {renderLinkedItem(isActive, 'linkedMedical', '3')}
//   </Stack>
// );

const renderLinkedItem = (isActive: boolean, iconType: TIconType, text: string | number) => (
  <Box
    sx={{
      backdropFilter: 'blur(24px)',
      bgcolor: theme => alpha(theme.palette.grey[800], 0.3),
      borderRadius: theme => theme.borders.borderRadius.lg,
      p: 1,
      color: theme => (isActive ? theme.palette.common.white : theme.palette.grey[400]),
    }}
  >
    <Stack direction="row" alignItems="center" spacing={1}>
      <Icon
        sx={{ color: theme => (isActive ? theme.palette.common.white : theme.palette.grey[400]) }}
        type={iconType}
      />
      <Typography variant="button" fontWeight={500}>
        {text}
      </Typography>
    </Stack>
  </Box>
);
