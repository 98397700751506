import { useCallback, useEffect, useState } from 'react';

import { AxiosResponse } from 'axios';

import { ToastType, notice } from 'components/ToastNotification';
import { ITEMS_PER_PAGE } from 'constants/layout-config';
import {
  ActionDataInitialState,
  useActionDialogManagement,
  useBoolean,
  useCheckedIDs,
  useInfinityScroll,
  useRouter,
  useUserProfile,
} from 'hooks';
import { backendErrorHandler } from 'utils/errorHanders';

import { ActionHandler, MenuItemKey, getBACardMenuItems } from '../components';

type UseAlbumProfileStateProps<TAlbum, TAlbumPair> = {
  fetchAlbumApi: (id: string, params: { includeOriginal: boolean }) => Promise<AxiosResponse<TAlbum, any>>;
  setAlbum: (data: TAlbum) => void;
  fetchAlbumPairsApi: (params: {
    album_id: string;
    page: number;
    size: number;
  }) => Promise<AxiosResponse<ListOfItems<TAlbumPair>, any>>;
  setAlbumPairs: (data: StoreListOfItems<TAlbumPair>) => void;
  setNextAlbumPairs: (data: StoreListOfItems<TAlbumPair>) => void;
  activateAlbumApi: (id: string) => Promise<AxiosResponse<TAlbum, any>>;
  deactivateAlbumApi: (id: string) => Promise<AxiosResponse<TAlbum, any>>;
  uploadAlbumCoverApi: (id: string, formData: FormData) => Promise<AxiosResponse<TAlbum, any>>;
  deleteAlbumCoverApi: (id: string) => Promise<AxiosResponse<TAlbum, any>>;
  deleteAlbumPairApi: (id: string) => Promise<AxiosResponse<any, any>>;
  totalPages: number;
  libraryType: LibraryType;
  // TODO: add to deactivate album pair
  // activateAlbumPair: (id: string) => Promise<AxiosResponse<TAlbumPair>>;
  // deactivateAlbumPair: (id: string) => Promise<AxiosResponse<TAlbumPair>>;
};

export const useAlbumProfileState = <TAlbum, TAlbumPairs>({
  setAlbum,
  fetchAlbumApi,
  fetchAlbumPairsApi,
  setAlbumPairs,
  activateAlbumApi,
  deactivateAlbumApi,
  deleteAlbumCoverApi,
  totalPages,
  uploadAlbumCoverApi,
  setNextAlbumPairs,
  deleteAlbumPairApi,
  libraryType,
}: UseAlbumProfileStateProps<TAlbum, TAlbumPairs>) => {
  const { params } = useRouter();

  const albumId = params?.albumId;

  const {
    closeActivateDialog,
    closeDeactivateDialog,
    setIsActionPending,
    onClickActionOptionHandler,
    ...restActionMethods
  } = useActionDialogManagement();

  const [isLoading, setIsLoading] = useState(true);

  const onGetGLAlbumHandler = async () => {
    !isLoading && setIsLoading(true);
    await fetchAlbum();
    setIsLoading(false);
  };

  const fetchAlbum = async () => {
    try {
      const { data } = await fetchAlbumApi(albumId, { includeOriginal: true });
      setAlbum(data);
    } catch (error) {
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get album, please try again!' } });
    }
  };

  const [isAlbumPairLoading, setIsAlbumPairLoading] = useState(true);

  const fetchAlbumPairs = async () => {
    !isAlbumPairLoading && setIsAlbumPairLoading(true);
    try {
      const { data } = await fetchAlbumPairsApi({ album_id: albumId, page: 1, size: ITEMS_PER_PAGE, ...params });

      setAlbumPairs(data);

      currentPage !== 1 && setCurrentPage(1);
    } catch (error) {
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get album pairs, please try again!' } });
    } finally {
      setIsAlbumPairLoading(false);
    }
  };

  const [isFetchMoreLoading, setIsFetchMoreLoading] = useState(false);

  const fetchNextAlbumPairs = async ({ page }: { page: number }) => {
    setIsFetchMoreLoading(true);
    try {
      const { data } = await fetchAlbumPairsApi({ album_id: albumId, page, size: ITEMS_PER_PAGE });

      setNextAlbumPairs(data);
    } catch (error) {
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get album pairs, please try again!' } });
    } finally {
      setIsFetchMoreLoading(false);
    }
  };

  const { ref, currentPage, setCurrentPage } = useInfinityScroll({
    totalPages: totalPages,
    fetchItemsHandler: fetchNextAlbumPairs,
  });

  const checkedIdsData = useCheckedIDs();

  const albumPairActionMethods = useActionDialogManagement();

  const deleteAlbumPairHandler = async (event: React.MouseEvent<HTMLElement>) => {
    albumPairActionMethods.setIsActionPending(true);
    try {
      await deleteAlbumPairApi(albumPairActionMethods.actionState.id);
      notice(ToastType.SUCCESS, 'Before & after photos have been deleted successfully');
      albumPairActionMethods.closeDeleteDialogWindow();

      await fetchAlbumPairs();
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to delete before & after photos, please try again!',
        },
      });
    } finally {
      albumPairActionMethods.setIsActionPending(false);
    }
  };

  // TODO: add to deactivate album pair
  // const onActivateAlbumPairHandler = async () => {
  //   setIsActionPendingAlbumPair(true);
  //   const { id, actionType, name } = actionStateAlbumPair;
  //   try {
  //     if (actionType === 'deactivate') {
  //       await deactivateAlbumPair(id);
  //       notice(ToastType.SUCCESS, 'Before & After photos has been deactivated successfully');
  //     } else {
  //       await activateAlbumPair(id);
  //       notice(ToastType.SUCCESS, 'Before & After photos has been activated successfully');
  //     }
  //     await fetchAlbumPairs();
  //   } catch (error) {
  //     backendErrorHandler({
  //       error,
  //       config: {
  //         customErrorMessage: `Failed to ${
  //           actionType === 'deactivate' ? 'deactivate' : 'activate'
  //         } Before & After photos, please try again!`,
  //       },
  //     });
  //   } finally {
  //     setIsActionPendingAlbumPair(false);
  //   }
  // };

  const {
    userRoles: { isRoleAdmin, isRolePracticeAdmin },
  } = useUserProfile();
  const { navigate } = useRouter();

  const isViewOnly = !isRoleAdmin && !isRolePracticeAdmin;

  const getMenuItems = useCallback(
    ({ isActive, id, name }: { isActive: boolean; id: string; name: string }) => {
      const navigateToBeforeAfterProfile = () =>
        navigate(`/before-and-after-galleries/${libraryType}/${albumId}/${id}`);

      const handlers: Partial<Record<MenuItemKey, ActionHandler<ActionDataInitialState>>> = {};

      const isAdmin = isRoleAdmin || (libraryType !== 'gl' && isRolePracticeAdmin);

      if (isAdmin) {
        handlers.edit = navigateToBeforeAfterProfile;
        handlers.delete = albumPairActionMethods.onClickActionOptionHandler;
        handlers.activateToggle = () => {};
      }

      if (libraryType === 'gl' && isRolePracticeAdmin) handlers.clone = () => {};

      if (isViewOnly) handlers.view = navigateToBeforeAfterProfile;

      return getBACardMenuItems({
        isActive,
        id,
        name,
        handlers,
      });
    },
    [onClickActionOptionHandler, isRoleAdmin, isRolePracticeAdmin, libraryType]
  );

  useEffect(() => {
    if (!albumId) return;

    onGetGLAlbumHandler();
    fetchAlbumPairs();

    return () => {
      setAlbum(null);
    };
  }, []);

  const onDeactivateAlbumHandler = async (event: React.MouseEvent<HTMLElement>) => {
    setIsActionPending(true);
    try {
      await deactivateAlbumApi(albumId);

      await fetchAlbum();

      notice(ToastType.SUCCESS, 'Album has been deactivated successfully');
      closeDeactivateDialog();
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to deactivate album, please try again!',
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  const onActivateAlbumHandler = async (event: React.MouseEvent<HTMLElement>) => {
    setIsActionPending(true);
    try {
      await activateAlbumApi(albumId);
      await fetchAlbum();

      notice(ToastType.SUCCESS, 'Album has been activated successfully');
      closeActivateDialog();
    } catch (error) {
      backendErrorHandler({
        error,
        config: {
          customErrorMessage: 'Failed to activate album, please try again!',
        },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  const onUploadAvatarHandler = async (formData: FormData) => {
    await uploadAlbumCoverApi(albumId, formData);
    await fetchAlbum();
  };

  const onDeleteAvatarHandler = async () => {
    await deleteAlbumCoverApi(albumId);

    await fetchAlbum();
  };

  const [isOpenAddAlbumImagesDialogWindow, openAddAlbumImagesDialogWindow, closeAddAlbumImagesDialogWindow] =
    useBoolean();

  return {
    isLoading,
    onDeleteAvatarHandler,
    onActivateAlbumHandler,
    onDeactivateAlbumHandler,
    onUploadAvatarHandler,
    getMenuItems,
    isFetchMoreLoading,
    ref,
    isAlbumPairLoading,
    closeActivateDialog,
    closeDeactivateDialog,
    albumId,
    isOpenAddAlbumImagesDialogWindow,
    openAddAlbumImagesDialogWindow,
    closeAddAlbumImagesDialogWindow,
    fetchAlbumPairs,
    deleteAlbumPairHandler,
    ...checkedIdsData,
    ...restActionMethods,
    albumPairActionMethods,
    isViewOnly,
  };
};
