import { useMemo } from 'react';

import { Skeleton } from '@mui/material';
import { AppStore, useAppStore } from 'store';
import { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';

import { LIBRARIES_NAMES } from 'constants/strings';
import { RouterState } from 'hooks';

import { BreadcrumbData, ExctractBreadcrumbData } from './types';

const BreadcrumbItem = ({ data }: BreadcrumbData) =>
  data ? <>{data}</> : <Skeleton component="span" variant="text" width={100} height={20} />;

const createMapStateToProps = (extractBreadcrumbData: ExctractBreadcrumbData, paramsId: string = 'id') => {
  return (state: AppStore, props: BreadcrumbComponentProps): BreadcrumbData =>
    mapEntityStateToProps(state, props, extractBreadcrumbData, paramsId);
};

const connect =
  <TProps,>(mapStateToPropsFC: (state: AppStore, props: BreadcrumbComponentProps) => TProps) =>
  (Component: React.FC<TProps & BreadcrumbComponentProps>) => {
    return (props: BreadcrumbComponentProps): JSX.Element => {
      const store = useAppStore();
      const stateProps = useMemo(() => mapStateToPropsFC(store, props), [store, props]);

      return useMemo(() => <Component {...props} {...stateProps} />, [props, stateProps]);
    };
  };

export const getBreadCrumbName = ({ location: { state } }: { location: { state: RouterState } }) => (
  <span>{state?.breadCrumbName}</span>
);

const mapEntityStateToProps = (
  state: AppStore,
  props: BreadcrumbComponentProps,
  extractBreadcrumbData: ExctractBreadcrumbData,
  paramsId: string = 'id'
): BreadcrumbData => {
  const { breadcrumbName, entityId } = extractBreadcrumbData(state);

  const isIdMatch = entityId === props?.match?.params?.[paramsId];

  return {
    data: isIdMatch ? breadcrumbName : null,
  };
};

export const createBreadcrumb = (mapStateToPropsFC: ReturnType<typeof createMapStateToProps>) =>
  connect(mapStateToPropsFC)(BreadcrumbItem);

export const mapPatientStateToProps = createMapStateToProps(({ patientBreadcrumb }) => {
  return {
    breadcrumbName: patientBreadcrumb?.name,
    entityId: patientBreadcrumb?.id,
  };
});

export const mapPracticeStateToProps = createMapStateToProps(({ practice }) => ({
  breadcrumbName: practice?.name || null,
  entityId: practice?.id,
}));

export const mapTagCategoryStateToProps = createMapStateToProps(({ breadcrumbData }) => ({
  breadcrumbName: breadcrumbData?.name || null,
  entityId: breadcrumbData?.categoryId,
}));

export const mapUserStateToProps = createMapStateToProps(({ expert }) => ({
  breadcrumbName: expert?.person
    ? `${expert?.person?.firstName} ${expert?.person?.middleName || ''} ${expert?.person?.lastName}`
    : null,
  entityId: expert?.id,
}));

export const mapGLServiceStateToProps = createMapStateToProps(({ GLService }) => ({
  breadcrumbName: GLService?.title ? `${LIBRARIES_NAMES.global} - Services - ${GLService?.title}` : null,
  entityId: GLService?.id,
}));

export const mapPLServiceStateToProps = createMapStateToProps(({ PLService }) => ({
  breadcrumbName: PLService?.title ? `${LIBRARIES_NAMES.practice} - Services - ${PLService?.title}` : null,
  entityId: PLService?.id,
}));

export const mapGLProductStateToProps = createMapStateToProps(({ GLProduct }) => ({
  breadcrumbName: GLProduct?.title ? `${LIBRARIES_NAMES.global} - Products - ${GLProduct?.title}` : null,
  entityId: GLProduct?.id,
}));

export const mapPLProductStateToProps = createMapStateToProps(({ PLProduct }) => ({
  breadcrumbName: PLProduct?.title ? `${LIBRARIES_NAMES.practice} - Products - ${PLProduct?.title}` : null,
  entityId: PLProduct?.id,
}));

export const mapGLalbumStateToProps = createMapStateToProps(
  ({ b_a_gl_album }) => ({
    breadcrumbName: b_a_gl_album?.name ? `${LIBRARIES_NAMES.global} - ${b_a_gl_album?.name}` : null,
    entityId: b_a_gl_album?.id,
  }),
  'albumId'
);

export const mapPLalbumStateToProps = createMapStateToProps(
  ({ b_a_pl_album }) => ({
    breadcrumbName: b_a_pl_album?.name ? `${LIBRARIES_NAMES.practice} - ${b_a_pl_album?.name}` : null,
    entityId: b_a_pl_album?.id,
  }),
  'albumId'
);

export const mapGLimagePairStateToProps = createMapStateToProps(({ ba_gl_image_pair }) => ({
  breadcrumbName: ba_gl_image_pair?.name ? `${LIBRARIES_NAMES.global} - ${ba_gl_image_pair?.name}` : null,
  entityId: ba_gl_image_pair?.id,
}));

export const mapPLimagePairStateToProps = createMapStateToProps(({ ba_pl_image_pair }) => ({
  breadcrumbName: ba_pl_image_pair?.name ? `${LIBRARIES_NAMES.practice} - ${ba_pl_image_pair?.name}` : null,
  entityId: ba_pl_image_pair?.id,
}));

export const mapGLimagePairParentRouteStateToProps = createMapStateToProps(
  ({ ba_gl_image_pair }) => ({
    breadcrumbName: ba_gl_image_pair?.album?.name || null,
    entityId: ba_gl_image_pair?.album?.id,
  }),
  'albumId'
);

export const mapPLimagePairParentRouteStateToProps = createMapStateToProps(
  ({ ba_pl_image_pair }) => ({
    breadcrumbName: ba_pl_image_pair?.album?.name || null,
    entityId: ba_pl_image_pair?.album?.id,
  }),
  'albumId'
);

export const mapPromptStateToProps = createMapStateToProps(({ prompt }) => ({
  breadcrumbName: prompt?.name || null,
  entityId: prompt?.id,
}));
