import { useEffect, useState } from 'react';

import {
  GLServicesSearchParams,
  activateGLProduct,
  deactivateGLProduct,
  deleteGLProduct,
  getGLProducts,
  importToPLProduct,
} from 'apiServices';
import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { useTableState } from 'hooks/useTableState';
import { useUserProfile } from 'hooks/useUserProfile';
import { CATALOG_PRODUCT_MESSAGES } from 'pages/Catalog/constants';
import { backendErrorHandler } from 'utils/errorHanders';

export const useGLProductsState = () => {
  const {
    GLProducts: { items, total },
    setGLProducts,
  } = useAppStore(
    store => ({
      GLProducts: store.catalogGLData.GLProducts,
      setGLProducts: store.setGLProducts,
    }),
    shallow
  );

  const {
    userRoles: { isRoleAdmin, isRolePracticeUser },
    practiceId,
  } = useUserProfile();

  const [isLoading, setIsLoading] = useState(true);

  const onGetProductsHandler = async (params?: GLServicesSearchParams) => {
    !isLoading && setIsLoading(true);

    try {
      const { data } = await getGLProducts({
        ...(debouncedSearchValue && { search: debouncedSearchValue }),
        size: rowsPerPage,
        page,
        ...params,
      });

      setGLProducts({
        items: data.items,
        page: data.page,
        pages: data.pages,
        size: data.size,
        total: data.total,
      });

      if (params?.page === 1 && page !== 1) setPage(1);
    } catch (error) {
      console.error(error);
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get products, please try again!' } });
    } finally {
      setIsLoading(false);
    }
  };

  const {
    onChangeSearchInputValue,
    isOpenDeleteDialogWindow,
    isActivateDialogOpen,
    isDeactivateDialogOpen,
    onChangeRowsPerPage,
    onChangePage,
    page,
    searchValue,
    rowsPerPage,
    debouncedSearchValue,
    appliedFilters,
    isResettingPage,
    onDeactivateServiceHandler,
    onDeleteServiceHandler,
    isActionPending,
    closeAddItemDialog,
    closeDeactivateDialog,
    closeFiltersMenu,
    onApplyFilters,
    isFiltersApplied,
    onActivateServiceHandler,
    actionState,
    isFiltersMenuOpen,
    openFilterMenu,
    closeDeleteDialogWindow,
    setPage,
    resetPageHandler,
    isAddItemDialogOpen,
    openAddItemDialog,
    onClickActionOptionHandler,
    closeActivateDialog,
    closeCopyDialogWindow,
    isOpenCopyDialogWindow,
    onCopyItemHandler,
  } = useTableState({
    fetchItems: onGetProductsHandler,
    activateItem: activateGLProduct,
    deleteItem: deleteGLProduct,
    deactivateItem: deactivateGLProduct,
    copyItem: importToPLProduct,
    practiceId,
    messages: {
      activationError: CATALOG_PRODUCT_MESSAGES.activateFailed,
      activationSuccess: CATALOG_PRODUCT_MESSAGES.activateSuccess,
      deactivationError: CATALOG_PRODUCT_MESSAGES.inactivateFailed,
      deactivationSuccess: CATALOG_PRODUCT_MESSAGES.inactivateSuccess,
      deletionError: CATALOG_PRODUCT_MESSAGES.deleteFailed,
      deletionSuccess: CATALOG_PRODUCT_MESSAGES.deleteSuccess,
      copyError: CATALOG_PRODUCT_MESSAGES.copyFailed,
      copySuccess: CATALOG_PRODUCT_MESSAGES.copySuccess,
    },
  });

  useEffect(() => {
    onGetProductsHandler({ ...(isResettingPage.current && { page: 1 }) });

    resetPageHandler();
  }, [debouncedSearchValue, appliedFilters, rowsPerPage]);

  return {
    searchValue,
    onChangeSearchInputValue,
    isOpenDeleteDialogWindow,
    closeDeleteDialogWindow,
    closeFiltersMenu,
    openFilterMenu,
    isFiltersMenuOpen,
    items,
    isLoading,
    total,
    onChangeRowsPerPage,
    rowsPerPage,
    onChangePage,
    page,
    appliedFilters,
    isActionPending,
    isAddItemDialogOpen,
    openAddItemDialog,
    closeAddItemDialog,
    onGetProductsHandler,
    onActivateServiceHandler,
    onDeactivateServiceHandler,
    onClickActionOptionHandler,
    isDeactivateDialogOpen,
    isActivateDialogOpen,
    closeDeactivateDialog,
    closeActivateDialog,
    actionState,
    onApplyFilters,
    isFiltersApplied,
    isRoleAdmin,
    onDeleteServiceHandler,
    closeCopyDialogWindow,
    onCopyItemHandler,
    isOpenCopyDialogWindow,
    isRolePracticeUser,
  };
};
