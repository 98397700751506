import { FC } from 'react';

import { Table, TableBody, TableContainer } from '@mui/material';

import { getActivateMenuItem } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import { CustomTableHeader, CustomTablePagination, TableSkeleton } from 'components/Table';
import { useBoolean } from 'hooks';

import { RelatedVisitsTableRow } from './components';
import {
  EditRelatedVisitSideMenuContent,
  RelatedVisitsSideMenu,
  ViewRelatedVisitSideMenuContent,
} from './components/SideMenu';
import { RELATED_VISITS_TABLE_HEADERS } from './table.config';

export const RelatedVisitsTabContent: FC = () => {
  const isLoading = false;

  const [isDialogOpen, openDialog, closeDialog] = useBoolean();

  const [isEditMenuOpen, openEditMenu, closeEditMenu] = useBoolean();

  return (
    <>
      <TableContainer>
        <Scrollbar>
          <Table size="medium" sx={{ minWidth: 600 }}>
            <CustomTableHeader headLabel={RELATED_VISITS_TABLE_HEADERS} />

            <TableBody>
              {isLoading ? (
                <TableSkeleton cellsAmount={3} />
              ) : (
                <RelatedVisitsTableRow id="123" onClickTableRow={openDialog} onClickEditMenuItem={openEditMenu} />
              )}
              {/* TODO */}
              {/* <TableNoData isNotFound={!isLoading} /> */}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
      <CustomTablePagination
        count={0}
        page={1 - 1}
        rowsPerPage={20}
        onPageChange={(event, page) => {}}
        onRowsPerPageChange={event => {}}
      />

      <RelatedVisitsSideMenu
        menuItems={[getActivateMenuItem(true, () => {})]}
        open={isDialogOpen}
        onClose={closeDialog}
        onClickCloseButton={closeDialog}
      >
        <ViewRelatedVisitSideMenuContent />
      </RelatedVisitsSideMenu>

      <RelatedVisitsSideMenu
        menuItems={[getActivateMenuItem(false, () => {})]}
        open={isEditMenuOpen}
        onClose={closeEditMenu}
        onClickCloseButton={closeEditMenu}
      >
        <EditRelatedVisitSideMenuContent />
      </RelatedVisitsSideMenu>
    </>
  );
};
