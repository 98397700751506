import { FC } from 'react';

import { MainLayout } from 'layouts/MainLayout';
import { Navigate, useRoutes } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import {
  BeforeAfter,
  GlobalLibraryBAProfile,
  GlobalLibraryBAphotos,
  PracticeLibraryBAProfile,
  PracticeLibraryBAphotos,
} from 'pages/BeforeAfter';
import { Catalog } from 'pages/Catalog';
import {
  GlobalLibraryProductProfile,
  GlobalLibraryServiceProfile,
  PracticeLibraryProductProfile,
  PracticeLibraryServiceProfile,
} from 'pages/Catalog';
import { ForgotPassword } from 'pages/ForgotPassword';
import { NotFound } from 'pages/NotFound';
import { PatientConcernProfile, PatientView, Patients } from 'pages/Patients';
import { Practices, ViewPractice } from 'pages/PracticeManagement';
import { ProfileLayout, Security, UserProfile } from 'pages/Profile';
import { AIModelList, PromptList, PromptView } from 'pages/PromptLibrary';
import { AIModelVersions } from 'pages/PromptLibrary/AIModelListTable/components/AIModelVersions';
import { AddModel } from 'pages/PromptLibrary/AddModel/AddModel';
import { AddUpdateModelVersion } from 'pages/PromptLibrary/AddUpdateModelVersion/AddUpdateModelVersion';
import { AddUpdatePromptVersion } from 'pages/PromptLibrary/AddUpdatePromptVersion/AddUpdatePrompt_Version';
import { PromptVersionList } from 'pages/PromptLibrary/PromptVersionsListTable/PromptVersionsList';
import { RecentlyLoggedInUsers, RecentlyLoggedInUsersSignIn } from 'pages/RecentlyLoggedInUsers';
import { ResetPassword } from 'pages/ResetPassword';
import { SetupAccount } from 'pages/SetupAccount';
import { SignIn } from 'pages/SignIn';
import { GlobalLibraryTagCategoryProfile, PracticeLibraryTagCategoryProfile, TagManagement } from 'pages/TagManagement';
import { UserProfile as PracticeUserProfile, UsersList } from 'pages/Users';

import { PrivateRouter } from './Private.router';
import { RoleGuardRouter } from './RoleGuard.router';
import { ALLOWED_PRACTICE_MANAGEMENT_ROLES } from './rolesConfig';

export const AppRouter: FC = () => {
  return useRoutes([
    {
      path: ROUTES.signIn,
      element: <SignIn />,
    },

    {
      path: ROUTES.setupAccount,
      element: <SetupAccount />,
    },
    {
      path: ROUTES.forgotPassword,
      element: <ForgotPassword />,
    },

    {
      path: ROUTES.resetPassword,
      element: <ResetPassword />,
    },

    {
      path: ROUTES.recentlyLoggedInUsers,
      element: <RecentlyLoggedInUsers />,
    },

    { path: ROUTES.recentlyLoggedInUsersSignIn, element: <RecentlyLoggedInUsersSignIn /> },

    {
      path: ROUTES.home,
      element: (
        <PrivateRouter>
          <MainLayout />
        </PrivateRouter>
      ),
      children: [
        { index: true, element: <Navigate to={ROUTES.patients} /> },

        {
          path: ROUTES.profile,
          element: <ProfileLayout />,
          children: [
            { index: true, element: <UserProfile /> },
            { path: ROUTES.profileSecurity, element: <Security /> },
          ],
        },
        {
          path: ROUTES.practiceManagement,
          element: (
            <RoleGuardRouter allowedUserRoles={ALLOWED_PRACTICE_MANAGEMENT_ROLES}>
              <Practices />
            </RoleGuardRouter>
          ),
        },

        { path: ROUTES.practiceManagementProfile, element: <ViewPractice /> },

        { path: ROUTES.users, element: <UsersList /> },

        { path: ROUTES.usersProfile, element: <PracticeUserProfile /> },

        { path: ROUTES.patients, element: <Patients /> },

        {
          path: ROUTES.patientProfile,
          element: <PatientView />,
        },

        { path: ROUTES.patientConcernProfile, element: <PatientConcernProfile /> },

        {
          path: ROUTES.tagManagement,
          element: <TagManagement />,
        },

        { path: ROUTES.tagManagementGLTagCategoryProfile, element: <GlobalLibraryTagCategoryProfile /> },

        { path: ROUTES.tagManagementPLTagCategoryProfile, element: <PracticeLibraryTagCategoryProfile /> },

        {
          path: ROUTES.catalog,
          element: <Catalog />,
        },

        { path: ROUTES.catalogGLServiceProfile, element: <GlobalLibraryServiceProfile /> },

        { path: ROUTES.catalogPLServiceProfile, element: <PracticeLibraryServiceProfile /> },

        { path: ROUTES.catalogGLProductProfile, element: <GlobalLibraryProductProfile /> },

        { path: ROUTES.catalogPLProductProfile, element: <PracticeLibraryProductProfile /> },

        {
          path: ROUTES.beforeAfter,
          element: <BeforeAfter />,
        },

        {
          path: ROUTES.beforeAfterGLProfile,
          element: <GlobalLibraryBAProfile />,
        },

        {
          path: ROUTES.beforeAfterPLProfile,
          element: <PracticeLibraryBAProfile />,
        },

        {
          path: ROUTES.beforeAfterPhotosGLProfile,
          element: <GlobalLibraryBAphotos />,
        },

        {
          path: ROUTES.beforeAfterPhotosPLProfile,
          element: <PracticeLibraryBAphotos />,
        },

        {
          path: ROUTES.promptLibrary,
          element: <PromptList />,
        },

        {
          path: ROUTES.promptVersionList,
          element: <PromptVersionList />,
        },

        {
          path: ROUTES.promptVersionUpdate,
          element: <AddUpdatePromptVersion mode="update" />,
        },

        {
          path: ROUTES.promptVersionAdd,
          element: <AddUpdatePromptVersion />,
        },

        {
          path: ROUTES.updateAIModel,
          element: <AddModel mode="update" />,
        },

        {
          path: ROUTES.addAIModelVersion,
          element: <AddUpdateModelVersion />,
        },

        {
          path: ROUTES.updateAIModelVersion,
          element: <AddUpdateModelVersion mode="update" />,
        },

        {
          path: ROUTES.modelList,
          element: <AIModelList />,
        },

        {
          path: ROUTES.addAIModel,
          element: <AddModel />,
        },

        {
          path: ROUTES.aiModelVersions,
          element: <AIModelVersions />,
        },

        {
          path: ROUTES.viewPrompt,
          element: <PromptView />,
        },
      ],
    },

    {
      path: ROUTES.notFound,
      element: <NotFound />,
    },
  ]);
};
