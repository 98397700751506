import { useTabs } from 'hooks/useTabs';

export enum PATIENT_CONCERN_PROFILE_TABS {
  GENERAL = 'General',
  RELATED_VISITS = 'Related Visits',
}
export const PATIENT_CONCERN_PROFILE_TAB_VALUES: PATIENT_CONCERN_PROFILE_TABS[] = [
  PATIENT_CONCERN_PROFILE_TABS.GENERAL,
  PATIENT_CONCERN_PROFILE_TABS.RELATED_VISITS,
];

export const usePatientConcernProfileState = () => {
  const { tabValue, handleSetTabValue } = useTabs();

  return {
    tabValue,
    handleSetTabValue,
  };
};
