import { FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';

import { FormDialogWindowContent } from 'components/BaseDialogWindow';
import { RHFTextField } from 'components/HookForm';
import { ToastType, notice } from 'components/ToastNotification';
import { formErrorHandler } from 'utils/errorHanders';

import { CreateTagFormSchema, createTagDefaultValues, createTagValidationSchema } from '../form.config';

type CreateTagMenuContentProps = {
  closeMenuHandler: VoidFunction;
  createTagHandler: (name: string) => Promise<void>;
};

export const CreateTagMenuContent: FC<CreateTagMenuContentProps> = ({ closeMenuHandler, createTagHandler }) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid, isSubmitting },
  } = useForm<CreateTagFormSchema>({
    resolver: yupResolver(createTagValidationSchema) as Resolver<CreateTagFormSchema>,
    mode: 'onTouched',
    defaultValues: createTagDefaultValues,
  });

  const onSubmitFormHandler = handleSubmit(async ({ name }) => {
    try {
      await createTagHandler(name);
      notice(ToastType.SUCCESS, 'Tag has been successfully created!');
    } catch (error) {
      console.error(error);
      formErrorHandler({
        error,
        customErrorMessage: 'Failed to create tag, please try again!',
        config: { formError: { setError } },
      });
    }
  });

  return (
    <FormDialogWindowContent
      onFormSubmitHandler={onSubmitFormHandler}
      headerProps={{ title: 'Add Tag', onClickCancelButtonHandler: closeMenuHandler }}
      actionProps={{
        approveButtonProps: { disabled: isSubmitting || !isValid, isLoading: isSubmitting },
      }}
    >
      <RHFTextField
        fullWidth
        register={register}
        label="Tag Name"
        registerErrors={errors?.name?.message}
        registerName="name"
        required
        placeholder="Enter Name"
      />
    </FormDialogWindowContent>
  );
};
