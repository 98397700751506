import { FC } from 'react';

import { Stack, Typography } from '@mui/material';

import { MDRating } from 'components/MDRating';

import { RatingKey } from './FeedbackContent';

interface RatingItemProps {
  ratingValue: number;
  name: string;
  ratingKey: RatingKey;
  onChange: (ratingName: RatingKey, newValue: number | null) => void;
}

export const RatingItem: FC<RatingItemProps> = ({ ratingKey, ratingValue, name, onChange }) => {
  const onChangeHandler = (_: React.SyntheticEvent, newValue: number | null) => onChange(ratingKey, newValue);

  return (
    <Stack direction="row" alignItems="center" gap={0.5} width={1}>
      <Typography variant="button" fontSize={12} fontWeight={500} flex={1}>
        {name}
      </Typography>
      <MDRating name="feedback" value={ratingValue} precision={0.5} onChange={onChangeHandler} />
    </Stack>
  );
};
