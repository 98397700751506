import { CreatePracticeContactPersonPayload } from 'apiServices';
import * as yup from 'yup';

import { Shape } from 'types/RHF-types';
import { EmailLazyValidationSchema, PhoneValidationSchema, WebsiteLazyValidationSchema } from 'utils/formValidation';

export type CreateContactPersonFormSchema = CreatePracticeContactPersonPayload;

export const createContactPersonValidationSchema = yup
  .object()
  .shape<Shape<CreateContactPersonFormSchema>>({
    firstName: yup.string().required().label('First Name'),
    middleName: yup.string().optional().label('Middle  Name'),
    lastName: yup.string().required().label('Last Name'),
    position: yup.string().optional().label('Position'),
    title: yup.string().required().label('Title'),
    contact: yup
      .object()
      .shape<Shape<BaseContact>>({
        email: EmailLazyValidationSchema,
        phone: PhoneValidationSchema,
        website: WebsiteLazyValidationSchema,
      })
      .required(),
  })
  .required();

export const CONTACT_PERSON_DEFAULT_VALUES = {
  firstName: '',
  lastName: '',
  title: '',
  position: '',
  middleName: '',
  contact: { email: '', phone: '', website: '' },
};
