import { FC } from 'react';

import { Divider, Skeleton, Stack } from '@mui/material';

type EvaluationHistoryCardSkeletonProps = {
  isDividerVisible?: boolean;
};

export const EvaluationHistoryCardSkeleton: FC<EvaluationHistoryCardSkeletonProps> = ({ isDividerVisible = true }) => {
  return (
    <>
      <Stack p={2} spacing={2}>
        <Stack direction={{ xs: 'column', lg: 'row' }} alignItems="center" justifyContent="space-between" spacing={1}>
          <Stack direction={{ xs: 'column', lg: 'row' }} spacing={1} alignItems="center">
            <Skeleton variant="circular" width={32} height={32} />
            <Skeleton variant="rounded" width={120} height={20} />
            <Skeleton variant="rounded" width={140} height={25} />
            <Skeleton variant="rounded" width={140} height={25} />
          </Stack>
          <Skeleton variant="rounded" width={100} height={20} />
        </Stack>

        <Stack direction={{ xs: 'column', lg: 'row' }} justifyContent="space-between" gap={2} width={1}>
          <HistoryContentCardSkeleton />
          <HistoryContentCardSkeleton />
          <HistoryContentCardSkeleton />
          <RatingItemsSkeleton />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Skeleton variant="rounded" width={200} height={20} />

          <Skeleton variant="rounded" width={20} height={20} />
        </Stack>
      </Stack>
      {isDividerVisible && <Divider />}
    </>
  );
};

const HistoryContentCardSkeleton = () => (
  <Stack
    sx={{
      maxWidth: { xs: 1, lg: 580 },
      width: 1,
      height: { xs: 70, lg: 'auto' },
    }}
    gap={0.5}
  >
    <Skeleton variant="rounded" height="100%" />
  </Stack>
);

const RatingItemsSkeleton = () => (
  <Stack
    gap={1}
    flexDirection={{ xs: 'column', sm: 'row', lg: 'column' }}
    alignItems={{ xs: 'flex-start', sm: 'center' }}
  >
    {[...Array(2)].map((_, index) => (
      <Stack key={index} alignItems="flex-start" spacing={0.5}>
        <Skeleton variant="rounded" width={60} height={15} />
        <Skeleton variant="rounded" width={120} height={20} />
      </Stack>
    ))}
  </Stack>
);
