import { FC } from 'react';

import { Box, Card, Dialog, Stack, Table, TableBody, TableContainer, Typography } from '@mui/material';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { PopoverWrapper } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import {
  CustomTableHeader,
  CustomTablePagination,
  TableFiltersPanel,
  TableNoData,
  TableSkeleton,
} from 'components/Table';
import { FilterPopoverContent, TagCategoryListTableRow } from 'pages/TagManagement/components';
import { TAG_LIST_TABLE_HEADERS } from 'pages/TagManagement/config';

import { CreateTagCategoryMenuContent } from './components';
import { usePracticeLibraryTagListState } from './usePracticeLibraryTagList.state';

export const PracticeLibraryTagList: FC = () => {
  const {
    closeDeleteDialogWindow,
    isOpenDeleteDialogWindow,
    onChangeSearchInputValue,
    searchValue,
    closeFiltersMenu,
    isFiltersMenuOpen,
    openFilterMenu,
    data,
    isLoading,
    onGetTagCategoriesHandler,
    onChangeRowsPerPage,
    rowsPerPage,
    total,
    onChangePageHandler,
    page,
    appliedFilters,
    isActivateDialogOpen,
    isDeactivateDialogOpen,
    onClickActionOptionHandler,
    onDeactivateTagCategoryHandler,
    closeActivateDialog,
    closeDeactivateDialog,
    actionState,
    isActionPending,
    onActivateTagCategoryHandler,
    onApplyFilters,
    isFiltersApplied,
    onSort,
    order,
    orderBy,
    isAddTagCategoryDialogOpen,
    openAddTagCategoryDialog,
    closeAddTagCategoryDialog,
    isRoleAdmin,
    isViewOnly,
  } = usePracticeLibraryTagListState();

  return (
    <Card sx={{ borderRadius: 2, overflow: 'hidden' }}>
      <TableFiltersPanel
        searchInputProps={{
          value: searchValue,
          placeholder: 'Search by Name',
          onChange: onChangeSearchInputValue,
        }}
        onOpenFilterMenuHandler={openFilterMenu}
        actionButtonProps={
          !isViewOnly && {
            children: 'Add tag category',
            onClick: openAddTagCategoryDialog,
          }
        }
        isFiltersApplied={isFiltersApplied}
      />

      <TableContainer>
        <Scrollbar>
          <Table size="medium" sx={{ minWidth: 600 }}>
            <colgroup>
              <Box component="col" width={isViewOnly ? '70%' : '50%'} />
              <Box component="col" width="20%" />
              <Box component="col" width="20%" />
              {!isViewOnly && <Box component="col" width="10%" />}
            </colgroup>
            <CustomTableHeader
              headLabel={isViewOnly ? TAG_LIST_TABLE_HEADERS.slice(0, 3) : TAG_LIST_TABLE_HEADERS}
              onSort={onSort}
              order={order}
              orderBy={orderBy}
            />

            <TableBody>
              {isLoading ? (
                <TableSkeleton cellsAmount={isViewOnly ? 3 : 4} />
              ) : (
                data?.map(el => (
                  <TagCategoryListTableRow
                    key={el?.id}
                    onClickActivationOptionHandler={onClickActionOptionHandler}
                    tagCategoryName={el?.name}
                    categoryTags={el?.tags}
                    tagCategoryServiceName={el?.serviceName}
                    id={el?.id}
                    isPublished={el?.isPublished}
                    isActive={el?.isActive}
                    isViewOnly={isViewOnly}
                  />
                ))
              )}
              <TableNoData isNotFound={!isLoading && !data?.length} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <CustomTablePagination
        count={total}
        page={page - 1}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePageHandler}
        onRowsPerPageChange={onChangeRowsPerPage}
      />

      <Dialog
        open={isAddTagCategoryDialogOpen}
        onClose={closeAddTagCategoryDialog}
        PaperProps={{
          sx: { width: 1 },
        }}
      >
        <CreateTagCategoryMenuContent
          fetchTagCategories={onGetTagCategoriesHandler}
          closeMenuHandler={closeAddTagCategoryDialog}
          isPracticeField={isRoleAdmin}
        />
      </Dialog>

      <BaseDialogWindow
        onClickCancelButtonHandler={closeDeleteDialogWindow}
        open={isOpenDeleteDialogWindow}
        description="Are you sure you want to delete tag category?"
        title="Deleting Tag Category"
        isApproveButtonDisabled={false}
        isApproveButtonLoading={false}
        approveButtonTitle="Delete tag category"
        onClickApproveButtonHandler={() => {}}
      />

      <BaseDialogWindow
        open={isDeactivateDialogOpen}
        onClickCancelButtonHandler={closeDeactivateDialog}
        description={
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight={400}>
              Are you sure you want to inactivate{' '}
              <Typography variant="button" fontWeight={700}>
                {actionState.name}
              </Typography>{' '}
              tag category?
            </Typography>
          </Stack>
        }
        title="Inactivate Tag Category"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Inactivate tag category"
        onClickApproveButtonHandler={onDeactivateTagCategoryHandler}
      />

      <BaseDialogWindow
        open={isActivateDialogOpen}
        onClickCancelButtonHandler={closeActivateDialog}
        description={
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight={400}>
              Are you sure you want to activate{' '}
              <Typography variant="button" fontWeight={700}>
                {actionState.name}
              </Typography>{' '}
              tag category?
            </Typography>
          </Stack>
        }
        title="Activate Tag Category"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Activate tag category"
        onClickApproveButtonHandler={onActivateTagCategoryHandler}
      />

      <PopoverWrapper
        open={isFiltersMenuOpen}
        title="Filter Tag Categories"
        handleClosePopover={closeFiltersMenu}
        sx={{ mt: 1, px: 2, py: 1 }}
        variant="filter"
      >
        <FilterPopoverContent appliedFilters={appliedFilters} onApplyFilters={onApplyFilters} />
      </PopoverWrapper>
    </Card>
  );
};
