import { useCallback, useState } from 'react';

import { useBoolean } from './useBoolean';

export type ActionDataInitialState = {
  id: string;
  name: string;
  actionType: ActionType | null;
};

export type UseActionDialogManagementProps = {
  openActivateDialog: () => void;
  openDeactivateDialog: () => void;
  onClickActionOptionHandler: (actionStateData: ActionDataInitialState) => void;
  isDeactivateDialogOpen: boolean;
  closeDeactivateDialog: () => void;
  isActivateDialogOpen: boolean;
  closeActivateDialog: () => void;
  actionState: ActionDataInitialState;
  isActionPending: boolean;
  setIsActionPending: (value: boolean) => void;
  isOpenDeleteDialogWindow: boolean;
  openDeleteDialogWindow: () => void;
  closeDeleteDialogWindow: () => void;
  isOpenCopyDialogWindow: boolean;
  openCopyDialogWindow: () => void;
  closeCopyDialogWindow: () => void;
  isOpenPublishDialogWindow: boolean;
  openPublishDialogWindow: () => void;
  closePublishDialogWindow: () => void;
  isOpenUnpublishDialogWindow: boolean;
  openUnpublishDialogWindow: () => void;
  closeUnpublishDialogWindow: () => void;
};

const ActionDataInitialStateValue: ActionDataInitialState = {
  actionType: null,
  id: '',
  name: '',
};

export const useActionDialogManagement = (): UseActionDialogManagementProps => {
  const [isActionPending, setIsActionPending] = useState(false);

  const [actionState, setActionState] = useState(ActionDataInitialStateValue);

  const [isActivateDialogOpen, openActivateDialog, closeActivateDialog] = useBoolean();

  const [isDeactivateDialogOpen, openDeactivateDialog, closeDeactivateDialog] = useBoolean();

  const [isOpenDeleteDialogWindow, openDeleteDialogWindow, closeDeleteDialogWindow] = useBoolean();

  const [isOpenCopyDialogWindow, openCopyDialogWindow, closeCopyDialogWindow] = useBoolean();

  const [isOpenPublishDialogWindow, openPublishDialogWindow, closePublishDialogWindow] = useBoolean();

  const [isOpenUnpublishDialogWindow, openUnpublishDialogWindow, closeUnpublishDialogWindow] = useBoolean();

  const onClickActionOptionHandler = useCallback((actionStateData: ActionDataInitialState) => {
    setActionState(actionStateData);

    switch (actionStateData.actionType) {
      case 'activate':
        return openActivateDialog();
      case 'deactivate':
        return openDeactivateDialog();
      case 'copy':
        return openCopyDialogWindow();
      case 'delete':
        return openDeleteDialogWindow();
      case 'publish':
        return openPublishDialogWindow();
      case 'unpublish':
        return openUnpublishDialogWindow();
      default:
        break;
    }
  }, []);

  return {
    openActivateDialog,
    openDeactivateDialog,
    onClickActionOptionHandler,
    isDeactivateDialogOpen,
    closeDeactivateDialog,
    isActivateDialogOpen,
    closeActivateDialog,
    actionState,
    isActionPending,
    setIsActionPending,
    isOpenDeleteDialogWindow,
    openDeleteDialogWindow,
    closeDeleteDialogWindow,
    isOpenCopyDialogWindow,
    openCopyDialogWindow,
    closeCopyDialogWindow,
    isOpenPublishDialogWindow,
    openPublishDialogWindow,
    closePublishDialogWindow,
    isOpenUnpublishDialogWindow,
    openUnpublishDialogWindow,
    closeUnpublishDialogWindow,
  };
};
