import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { CreatePromptPayload, updatePrompt } from 'apiServices';
import { Resolver, useForm } from 'react-hook-form';
import { useAppStore } from 'store';

import { ToastType, notice } from 'components/ToastNotification';
import { useRouter } from 'hooks';
import { createPromptValidationSchema } from 'pages/PromptLibrary/PromptListTable/components';
import { backendErrorHandler } from 'utils/errorHanders';

import { UpdatePromptTabContentProps } from './UpdatePromptTabContent';

export const useUpdatePromptTabContentState = ({ prompt }: Pick<UpdatePromptTabContentProps, 'prompt'>) => {
  const { params } = useRouter();

  const promptId = params?.id;

  const setPrompt = useAppStore(store => store.setPrompt);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, isDirty },
  } = useForm<CreatePromptPayload>({
    mode: 'onTouched',
    resolver: yupResolver(createPromptValidationSchema) as Resolver<CreatePromptPayload>,
    values: {
      name: prompt?.name || '',
      text: prompt?.text || '',
      comment: prompt?.comment || '',
    },
  });

  const isSubmitButtonDisabled = !isValid || isSubmitting || !isDirty;

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      const { data } = await updatePrompt(promptId, formData);

      setPrompt(data);

      notice(ToastType.SUCCESS, 'Prompt has been successfully updated!');
    } catch (error) {
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to update prompt, please try again!' } });
    }
  });

  return {
    register,
    errors,
    isSubmitButtonDisabled,
    onFormSubmitHandler,
    isDirty,
    isSubmitting,
    control,
  };
};
