import { useEffect, useState } from 'react';

import { GLServicesSearchParams, importToPLService } from 'apiServices';
import {
  activateGLService,
  deactivateGLService,
  deleteGLService,
  getGLServices,
} from 'apiServices/dictionary/servicesGlobalLibrary.api';
import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { useTableState } from 'hooks/useTableState';
import { useUserProfile } from 'hooks/useUserProfile';
import { CATALOG_SERVICE_MESSAGES } from 'pages/Catalog/constants';
import { backendErrorHandler } from 'utils/errorHanders';

export const useGLServicesState = () => {
  const {
    GLServices: { items, total },
    setGLServices,
  } = useAppStore(
    store => ({
      GLServices: store.catalogGLData.GLServices,
      setGLServices: store.setGLServices,
    }),
    shallow
  );

  const {
    userRoles: { isRoleAdmin, isRolePracticeUser },
    practiceId,
  } = useUserProfile();

  const [isLoading, setIsLoading] = useState(true);

  const onGetServicesHandler = async (params?: GLServicesSearchParams) => {
    !isLoading && setIsLoading(true);

    try {
      const { data } = await getGLServices({
        ...(debouncedSearchValue && { search: debouncedSearchValue }),
        size: rowsPerPage,
        page,
        ...params,
      });

      setGLServices({
        items: data.items,
        page: data.page,
        pages: data.pages,
        size: data.size,
        total: data.total,
      });

      if (params?.page === 1 && page !== 1) setPage(1);
    } catch (error) {
      console.error(error);
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get services, please try again!' } });
    } finally {
      setIsLoading(false);
    }
  };

  const {
    onChangeSearchInputValue,
    isOpenDeleteDialogWindow,
    isActivateDialogOpen,
    isDeactivateDialogOpen,
    onChangeRowsPerPage,
    onChangePage,
    page,
    searchValue,
    rowsPerPage,
    debouncedSearchValue,
    appliedFilters,
    isResettingPage,
    onDeactivateServiceHandler,
    onDeleteServiceHandler,
    isActionPending,
    closeAddItemDialog,
    closeDeactivateDialog,
    closeFiltersMenu,
    onApplyFilters,
    isFiltersApplied,
    onActivateServiceHandler,
    actionState,
    isFiltersMenuOpen,
    openFilterMenu,
    closeDeleteDialogWindow,
    setPage,
    resetPageHandler,
    isAddItemDialogOpen,
    openAddItemDialog,
    onClickActionOptionHandler,
    closeActivateDialog,
    closeCopyDialogWindow,
    isOpenCopyDialogWindow,
    onCopyItemHandler,
    handleChangeCategoryName,
    categoryName,
  } = useTableState({
    fetchItems: onGetServicesHandler,
    activateItem: activateGLService,
    deleteItem: deleteGLService,
    deactivateItem: deactivateGLService,
    copyItem: importToPLService,
    practiceId,
    messages: {
      activationError: CATALOG_SERVICE_MESSAGES.activateFailed,
      activationSuccess: CATALOG_SERVICE_MESSAGES.activateSuccess,
      deactivationError: CATALOG_SERVICE_MESSAGES.inactivateFailed,
      deactivationSuccess: CATALOG_SERVICE_MESSAGES.inactivateSuccess,
      deletionError: CATALOG_SERVICE_MESSAGES.deleteFailed,
      deletionSuccess: CATALOG_SERVICE_MESSAGES.deleteSuccess,
      copyError: CATALOG_SERVICE_MESSAGES.copyFailed,
      copySuccess: CATALOG_SERVICE_MESSAGES.copySuccess,
    },
  });

  useEffect(() => {
    onGetServicesHandler({ ...(isResettingPage.current && { page: 1 }) });

    resetPageHandler();
  }, [debouncedSearchValue, appliedFilters, rowsPerPage]);

  return {
    searchValue,
    onChangeSearchInputValue,
    isOpenDeleteDialogWindow,
    closeDeleteDialogWindow,
    closeFiltersMenu,
    openFilterMenu,
    isFiltersMenuOpen,
    items,
    isLoading,
    total,
    onChangeRowsPerPage,
    rowsPerPage,
    onChangePage,
    page,
    appliedFilters,
    isActionPending,
    isAddServiceDialogOpen: isAddItemDialogOpen,
    openAddServiceDialog: openAddItemDialog,
    closeAddServiceDialog: closeAddItemDialog,
    onGetServicesHandler,
    onActivateServiceHandler,
    onDeactivateServiceHandler,
    onClickActionOptionHandler,
    isDeactivateDialogOpen,
    isActivateDialogOpen,
    closeDeactivateDialog,
    closeActivateDialog,
    actionState,
    onApplyFilters,
    isFiltersApplied,
    isRoleAdmin,
    onDeleteServiceHandler,
    closeCopyDialogWindow,
    onCopyItemHandler,
    isOpenCopyDialogWindow,
    handleChangeCategoryName,
    categoryName,
    isRolePracticeUser,
  };
};
