import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  PracticeLibrarySearchParams,
  TagListSortFieldName,
  UpdatePracticeLibraryTagCategoryPayload,
  createPracticeLibraryTag,
  deletePracticeLibraryTag,
  deletePracticeLibraryTagCategory,
  getPracticeLibraryTagCategory,
  getPracticeLibraryTags,
  updatePracticeLibraryTag,
  updatePracticeLibraryTagCategory,
} from 'apiServices';
import { Resolver, useForm } from 'react-hook-form';
import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { ToastType, notice } from 'components/ToastNotification';
import { useBoolean } from 'hooks/useBoolean';
import { useTable } from 'hooks/useTable';
import {
  CreateTagCategoryFormSchema,
  createTagCategoryValidationSchema,
  useEditTagCategoryState,
} from 'pages/TagManagement';
import { backendErrorHandler, formErrorHandler } from 'utils/errorHanders';

export const usePLtagCategoryProfile = () => {
  const {
    practiceLibraryTagCategoryData: {
      category,
      tags: { data: tags, total },
    },
    setPracticeLibraryTagCategory,
    setPracticeLibraryTags,
  } = useAppStore(
    store => ({
      practiceLibraryTagCategoryData: store.practiceLibraryTagCategoryData,
      setPracticeLibraryTagCategory: store.setPracticeLibraryTagCategory,
      setPracticeLibraryTags: store.setPracticeLibraryTags,
    }),
    shallow
  );

  const isTagCategoryPublished = category?.isPublished;
  const isTagCategoryActive = category?.isActive;

  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting, isDirty },
  } = useForm<CreateTagCategoryFormSchema>({
    resolver: yupResolver(createTagCategoryValidationSchema) as Resolver<CreateTagCategoryFormSchema>,
    mode: 'onTouched',
    values: { name: category?.name || '', isPublished: category?.isPublished || false },
  });

  const onFormSubmitHandler = handleSubmit(async ({ name, isPublished }) => {
    try {
      const { data } = await updatePracticeLibraryTagCategory(categoryId, {
        isActive: category?.isActive,
        isPublished,
        name,
      });
      setPracticeLibraryTagCategory(data);

      notice(ToastType.SUCCESS, 'Tag category has been successfully updated!');
    } catch (error) {
      formErrorHandler({
        error,
        config: { formError: { setError } },
        customErrorMessage: 'Failed to update tag category, please try again!',
      });
    }
  });

  const isSubmitButtonDisabled = !isValid || isSubmitting || !isDirty;

  const onActivate = async (id: string) => {
    await updatePracticeLibraryTag(id, { isActive: true, categoryId });

    notice(ToastType.SUCCESS, 'Tag has been successfully activated!');

    await onGetTagsByCategoryHandler({ isResetPage: true, params: { page: 1 } });
  };

  const onDeactivate = async (id: string) => {
    await deletePracticeLibraryTag(id);

    notice(ToastType.SUCCESS, 'Tag has been successfully inactivated!');

    await onGetTagsByCategoryHandler({ isResetPage: true, params: { page: 1 } });
  };

  const updateTagCategory = async (
    updateData: Partial<UpdatePracticeLibraryTagCategoryPayload>,
    successMessage: string
  ) => {
    await updatePracticeLibraryTagCategory(categoryId, {
      isActive: category?.isActive,
      name: category?.name,
      serviceName: category?.serviceName,
      ...updateData,
    });

    await getTagCategory();

    notice(ToastType.SUCCESS, successMessage);
  };

  const onActivateTagCategory = async () =>
    await updateTagCategory({ isActive: true }, 'Tag category has been successfully activated!');

  const onDeactivateTagCategory = async () => {
    try {
      await deletePracticeLibraryTagCategory(categoryId);
      await getTagCategory();
      notice(ToastType.SUCCESS, 'Tag category has been successfully inactivated!');
    } catch (error) {
      console.error('Error deactivating tag category:', error);
      notice(ToastType.ERROR, 'Failed to deactivate the tag category. Please try again.');
    }
  };

  const onPublishTagCategory = async () =>
    await updateTagCategory({ isPublished: true }, 'Tag category has been successfully published!');

  const onUnpublishTagCategory = async () =>
    await updateTagCategory({ isPublished: false }, 'Tag category has been successfully unpublished!');

  const { order, orderBy, onSort, page, rowsPerPage, onChangeRowsPerPage, setPage, isResettingPage, resetPageHandler } =
    useTable<TagListSortFieldName>({ defaultOrderBy: 'name', defaultOrder: 'asc' });

  const {
    categoryId,
    searchValue,
    debouncedSearchValue,
    appliedFilters,
    selectedTagId,
    closeAddTagMenu,
    setSelectedTagId,
    onChangeSearchInputValue,
    closeEditTagMenu,
    userRoles: { isRoleAdmin, isRolePracticeAdmin },
    ...restEditState
  } = useEditTagCategoryState({
    onActivate,
    onDeactivate,
    isResettingPage,
    onActivateTagCategory,
    onDeactivateTagCategory,
    onPublishTagCategory,
    onUnpublishTagCategory,
  });

  const isViewOnly = !isRoleAdmin && !isRolePracticeAdmin;

  const [isOpenDeleteDialogWindow, openDeleteDialogWindow, closeDeleteDialogWindow] = useBoolean();

  const [isLoading, setIsLoading] = useState(true);

  const getTagCategory = async () => {
    try {
      const { data } = await getPracticeLibraryTagCategory(categoryId);

      setPracticeLibraryTagCategory(data);
    } catch (error) {
      console.error(error);
      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get tag category, please try again!' } });
    }
  };

  const onGetTagCategoryHandler = async () => {
    !isLoading && setIsLoading(true);

    await getTagCategory();
    setIsLoading(false);
  };

  const [isLoadingTags, setIsLoadingTags] = useState(true);

  const onGetTagsByCategoryHandler = async ({
    isResetPage,
    params,
  }: {
    isResetPage?: boolean;
    params?: PracticeLibrarySearchParams;
  }) => {
    !isLoading && setIsLoadingTags(true);

    try {
      const { data } = await getPracticeLibraryTags({
        tag_category_id: categoryId,
        order: [`${orderBy},${order}`],
        ...(debouncedSearchValue && { search: debouncedSearchValue }),
        is_active: !appliedFilters.isShowDeactivated,
        size: rowsPerPage,
        page,
        ...params,
      });

      setPracticeLibraryTags({
        data: data.items,
        page: data.page,
        pages: data.pages,
        size: data.size,
        total: data.total,
      });

      if (isResetPage && page !== 1) {
        setPage(1);
      }
    } catch (error) {
      console.error(error);
      backendErrorHandler({
        error,
        config: { customErrorMessage: 'Failed to get tags by category, please try again!' },
      });
    } finally {
      setIsLoadingTags(false);
    }
  };

  const onChangePage = async (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const newPage = page + 1;
    setPage(newPage);
    await onGetTagsByCategoryHandler({ params: { page: newPage } });
  };

  useEffect(() => {
    onGetTagCategoryHandler();
  }, []);

  useEffect(() => {
    if (categoryId) {
      onGetTagsByCategoryHandler({ params: { ...(isResettingPage.current && { page: 1 }) } });
      resetPageHandler();
    }
  }, [debouncedSearchValue, appliedFilters, orderBy, order]);

  const createTagHandler = async (name: string) => {
    await createPracticeLibraryTag({
      isActive: true,
      name,
      categoryId,
    });

    closeAddTagMenu();

    await onGetTagsByCategoryHandler({ isResetPage: true, params: { page: 1 } });
  };

  const editTagHandler = async (name: string, isActive: boolean) => {
    await updatePracticeLibraryTag(selectedTagId, {
      isActive,
      name,
      categoryId,
    });

    closeEditTagMenu();
    setSelectedTagId('');
    await onGetTagsByCategoryHandler({});
  };

  return {
    searchValue,
    onChangeSearchInputValue,
    isOpenDeleteDialogWindow,
    openDeleteDialogWindow,
    closeDeleteDialogWindow,
    tags,
    register,
    isSubmitButtonDisabled,
    onFormSubmitHandler,
    isSubmitting,
    isLoading,
    isLoadingTags,
    total,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    createTagHandler,
    editTagHandler,
    order,
    orderBy,
    onSort,
    isViewOnly,
    closeAddTagMenu,
    selectedTagId,
    closeEditTagMenu,
    appliedFilters,
    isTagCategoryActive,
    isTagCategoryPublished,
    category,
    isDirty,
    ...restEditState,
  };
};
