export const ERROR_MESSAGES = {
  smt_went_wrong: 'Something went wrong, please try again!',
  unsupportedFileFormat: `The file you've uploaded is not supported. Please upload a file in one of the following formats:`,
};

export const UNSUPPORTED_FILE_TYPE_TITLE = 'Unsupported file type';

export const VERIFY_TOTP_CODE_TITLES = {
  title: 'Verification code',
  description: 'Enter the 6-digit verification code from your TOTP application',
  backButton: 'Back to Login',
};

export const LIBRARIES_NAMES = {
  global: 'Global Library',
  practice: 'Practice Library',
};
