import { FC } from 'react';

import { Stack } from '@mui/material';

import { EntityHeaderMainWrapperProps } from '../types';

export const EntityHeaderMainWrapper: FC<EntityHeaderMainWrapperProps> = ({ children, ...stackProps }) => {
  return (
    <Stack
      direction={{ xs: 'column', lg: 'row' }}
      alignItems={{ xs: 'center', sm: 'flex-start' }}
      gap={2}
      width={1}
      justifyContent="space-between"
      {...stackProps}
    >
      {children}
    </Stack>
  );
};
