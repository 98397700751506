import { FC } from 'react';

import { Grid } from '@mui/material';

import { FeedbackContent, OptionsContent, PromptContent } from './components';
import { useTestingTabContentState } from './useTestingTabContent.state';

export const TestingTabContent: FC = () => {
  const {
    isLoadingPromptText,
    userPromptText,
    promptResponse,
    isFeedback,
    getUserPromptText,
    executePromptHandler,
    submitFeedbackHandler,
  } = useTestingTabContentState();

  return (
    <Grid container flex={{ md: 0, lg: 1 }} flexWrap={{ xs: 'wrap-reverse', lg: 'wrap' }}>
      <Grid
        item
        xs={12}
        md={12}
        lg={8}
        flex={1}
        sx={({ breakpoints, borders }) => ({
          borderRight: `${borders.borderWidth[1]} solid ${borders.borderColor}`,
          [breakpoints.down('lg')]: {
            borderRight: 0,
            borderTop: `${borders.borderWidth[1]} solid ${borders.borderColor}`,
          },
        })}
      >
        <PromptContent
          isLoadingPromptText={isLoadingPromptText}
          promptText={promptResponse?.result || userPromptText?.text}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        {isFeedback ? (
          <FeedbackContent submitFeedbackHandler={submitFeedbackHandler} />
        ) : (
          <OptionsContent
            getUserPromptText={getUserPromptText}
            executePromptHandler={executePromptHandler}
            isLoadingPromptText={isLoadingPromptText}
          />
        )}
      </Grid>
    </Grid>
  );
};
