import { PropsWithChildren, useEffect } from 'react';

import { Navigate } from 'react-router-dom';

import { ToastType, notice } from 'components/ToastNotification';
import { ROUTES } from 'constants/routes';
import { useUserProfile } from 'hooks';
import { UserRoles } from 'types/enums';

type RoleGuardRouterProps = PropsWithChildren & {
  allowedUserRoles: UserRoles[];
  navigateTo?: ROUTES;
};

export const RoleGuardRouter = ({ allowedUserRoles, navigateTo, children }: RoleGuardRouterProps) => {
  const userRole = useUserProfile().userRole;

  const isUserHasAccess = allowedUserRoles.includes(userRole);

  useEffect(() => {
    if (!isUserHasAccess) notice(ToastType.WARNING, "You don't have permission to access this page!");
  }, [isUserHasAccess]);

  if (isUserHasAccess) return <>{children}</>;

  return <Navigate to={navigateTo ?? ROUTES.home} replace />;
};
