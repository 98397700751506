import { startTransition, useEffect, useState } from 'react';

import { deletePatientAvatar, uploadPatientAvatar } from 'apiServices';
import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { MM_DD_YYYY_DATE_FORMAT } from 'constants/dateFormats';
import { useUserProfile } from 'hooks';
import { useRouter } from 'hooks/useRouter';
import { useTabs } from 'hooks/useTabs';
import { calculateAge, dateToCustomFormat } from 'utils/helpers';

import { PATIENT_TABS } from './types';

const tabs = [PATIENT_TABS.PROFILE, PATIENT_TABS.MEDICAL, PATIENT_TABS.PATIENT_PROFILE, PATIENT_TABS.PATIENT_CONCERNS];

export const usePatientViewState = () => {
  const { params } = useRouter();

  const { setPatient, patient, fetchPatient } = useAppStore(
    store => ({
      patient: store.patient,
      fetchPatient: store.fetchPatient,
      setPatient: store.setPatient,
    }),
    shallow
  );

  const { person, birthDate } = patient || {};

  const [isLoading, setIsLoading] = useState(true);

  const onGetPatientHandler = async () => {
    !isLoading && setIsLoading(true);
    await fetchPatient(params?.id);
    setIsLoading(false);
  };

  useEffect(() => {
    onGetPatientHandler();

    return () => setPatient(null);
  }, []);

  const { setTabValue, tabValue } = useTabs();

  const fullName = `${person?.firstName} ${person?.lastName}`;
  const fullNameWidthMiddleName = `${person?.firstName} ${person?.middleName} ${person?.lastName}`;

  const formatedBirthDate = dateToCustomFormat(birthDate, MM_DD_YYYY_DATE_FORMAT);
  const age = calculateAge(birthDate);

  const isNoPatient = !isLoading && !patient;

  const handleSetTabValue = (_: React.SyntheticEvent<Element, Event>, newValue: number) =>
    startTransition(() => {
      setTabValue(newValue);
    });

  const [croppedImageSrc, setCroppedImageSrc] = useState<string | null>(null);

  const onUploadAvatarHandler = async (formData: FormData, croppedImageSrc: string) => {
    setCroppedImageSrc(croppedImageSrc);
    await uploadPatientAvatar(params?.id, formData);
    await fetchPatient(params?.id);
  };

  const onDeleteAvatarHandler = async () => {
    await deletePatientAvatar(params?.id);

    croppedImageSrc && setCroppedImageSrc(null);
    await fetchPatient(params?.id);
  };

  const avatarSrc = croppedImageSrc || patient?.avatarUrl || '';

  const {
    userRoles: { isRoleAdmin, isRolePracticeAdmin },
    personId,
  } = useUserProfile();

  const isCanEditAvatar = isRoleAdmin || isRolePracticeAdmin || personId === patient?.person?.id;

  return {
    isCanEditAvatar,
    isLoading,
    handleSetTabValue,
    tabValue,
    person,
    formatedBirthDate,
    fullName,
    fullNameWidthMiddleName,
    patient,
    tabs,
    age,
    isNoPatient,
    onGetPatientHandler,
    onUploadAvatarHandler,
    avatarSrc,
    onDeleteAvatarHandler,
  };
};
