import { FC } from 'react';

import { Skeleton, SkeletonProps, Stack, StackProps } from '@mui/material';

type MDInputSkeletonProps = SkeletonProps & {
  isLabel?: boolean;
  wrapperProps?: StackProps;
};

export const MDInputSkeleton: FC<MDInputSkeletonProps> = ({ isLabel = true, wrapperProps, ...inputSkeletonProps }) => {
  return (
    <Stack spacing={1} {...wrapperProps}>
      {isLabel && <Skeleton variant="rounded" height={18} width={100} />}

      <Skeleton variant="rounded" height={30} {...inputSkeletonProps} />
    </Stack>
  );
};
