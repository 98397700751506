import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { IllustrationLayout } from 'layouts/IllustrationLayout';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import MDButton from 'components/MDButton';
import { Scrollbar } from 'components/ScrollBar';

import { RecentlyLoggedInUserItem } from './RecentlyLoggedInUserItem';
import { useRecentlyLoggedInUsersState } from './useRecentlyLoggedInUsers.state';

export const RecentlyLoggedInUsers: FC = () => {
  const {
    selectedEmail,
    closeRemoveDialog,
    recentlyLoggedInUsers,
    isRemoveDialogOpen,
    onClickRemoveUserHandler,
    onClickUserItemHandler,
    openRemoveDialogHandler,
    onClickChangeUserHandler,
  } = useRecentlyLoggedInUsersState();

  return (
    <>
      <IllustrationLayout title="Recently Logged In" description="Please select user">
        <Box component="section">
          <Stack spacing={1.5}>
            <Box
              sx={{
                borderRadius: theme => theme.borders.borderRadius.lg,
                p: 1.5,
                bgcolor: theme => theme.palette.grey[200],
              }}
            >
              <Scrollbar sx={{ maxHeight: 280, height: 1 }}>
                <Stack gap={1}>
                  {recentlyLoggedInUsers.map(user => (
                    <RecentlyLoggedInUserItem
                      key={user.email}
                      email={user.email}
                      onRemoveUserHandler={openRemoveDialogHandler}
                      onClickUserItemHandler={onClickUserItemHandler}
                    />
                  ))}
                </Stack>
              </Scrollbar>
            </Box>

            <MDButton variant="contained" onClick={onClickChangeUserHandler}>
              Change User
            </MDButton>
          </Stack>
        </Box>
      </IllustrationLayout>

      <BaseDialogWindow
        fullWidth
        open={isRemoveDialogOpen}
        title="Remove User"
        onClickCancelButtonHandler={closeRemoveDialog}
        description={
          <Typography variant="body2">
            Are you sure you want to remove{' '}
            <Typography sx={{ wordBreak: 'break-all' }} variant="body2" component="span" fontWeight={500}>
              {selectedEmail}{' '}
            </Typography>
            from Recently Logged in list?
          </Typography>
        }
        approveButtonTitle="Remove"
        onClickApproveButtonHandler={onClickRemoveUserHandler}
      />
    </>
  );
};
