import { FC } from 'react';

import { Box, Button, Card, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material';

import { Icon } from 'components/Icon';
import { MDTabs } from 'components/MDTabs';

import { EvaluationHistoryTabContent } from './EvaluationHistoryTabContent';
import { TestingTabContent } from './TestingTabContent';
import { UpdatePromptTabContent, UpdatePromptTabContentProps } from './UpdatePromptTabContent';
import { TABS } from './config';
import { usePromptViewState } from './usePromptView.state';

const renderTabContent = (tabValue: number, updateTabProps: UpdatePromptTabContentProps) => {
  switch (tabValue) {
    case 1:
      return <TestingTabContent />;
    case 2:
      return <EvaluationHistoryTabContent />;
    default:
      return <UpdatePromptTabContent {...updateTabProps} />;
  }
};

export const PromptView: FC = () => {
  const { handleSetTabValue, tabValue, back, prompt, isLoading } = usePromptViewState();

  return (
    <Box component="section" flex={1} display="flex">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ height: 1 }}>
            <Stack>
              <Stack px={2} pt={1.5} alignItems="flex-start" justifyContent="flex-start">
                <Button
                  onClick={back}
                  variant="text"
                  sx={{ fontWeight: 500, color: theme => theme.palette.common.black, fontSize: 14, px: 0.5 }}
                  startIcon={<Icon type="arrowLeftIcon" />}
                >
                  Back
                </Button>

                {isLoading ? (
                  <Skeleton variant="rounded" height={24} width="60%" />
                ) : (
                  <Typography
                    noWrap
                    textAlign={{ xs: 'center', sm: 'start' }}
                    fontSize={{ xs: 14, md: 16, lg: 18 }}
                    fontWeight={500}
                    sx={{
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      display: '-webkit-box',
                      whiteSpace: 'normal',
                    }}
                  >
                    {prompt?.name}
                  </Typography>
                )}
                <Box
                  display="flex"
                  justifyContent={{ xs: 'center', sm: 'flex-start' }}
                  maxWidth={{ xs: 1, sm: 400 }}
                  width={1}
                  mt={1}
                >
                  <MDTabs
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    value={tabValue}
                    tabsData={TABS}
                    handleSetTabValue={handleSetTabValue}
                  />
                </Box>
              </Stack>
              <Divider sx={{ mt: '-1px' }} />
            </Stack>

            {renderTabContent(tabValue, { isLoading, prompt })}
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
