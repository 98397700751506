import { Icon, TIconType } from 'components/Icon';

import { ActionMenuItem, OnClickMenuItemHandler } from './types';

const createMenuItem = (
  title: string,
  iconType: TIconType,
  onClick: OnClickMenuItemHandler,
  iconProps: Record<string, unknown> = {}
): ActionMenuItem => ({
  title,
  icon: <Icon type={iconType} {...iconProps} />,
  onClick,
});

export const getPublishMenuItem = (isPublished: boolean, onClick: OnClickMenuItemHandler): ActionMenuItem =>
  createMenuItem(isPublished ? 'Unpublish' : 'Publish', isPublished ? 'lockIcon' : 'rocketIcon', onClick);

export const getActivateMenuItem = (isActive: boolean, onClick: OnClickMenuItemHandler): ActionMenuItem =>
  createMenuItem(isActive ? 'Deactivate' : 'Activate', isActive ? 'eyeClosed' : 'eyeOpened', onClick);

export const getEditMenuItem = (onClick: OnClickMenuItemHandler): ActionMenuItem =>
  createMenuItem('Edit', 'editPen', onClick);

export const getCopyMenuItem = (onClick: OnClickMenuItemHandler): ActionMenuItem =>
  createMenuItem('Clone', 'copy', onClick);

export const getDeleteMenuItem = (onClick: OnClickMenuItemHandler): ActionMenuItem =>
  createMenuItem('Delete', 'trashBin', onClick, { fontSize: 'medium' });
