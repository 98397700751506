import { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Button, ChipProps, ClickAwayListener, Divider, Typography, styled } from '@mui/material';
import { Resolver, useForm } from 'react-hook-form';

import { MDChip } from 'components/MDChip';
import { MDInput } from 'components/MDInput';
import { PopoverMenuItem as PopoverItem } from 'components/MenuPopover';
import { ToastType, notice } from 'components/ToastNotification';

import { addPatientAllergy, createPatientMedicalCondition } from '../../apiServices';
import { useDictionary } from '../../hooks/useDictionary';
import {
  CreateAllergyFormSchema,
  allergyDefaultValues,
  createAllergyValidationSchema,
} from '../../pages/Patients/pages/PatientView/TabContentPages/PatientProfile/components/CreateAllergyMenuContent/form.config';
import { formErrorHandler } from '../../utils/errorHanders';
import { Icon } from '../Icon';

interface Props {
  isOpened?: boolean;
  anchor: HTMLElement;
  onClose?: () => void;
  id?: string;
  patientId: string;
  fetchPatient: (patientId: string) => Promise<void>;
  isMedicalConditions: boolean;
}

export const SelectorPopupMedical: FC<Props> = props => {
  const { fetchAllergies, fetchMedicalConditions, allergies, medicalConditions } = useDictionary();

  const [step, setStep] = useState<number>(0);
  const [search, setSearch] = useState<string>('');

  const [note, setNote] = useState('');
  const [selected, setSelected] = useState<string | null>(null);

  useEffect(() => {
    if (props.isMedicalConditions) {
      fetchMedicalConditions({ page: 1, size: 50 });
    } else {
      fetchAllergies({ page: 1, size: 100 });
    }
  }, [props.isMedicalConditions]);

  const { setError } = useForm({
    resolver: yupResolver(createAllergyValidationSchema) as Resolver<CreateAllergyFormSchema>,
    mode: 'onTouched',
    defaultValues: allergyDefaultValues,
  });

  const handleSubmit = async () => {
    try {
      if (props.isMedicalConditions) {
        await createPatientMedicalCondition(props.patientId, {
          medicalConditionIcd10Code: selected,
          symptoms: 'Symptoms',
        });
      } else {
        await addPatientAllergy(props.patientId, { allergyIcd10Code: selected });
      }
      await props.fetchPatient(props.patientId);
      notice(
        ToastType.SUCCESS,
        `The ${props.isMedicalConditions ? 'condition' : 'allergy'} has been successfully added!`
      );
    } catch (error) {
      formErrorHandler({
        error,
        config: { formError: { setError } },
        customErrorMessage: `Failed to add ${props.isMedicalConditions ? 'condition' : 'allergy'}, please try again!`,
      });
    }
  };

  const filterOptions = (options: Array<{ name: string; icd10Code: string }>) =>
    options.filter(el => (search.length === 0 ? true : el.name.toLowerCase().includes(search.toLowerCase().trim())));

  const renderSelectOptions = () => {
    const options = props.isMedicalConditions ? medicalConditions : allergies;
    return (
      <Box display="flex" flexDirection="column" gap={2}>
        <MDInput
          onChange={e => setSearch(e.target.value)}
          value={search}
          placeholder={`Search ${props.isMedicalConditions ? 'Condition' : 'Allergy'} `}
        />
        <Box>
          {filterOptions(options).map(el => (
            <PopoverItem
              key={el.icd10Code}
              onClick={() => {
                setSelected(el.icd10Code);
                setStep(1);
              }}
              title={el.name}
            />
          ))}
        </Box>
      </Box>
    );
  };

  const renderSelectDegree = () => (
    <Box>
      <Typography fontSize={14} fontWeight={400} display="flex" alignItems="center" paddingBottom={1} paddingLeft={1}>
        <ArrowBackIosIcon onClick={() => setStep(0)} />
        Select the degree of {props.isMedicalConditions ? 'condition' : 'allergy'}.
      </Typography>
      <Divider />
      {[
        { name: 'Mild', degree: 'primary' },
        { name: 'Moderate', degree: 'success' },
        { name: 'Severe', degree: 'warning' },
      ].map(({ name, degree }) => (
        <PopoverItem
          key={degree}
          onClick={() => setStep(2)}
          element={<MDChip size="small" variant="soft" color={degree as ChipProps['color']} label={name} />}
          title={degree}
        />
      ))}
    </Box>
  );

  const renderLeaveNote = () => (
    <Box>
      <Typography fontSize={14} fontWeight={400} display="flex" alignItems="center" paddingBottom={1} paddingLeft={1}>
        <ArrowBackIosIcon onClick={() => setStep(1)} />
        You can leave a note
      </Typography>
      <Divider />
      <MDInput
        sx={{ paddingBottom: 2 }}
        variant="standard"
        placeholder="You can leave a note if you wish, but it is not mandatory"
        fullWidth
        multiline
        value={note}
        onChange={e => setNote(e.target.value)}
      />
      <Box display="flex" flexDirection="row-reverse">
        <Button
          onClick={() => {
            props.onClose?.();
            handleSubmit();
            setStep(0);
          }}
          startIcon={<Icon type="add" />}
          variant="contained"
        >
          Add an {props.isMedicalConditions ? 'condition' : 'allergy'}
        </Button>
      </Box>
    </Box>
  );

  const renderSteps = () => {
    switch (step) {
      case 0:
        return renderSelectOptions();
      case 1:
        return renderSelectDegree();
      case 2:
        return renderLeaveNote();
      default:
        return null;
    }
  };

  return (
    <ClickAwayListener onClickAway={props.onClose}>
      <BasePopup id={props.id} open={props.isOpened} anchor={props.anchor} onClose={props.onClose}>
        <PopupBody>{renderSteps()}</PopupBody>
      </BasePopup>
    </ClickAwayListener>
  );
};

const PopupBody = styled('div')(
  ({ theme }) => `
  width: 55vw;
  padding: 12px 16px;
  margin: 8px;
  border-radius: 8px;
  border: 1px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200]};
  background-color: ${theme.palette.mode === 'dark' ? theme.palette.grey[900] : '#fff'};
  box-shadow: ${theme.palette.mode === 'dark' ? `0px 4px 8px rgb(0 0 0 / 0.7)` : `0px 4px 8px rgb(0 0 0 / 0.1)`};
  font-weight: 500;
  font-size: 0.875rem;
  z-index: 1;
`
);
