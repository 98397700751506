import { useCallback, useEffect, useRef } from 'react';

import { Stack } from '@mui/material';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

import { TAG_PATTERN_REGEX } from 'constants/regex';
import useResponsive from 'hooks/useResponsive';
import { EntityFieldMap, TagEditorField } from 'pages/PromptLibrary/components';

import { TokensField } from './TokensField';

type RHFTagEditorProps<TField extends FieldValues> = {
  control: Control<TField>;
  name: Path<TField>;
  placeholder?: string;
  label?: string;
  isRequired?: boolean;
};

export const TagEditor = <TField extends FieldValues>({
  control,
  name,
  isRequired,
  label,
  placeholder,
}: RHFTagEditorProps<TField>) => {
  const editorRef = useRef<HTMLDivElement | null>(null);
  const cursorPositionRef = useRef<{ node: Node | null; offset: number }>({ node: null, offset: 0 });

  const storeCursorPosition = useCallback(() => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      cursorPositionRef.current = {
        node: range.startContainer,
        offset: range.startOffset,
      };
    }
  }, []);

  const restoreCursorPosition = useCallback(() => {
    const editor = editorRef.current;
    const selection = window.getSelection();
    const { node, offset } = cursorPositionRef.current;

    if (editor && node && editor.contains(node)) {
      const range = document.createRange();
      range.setStart(node, offset);
      range.collapse(true);
      selection?.removeAllRanges();
      selection?.addRange(range);
    } else if (editor) {
      const range = document.createRange();
      range.selectNodeContents(editor);
      range.collapse(false);
      selection?.removeAllRanges();
      selection?.addRange(range);
    }
  }, []);

  const insertTag = useCallback(
    (tag: string) => {
      const editor = editorRef.current;
      if (editor) {
        restoreCursorPosition();

        const selection = window.getSelection();

        if (!selection || selection.rangeCount === 0) return;
        const range = selection.getRangeAt(0);

        const tagElement = document.createElement('span');
        tagElement.className = 'tag';
        tagElement.contentEditable = 'false';
        tagElement.dataset.tagId = tag;
        tagElement.textContent = tag;

        const closeButton = document.createElement('span');
        closeButton.className = 'tag-close';
        closeButton.contentEditable = 'false';
        closeButton.onclick = e => {
          e.preventDefault();
          tagElement.remove();
          field.onChange(editor.innerHTML);
          storeCursorPosition();
        };

        tagElement.appendChild(closeButton);
        const space = document.createTextNode('\u00A0');
        range.insertNode(space);
        range.insertNode(tagElement);
        range.setStartAfter(space);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
        field.onChange(editor.innerHTML);
        storeCursorPosition();
      }
    },
    [restoreCursorPosition, storeCursorPosition]
  );

  const handlePaste = useCallback(
    (event: React.ClipboardEvent) => {
      event.preventDefault();
      const clipboardData = event.clipboardData?.getData('text/plain');
      const editor = editorRef.current;

      if (!editor || !clipboardData) return;

      const match = clipboardData.match(TAG_PATTERN_REGEX);

      if (match) {
        const value = match?.[0];
        if (!value) return;

        return insertTag(value);
      }

      insertPlainText(clipboardData);
    },
    [insertTag]
  );

  const insertPlainText = useCallback(
    (text: string) => {
      const editor = editorRef.current;
      if (editor) {
        editor.focus();
        restoreCursorPosition();

        const selection = window.getSelection();

        if (selection && selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          range.deleteContents();
          const textNode = document.createTextNode(text);
          range.insertNode(textNode);
          range.setStartAfter(textNode);
          range.collapse(true);
          selection.removeAllRanges();
          selection.addRange(range);

          storeCursorPosition();
        }
      }
    },
    [restoreCursorPosition, storeCursorPosition]
  );

  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  useEffect(() => {
    const editor = editorRef.current;
    if (editor && control._defaultValues.text) {
      editor.innerHTML = control._defaultValues.text;

      const tags = editor.querySelectorAll('.tag');

      tags.forEach(tagElement => {
        const closeButton = tagElement.querySelector<HTMLSpanElement>('.tag-close');

        if (closeButton) {
          closeButton.onclick = e => {
            e.preventDefault();
            tagElement.remove();
            storeCursorPosition();
            field.onChange(editor.innerHTML.trim() || '');
          };
        }
      });

      const range = document.createRange();
      range.selectNodeContents(editor);
      range.collapse(false);

      cursorPositionRef.current = {
        node: range.startContainer,
        offset: range.startOffset,
      };
    }
  }, [control._defaultValues.text]);

  const onInputChangeHandler = useCallback(
    (e: React.FormEvent<HTMLDivElement>) => {
      const lastTag = e.currentTarget.lastElementChild;

      if (lastTag && lastTag.tagName === 'BR') lastTag.remove();

      field.onChange(e.currentTarget.innerHTML.trim() || '');
      storeCursorPosition();
    },
    [storeCursorPosition]
  );

  const onClickLabelHandler = useCallback(() => editorRef.current?.focus(), []);

  const refHandler = useCallback(
    (node: HTMLDivElement) => {
      editorRef.current = node;
      field.ref(node);
    },
    [field]
  );

  const isTablet = useResponsive('down', 'md');

  return (
    <Stack width={1} direction={{ xs: 'column-reverse', md: 'row' }} gap={1}>
      <TagEditorField
        onClickLabelHandler={onClickLabelHandler}
        refHandler={refHandler}
        handlePaste={handlePaste}
        onInputChangeHandler={onInputChangeHandler}
        onInputBlurHandler={field.onBlur}
        storeCursorPosition={storeCursorPosition}
        errorMessage={error?.message}
        isRequired={isRequired}
        placeholder={placeholder}
        label={label}
      />
      <TokensField entityFieldsMap={EntityFieldMap} isFullWidth={isTablet} insertTagHandler={insertTag} />
    </Stack>
  );
};
