import { FC } from 'react';

import { Box, Chip, ChipProps } from '@mui/material';

export type MDChipProps = {
  isDot?: boolean;
  isRounded?: boolean;
} & ChipProps;

export const MDChip: FC<MDChipProps> = ({ color, isDot, isRounded = true, icon, ...other }) => {
  return (
    <Chip
      color={color}
      sx={{
        ...(!isRounded && { borderRadius: theme => theme.borders.borderRadius.md }),
        '.MuiChip-icon': {
          marginLeft: 0,
          marginRight: 0,
        },
      }}
      icon={
        isDot ? (
          <Box
            sx={{
              width: 6,
              height: 6,
              borderRadius: '50%',
              bgcolor: ({ palette }) => (color === 'default' ? palette.grey[800] : palette[color]?.main),
            }}
          />
        ) : (
          icon ?? null
        )
      }
      {...other}
    />
  );
};
