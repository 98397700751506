import React, { memo } from 'react';

import { Box, BoxProps, Typography } from '@mui/material';

import avatarFallback from 'assets/icons/components/avatar_fallback_icon.svg';
import { useImageStatus } from 'hooks';
import { nameAvatar } from 'utils/helpers';

import { MDAvatarSkeleton } from './MDAvatar.skeleton';

export type AvatarSize = keyof typeof AVATAR_SIZES_MAP;

export type MDAvatarProps = BoxProps &
  Partial<{
    name: string;
    src: string;
    avatarSize: AvatarSize;
    variant: 'rectangular' | 'rounded' | 'circular';
    isDisabled: boolean;
    imageProps: BoxProps<'img'>;
    fallbackSrc: string;
  }>;

export const AVATAR_SIZES_MAP = {
  xs: { width: 24, fontSize: 12 },
  sm: { width: 32, fontSize: 14 },
  md: { width: 40, fontSize: 16 },
  lg: { width: 48, fontSize: 18 },
  xl: { width: 56, fontSize: 20 },
  '2xl': { width: 64, fontSize: 24 },
  '3xl': { width: 82, fontSize: 28 },
};

export const MDAvatar: React.FC<MDAvatarProps> = memo(
  ({ src, imageProps, name, avatarSize = 'md', variant = 'circular', sx, isDisabled, fallbackSrc, ...rest }) => {
    const { isImageLoading, isImageLoaded, isImageLoadingError } = useImageStatus({ src, isShouldStartLoading: true });

    const size = AVATAR_SIZES_MAP[avatarSize || 'md'];

    return (
      <Box
        sx={({ palette, borders }) => ({
          width: size.width,
          height: size.width,
          backgroundColor: palette.grey[200],
          ...(!isImageLoading && {
            border: `${borders.borderWidth[1]} solid ${palette.grey[300]}`,
          }),
          color: palette.grey[600],
          fontWeight: 500,
          fontSize: size.fontSize,
          borderRadius: '50%',
          ...(variant === 'rectangular' && {
            borderRadius: 0,
          }),
          ...(variant === 'rounded' && {
            borderRadius: borders.borderRadius.lg,
          }),
          overflow: 'hidden',
          flexShrink: 0,
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          transition: 'all 0.15ms ease-in-out',
          ':hover': {
            cursor: 'initial',
          },
          ...sx,
          ...(isDisabled && {
            cursor: 'initial',
            pointerEvents: 'none',
          }),
        })}
        {...rest}
      >
        <MDAvatarSkeleton
          sx={{ display: src && isImageLoading ? 'flex' : 'none' }}
          avatarSize={avatarSize}
          variant={variant}
        />
        <Box
          component="img"
          src={src}
          sx={{
            width: 1,
            height: 1,
            objectFit: 'cover',
            loading: 'lazy',
            display: src && isImageLoaded ? 'flex' : 'none',
            ...imageProps?.sx,
          }}
          {...imageProps}
        />

        {(!src || isImageLoadingError) &&
          !isImageLoading &&
          (name ? (
            <Typography fontSize={size.fontSize}>{nameAvatar(name)}</Typography>
          ) : (
            <Box
              component="img"
              src={fallbackSrc || avatarFallback}
              alt="Fallback Avatar"
              sx={{
                width: '75%',
                height: '75%',
                objectFit: 'cover',
                ...(fallbackSrc && { width: 1, height: 1 }),
              }}
            />
          ))}
      </Box>
    );
  }
);
