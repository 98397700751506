import { FC } from 'react';

import { Card, Dialog, Stack, Table, TableBody, TableContainer, Typography } from '@mui/material';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { PopoverWrapper } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import {
  CustomTableHeader,
  CustomTablePagination,
  TableNoData,
  TableSkeleton,
  TableTabsFilterPanel,
} from 'components/Table';
import { FiltersMenuContent, ProductsServicesListTableRow, TableTabs, TableTabsProps } from 'pages/Catalog';
import { getPracticeLibraryTableHeaders } from 'pages/Catalog/config';

import { AddProductDialogWindowForm } from './AddProductDialogWindowForm';
import { usePLProductsState } from './usePLProducts.state';

export const PracticeLibraryProducts: FC<TableTabsProps> = ({ handleSetTabValue, tabValue }) => {
  const {
    searchValue,
    onChangeSearchInputValue,
    isOpenDeleteDialogWindow,
    closeDeleteDialogWindow,
    closeFiltersMenu,
    openFilterMenu,
    isFiltersMenuOpen,
    items,
    isLoading,
    total,
    onChangeRowsPerPage,
    rowsPerPage,
    onChangePage,
    page,
    appliedFilters,
    isActionPending,
    onActivateServiceHandler,
    onDeactivateServiceHandler,
    onClickActionOptionHandler,
    isDeactivateDialogOpen,
    isActivateDialogOpen,
    closeDeactivateDialog,
    closeActivateDialog,
    actionState,
    onApplyFilters,
    isFiltersApplied,
    onDeleteServiceHandler,
    closeCopyDialogWindow,
    isOpenCopyDialogWindow,
    onCopyItemHandler,
    closeAddItemDialog,
    isAddItemDialogOpen,
    onGetProductsHandler,
    openAddItemDialog,
    isRolePracticeUser,
    isRoleAdmin,
  } = usePLProductsState();

  const tableHeaders = getPracticeLibraryTableHeaders(isRoleAdmin);

  return (
    <Card>
      <TableTabsFilterPanel
        searchInputProps={{
          value: searchValue,
          placeholder: 'Type to search',
          onChange: onChangeSearchInputValue,
        }}
        onOpenFilterMenuHandler={openFilterMenu}
        filterButtonShown={true}
        actionButtonProps={
          !isRolePracticeUser && {
            children: 'Add product',
            onClick: openAddItemDialog,
          }
        }
        isFiltersApplied={isFiltersApplied}
        tabs={<TableTabs tabValue={tabValue} handleSetTabValue={handleSetTabValue} />}
      />

      <TableContainer>
        <Scrollbar>
          <Table size="medium" sx={{ minWidth: 600 }}>
            <CustomTableHeader headLabel={tableHeaders} />

            <TableBody>
              {isLoading ? (
                <TableSkeleton cellsAmount={isRolePracticeUser ? 2 : isRoleAdmin ? 4 : 3} />
              ) : (
                items.map(el => (
                  <ProductsServicesListTableRow
                    key={el?.id}
                    onClickActivationOptionHandler={onClickActionOptionHandler}
                    description={el?.description}
                    title={el?.title}
                    id={el?.id}
                    practiceId={el?.practiceId}
                    practiceName={isRoleAdmin && el?.practiceName}
                    isActive={el?.isActive}
                    isThreeDotsButton
                    library="pl"
                    type="product"
                    isViewOnly={isRolePracticeUser}
                  />
                ))
              )}
              <TableNoData isNotFound={!isLoading && !items?.length} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <CustomTablePagination
        count={total}
        page={page - 1}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />

      <Dialog
        open={isAddItemDialogOpen}
        onClose={closeAddItemDialog}
        PaperProps={{
          sx: { width: 1, maxWidth: 750 },
        }}
      >
        <AddProductDialogWindowForm
          onCloseDialogHandler={closeAddItemDialog}
          onGetProductsHandler={onGetProductsHandler}
        />
      </Dialog>

      <BaseDialogWindow
        onClickCancelButtonHandler={closeCopyDialogWindow}
        open={isOpenCopyDialogWindow}
        description={
          <Typography textAlign="center" variant="body2">
            Are you sure you want to copy{' '}
            <Typography variant="body2" component="span" fontWeight={600}>
              {actionState.name}
            </Typography>{' '}
            to practice library?
          </Typography>
        }
        title="Copying product"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Copy product"
        onClickApproveButtonHandler={onCopyItemHandler}
      />

      <BaseDialogWindow
        onClickCancelButtonHandler={closeDeleteDialogWindow}
        open={isOpenDeleteDialogWindow}
        description={
          <Typography textAlign="center" variant="body2">
            Are you sure you want to delete{' '}
            <Typography variant="body2" component="span" fontWeight={600}>
              {actionState.name}?
            </Typography>
          </Typography>
        }
        title="Deleting product"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Delete product"
        onClickApproveButtonHandler={onDeleteServiceHandler}
      />

      <BaseDialogWindow
        open={isDeactivateDialogOpen}
        onClickCancelButtonHandler={closeDeactivateDialog}
        description={
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight={400}>
              Are you sure you want to inactivate{' '}
              <Typography variant="button" fontWeight={700}>
                {actionState.name}
              </Typography>{' '}
              product?
            </Typography>
          </Stack>
        }
        title="Inactivate product"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Inactivate product"
        onClickApproveButtonHandler={onDeactivateServiceHandler}
      />

      <BaseDialogWindow
        open={isActivateDialogOpen}
        onClickCancelButtonHandler={closeActivateDialog}
        description={
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight={400}>
              Are you sure you want to activate{' '}
              <Typography variant="button" fontWeight={700}>
                {actionState.name}
              </Typography>{' '}
              product?
            </Typography>
          </Stack>
        }
        title="Activate product"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Activate product"
        onClickApproveButtonHandler={onActivateServiceHandler}
      />

      <PopoverWrapper variant="filter" open={isFiltersMenuOpen} title="Filter" handleClosePopover={closeFiltersMenu}>
        <FiltersMenuContent appliedFilters={appliedFilters} onApplyFilters={onApplyFilters} />
      </PopoverWrapper>
    </Card>
  );
};
