import { ToastType, notice } from 'components/ToastNotification';
import { ERROR_MESSAGES } from 'constants/strings';

type CognitoErrorType = 'LimitExceededException' | 'CodeMismatchException' | 'NotAuthorizedException';

type ErrorHandlerProps = {
  error: unknown;
  customErrorMessage?: string;
  customErrorHandler?: (error: Error) => void;
  errorTypeHandler?: {
    [type in CognitoErrorType]?: (error: Error) => void | Promise<void>;
  };
};

export const awsCognitoErrorHandler = ({
  error,
  customErrorHandler,
  customErrorMessage,
  errorTypeHandler,
}: ErrorHandlerProps) => {
  if (error instanceof Error) {
    if (customErrorHandler) return customErrorHandler(error);

    if (errorTypeHandler && errorTypeHandler[error.name as CognitoErrorType]) {
      return errorTypeHandler[error.name as CognitoErrorType]?.(error);
    }

    return notice(ToastType.ERROR, error.message || ERROR_MESSAGES.smt_went_wrong);
  }

  return notice(ToastType.ERROR, customErrorMessage || ERROR_MESSAGES.smt_went_wrong);
};
