import { FC } from 'react';

import { Box, Card, Dialog, Grid, Table, TableBody, TableContainer, Typography } from '@mui/material';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { Scrollbar } from 'components/ScrollBar';
import {
  CustomTableHeader,
  CustomTablePagination,
  TableFiltersPanel,
  TableNoData,
  TableSkeleton,
} from 'components/Table';
import { ROUTES } from 'constants/routes';

import { AddPromptDialogWindowForm } from './components';
import { PromptLibraryTableRow } from './components/PromptLibraryTableRow';
import { PROMPT_LIBRARY_TABLE_HEADERS } from './table.config';
import { usePromptLibraryState } from './usePromptLibrary.state';

export const PromptList: FC = () => {
  const {
    closeFilterMenu,
    isFilterMenuOpen,
    openFilterMenu,
    isOpenDeleteDialogWindow,
    onClickActionOptionHandler,
    closeDeleteDialogWindow,
    handleDeletePrompt,
    handleSearchChange,
    navigate,
    isLoading,
    searchQuery,
    actionState,
    isActionPending,
    promptsToRender,
    isOpenAddPromptDialogWindow,
    openAddPromptDialogWindow,
    closeAddPromptDialogWindow,
    fetchPrompts,
    page,
    rowsPerPage,
    pagination,
    onChangePage,
    onChangeRowsPerPage,
  } = usePromptLibraryState();

  return (
    <section>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ borderRadius: 2, overflow: 'hidden' }}>
            <TableFiltersPanel
              searchInputProps={{
                value: searchQuery,
                placeholder: 'Search Prompt',
                onChange: handleSearchChange,
              }}
              actionButtonProps={{
                children: 'Create new prompt',
                onClick: openAddPromptDialogWindow,
              }}
              secondaryActionButtonProps={{
                children: 'Model List',
                onClick: () => {
                  navigate(ROUTES.modelList, {
                    state: {
                      breadCrumbName: 'Model List',
                    },
                  });
                },
              }}
            />

            <TableContainer sx={{ position: 'relative' }}>
              <Scrollbar>
                <Table size="medium" sx={{ minWidth: 1000 }}>
                  <colgroup>
                    <Box component="col" width="55%" />
                    <Box component="col" width="20%" />
                    <Box component="col" width="20%" />
                    <Box component="col" width="5%" />
                  </colgroup>
                  <CustomTableHeader headLabel={PROMPT_LIBRARY_TABLE_HEADERS} />

                  <TableBody>
                    {isLoading ? (
                      <TableSkeleton cellsAmount={4} />
                    ) : (
                      promptsToRender?.map(prompt => (
                        <PromptLibraryTableRow
                          key={prompt.id}
                          id={prompt.id}
                          text={prompt?.text}
                          name={prompt?.name}
                          updated_at={prompt?.updated_at}
                          onClickActionOptionHandler={onClickActionOptionHandler}
                        />
                      ))
                    )}
                    <TableNoData isNotFound={!isLoading && !promptsToRender.length} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <CustomTablePagination
              count={pagination?.total}
              page={page - 1}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <BaseDialogWindow
              onClickCancelButtonHandler={closeDeleteDialogWindow}
              open={isOpenDeleteDialogWindow}
              description={
                <Typography variant="body2">
                  Are you sure you want to delete{' '}
                  <Typography variant="body2" component="span" fontWeight={600}>
                    {actionState.name}
                  </Typography>{' '}
                  prompt? This action cannot be undone.
                </Typography>
              }
              title="Delete Prompt"
              isApproveButtonDisabled={isActionPending}
              isApproveButtonLoading={isActionPending}
              approveButtonTitle="Delete Prompt"
              onClickApproveButtonHandler={handleDeletePrompt}
            />

            <Dialog open={isOpenAddPromptDialogWindow} fullWidth onClose={closeAddPromptDialogWindow}>
              <AddPromptDialogWindowForm closeDialogHandler={closeAddPromptDialogWindow} fetchPrompts={fetchPrompts} />
            </Dialog>

            {/* TODO: after BE will be ready */}
            {/* <PopoverWrapper
              variant="filter"
              open={isFilterMenuOpen}
              title="Filter"
              sx={{ width: 1, maxWidth: 290, ml: 0, mt: 1, px: 2, py: 1 }}
              handleClosePopover={closeFilterMenu}
            >
              <FiltersMenuContent />
            </PopoverWrapper> */}
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};
