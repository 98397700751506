export const emailRegExp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#.?!@$%^&*-]).{8,}$/;

export const zipCodeRegExp = /^(?:\d{5}|\d{5}-\d{4})$/;

export const webSiteURLRegExp = /^((https?|ftp|smtp):\/\/)(www\.)?[a-z0-9-]+\.[a-zA-Z]+(\/[a-zA-Z0-9#]+\/?)*\/?$/;

export const PASSWORD_REG_EXP = {
  upperCase: /[A-Z]/,
  lowerCase: /[a-z]/,
  number: /[0-9]/,
  specialCharacter: /[!@#$%^&*]/,
};

export const whiteSpaceRegExp = /\%20?B?/g;

export const removeSpanRegExp = /<\s*\/?span\s*>/gi;

export const TAG_PATTERN_REGEX = /^([a-zA-Z_][a-zA-Z0-9_]*)\.([a-zA-Z_][a-zA-Z0-9_]*)$/;
