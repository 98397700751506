import { FC, memo, useCallback, useMemo, useState } from 'react';

import {
  Box,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  PaperProps,
  Stack,
  Typography,
  styled,
} from '@mui/material';

import { Icon } from 'components/Icon';
import { Scrollbar } from 'components/ScrollBar';

import { CollapseFieldItem } from './CallapseFieldItem';
import { EntityFieldMapType } from './entityFieldMap';

type FieldPopupProps = PaperProps & {
  entityFieldsMap: EntityFieldMapType;
  isFullWidth?: boolean;
  insertTagHandler: (tag: string) => void;
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  border: `${theme.borders.borderWidth[1]} solid ${theme.borders.borderColor}`,
  borderRadius: theme.borders.borderRadius.lg,
  overflow: 'hidden',
}));

export const TokensField: FC<FieldPopupProps> = memo(
  ({ isFullWidth, entityFieldsMap, insertTagHandler, ...paperProps }) => {
    const entityFieldEntries = useMemo(() => Object.entries(entityFieldsMap), [entityFieldsMap]);

    const [expandedCategories, setExpandedCategories] = useState<Set<string>>(new Set());

    const toggleCategory = useCallback((category: string) => {
      setExpandedCategories(prev => {
        const newExpanded = new Set(prev);
        if (newExpanded.has(category)) {
          newExpanded.delete(category);
        } else {
          newExpanded.add(category);
        }
        return newExpanded;
      });
    }, []);

    const onMouseDownHandler = (e: React.MouseEvent) => e.preventDefault();

    return (
      <Stack spacing={0.5} width={1} maxWidth={isFullWidth ? 1 : '40%'}>
        <Typography textTransform="capitalize" textAlign="left" variant="caption" fontWeight={400}>
          Tokens
        </Typography>

        <StyledPaper onMouseDown={onMouseDownHandler} {...paperProps}>
          <Scrollbar sx={{ width: 1, maxHeight: 255 }}>
            <List>
              {entityFieldEntries.map(([category, { model, fields }]) => {
                const isExpanded = expandedCategories.has(category);
                return (
                  <Box key={category} component="li">
                    <ListItemButton sx={{ px: 1.5 }} onClick={e => toggleCategory(category)}>
                      <ListItemText
                        primary={category}
                        primaryTypographyProps={{ fontSize: 14, fontWeight: 600, color: 'grey.600' }}
                      />
                      <Icon
                        type="arrowLeftIcon"
                        sx={{
                          transform: `rotate(${isExpanded ? '-' : ''}90deg)`,
                          transition: theme =>
                            theme.transitions.create('transform', {
                              duration: '0.3s',
                              easing: theme.transitions.easing.easeInOut,
                            }),
                        }}
                      />
                    </ListItemButton>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                      {fields.map(field => (
                        <CollapseFieldItem
                          key={field}
                          field={field}
                          model={model}
                          insertTagHandler={insertTagHandler}
                        />
                      ))}
                    </Collapse>
                    {isExpanded && <Divider />}
                  </Box>
                );
              })}
            </List>
          </Scrollbar>
        </StyledPaper>
      </Stack>
    );
  }
);
