import { ExpandMoreRounded } from '@mui/icons-material';
import { Autocomplete, Stack, Typography } from '@mui/material';

import { MDInput } from 'components/MDInput';

import { MDAutocompleteProps } from './types';

export const MDAutocomplete = <
  TValue extends {},
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  inputProps: { label, ...inputPropsOther },
  inputType,
  loading,
  ...rest
}: MDAutocompleteProps<TValue, Multiple, DisableClearable, FreeSolo>) => {
  return (
    <Stack width={1}>
      <Stack spacing={0.5} component="label">
        {label && (
          <Typography variant="caption" fontWeight={400}>
            {label}
          </Typography>
        )}
        <Autocomplete
          popupIcon={<ExpandMoreRounded />}
          renderInput={params => (
            <MDInput
              isLoading={loading}
              {...params}
              InputLabelProps={{ shrink: true }}
              fullWidth
              {...inputPropsOther}
            />
          )}
          loading={loading}
          {...rest}
        />
      </Stack>
    </Stack>
  );
};
