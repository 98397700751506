import { useEffect, useState } from 'react';

import { deleteFeedback, getFeedbacksByPromptId } from 'apiServices';
import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { ToastType, notice } from 'components/ToastNotification';
import { useActionDialogManagement, useRouter } from 'hooks';
import { backendErrorHandler } from 'utils/errorHanders';

export const useEvaluationHistoryTabContentState = () => {
  const { evaluationHistory, setEvaluationHistory } = useAppStore(
    store => ({
      evaluationHistory: store.evaluationHistory,
      setEvaluationHistory: store.setEvaluationHistory,
    }),
    shallow
  );
  const { params } = useRouter();

  const promptId = params?.id;

  const [isLoading, setIsLoading] = useState(true);

  const fetchEvaluationHistory = async () => {
    if (!promptId) return;

    !isLoading && setIsLoading(true);
    try {
      const { data } = await getFeedbacksByPromptId(promptId);

      setEvaluationHistory(data);
    } catch (error) {
      backendErrorHandler({
        error,
        config: { customErrorMessage: 'Failed to fetch prompts, please try again!' },
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEvaluationHistory();
  }, []);

  const {
    setIsActionPending,
    onClickActionOptionHandler,
    isOpenDeleteDialogWindow,
    closeDeleteDialogWindow,
    isActionPending,
    actionState,
  } = useActionDialogManagement();

  const handleDeleteEvaluationItem = async () => {
    setIsActionPending(true);
    try {
      await deleteFeedback(actionState?.id);

      notice(ToastType.SUCCESS, 'Prompt has been deleted sucessfully!');
      closeDeleteDialogWindow();

      await fetchEvaluationHistory();
    } catch (error) {
      backendErrorHandler({
        error,
        config: { customErrorMessage: 'Failed to delete prompt, please try again!' },
      });
    } finally {
      setIsActionPending(false);
    }
  };

  // TODO: will be implemented when BE will be ready
  // const { currentPage, ref, setCurrentPage } = useInfinityScroll({});

  return {
    evaluationHistory,
    isLoading,
    handleDeleteEvaluationItem,
    onClickActionOptionHandler,
    isOpenDeleteDialogWindow,
    closeDeleteDialogWindow,
    isActionPending,
  };
};
