import { useBoolean, usePopover } from 'hooks';
import { useTable } from 'hooks/useTable';

export const useConcernsState = () => {
  let total = 1;

  const {
    handleClosePopover: closeFiltersMenu,
    handleOpenPopover: openFilterMenu,
    openPopover: isFiltersMenuOpen,
  } = usePopover();

  const { page, rowsPerPage, onChangeRowsPerPage, setPage, isResettingPage, resetPageHandler } = useTable();

  const onChangePageHandler = async (e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const newPage = page + 1;
    setPage(newPage);
  };

  const [isOpenAddConcernDialogWindow, openAddConcernDialogWindow, closeAddConcernDialogWindow] = useBoolean();

  return {
    closeFiltersMenu,
    openFilterMenu,
    isFiltersMenuOpen,
    total,
    page,
    rowsPerPage,
    onChangeRowsPerPage,
    setPage,
    isResettingPage,
    resetPageHandler,
    onChangePageHandler,
    isOpenAddConcernDialogWindow,
    openAddConcernDialogWindow,
    closeAddConcernDialogWindow,
  };
};
