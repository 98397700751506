import { FC } from 'react';

import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import {
  create_pl_ba_album,
  create_pl_ba_album_pair,
  upload_image_pl_ba_album_pair,
  upload_pl_ba_album_cover,
} from 'apiServices';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { EmptyContent } from 'components/EmptyState';
import { PopoverWrapper } from 'components/MenuPopover';
import { TableFiltersPanel } from 'components/Table';
import {
  ActivateDeactivateDialogWindow,
  AddAlbumDialogWindow,
  AddBeforeAfterImagesDialogWindow,
  BACardItem,
  BACardItemSkeleton,
  FiltersMenuContent,
} from 'pages/BeforeAfter/components';

import { usePLBeforeAfterListState } from './usePLBeforeAfterList.state';

export const PracticeLibraryBeforeAfterList: FC = () => {
  const {
    b_a_pl_data,
    isLoading,
    isFetchMoreLoading,
    getMenuItems,
    searchValue,
    onChangeSearchInputValue,
    openFilterMenu,
    openAddAlbumDialogWindow,
    closeAddAlbumDialogWindow,
    isOpenAddAllbumDialogWindow,
    lastAlbumCardRef,
    onGetBeforeAfterAlbumsHandler,
    onCardClickHandler,
    isRoleAdmin,
    actionState,
    isActionPending,
    isActivateDialogOpen,
    isDeactivateDialogOpen,
    onActivateAlbumHandler,
    onDeactivateAlbumHandler,
    closeActivateDialog,
    closeDeactivateDialog,
    appliedFilters,
    isFiltersMenuOpen,
    onApplyFilters,
    closeFiltersMenu,
    closeAddAlbumImagesDialogWindow,
    fetchAlbumsHandler,
    isOpenAddAlbumImagesDialogWindow,
    albumId,
    onDeleteAlbumHandler,
    closeDeleteDialogWindow,
    isOpenDeleteDialogWindow,
    isViewOnly,
  } = usePLBeforeAfterListState();

  return (
    <Card>
      <TableFiltersPanel
        searchInputProps={{
          value: searchValue,
          placeholder: 'Type to search',
          onChange: onChangeSearchInputValue,
        }}
        onOpenFilterMenuHandler={openFilterMenu}
        actionButtonProps={
          !isViewOnly && {
            children: 'Add album',
            onClick: openAddAlbumDialogWindow,
          }
        }
      />
      <Divider />

      <Box p={2}>
        <Grid container spacing={2} position="relative" height={1}>
          {isLoading ? (
            <BACardItemSkeleton />
          ) : (
            b_a_pl_data.items.map((album, index) => (
              <Grid item key={album?.id} xs={12} sm={6} md={6} lg={3}>
                <BACardItem
                  isActive={album?.isActive}
                  title={album?.name}
                  pairsCount={album?.pairsCount}
                  description={album?.description}
                  coverThumbUrl={album?.coverThumbUrl}
                  onCardClickHandler={onCardClickHandler}
                  id={album?.id}
                  lastAlbumCardRef={b_a_pl_data.items.length - 1 === index ? lastAlbumCardRef : null}
                  getMenuItems={getMenuItems}
                />
              </Grid>
            ))
          )}
          {!isLoading && !b_a_pl_data.total && (
            <Grid item xs={12}>
              <EmptyContent />
            </Grid>
          )}

          {isFetchMoreLoading && <BACardItemSkeleton />}
        </Grid>
      </Box>

      {isOpenAddAlbumImagesDialogWindow && (
        <AddBeforeAfterImagesDialogWindow
          isOpenAddAllbumDialogWindow={isOpenAddAlbumImagesDialogWindow}
          closeAddAlbumDialogWindow={closeAddAlbumImagesDialogWindow}
          onFetchAlbumsHandler={fetchAlbumsHandler}
          uploadImageAPIrequest={upload_image_pl_ba_album_pair}
          createBAimagePairAPIrequest={create_pl_ba_album_pair}
          albumId={albumId}
        />
      )}

      <ActivateDeactivateDialogWindow
        title="Album"
        actionType={actionState.actionType}
        name={actionState.name}
        isOpen={actionState.actionType === 'activate' ? isActivateDialogOpen : isDeactivateDialogOpen}
        isActionPending={isActionPending}
        onClose={actionState.actionType === 'activate' ? closeActivateDialog : closeDeactivateDialog}
        onApprove={actionState.actionType === 'activate' ? onActivateAlbumHandler : onDeactivateAlbumHandler}
      />

      <AddAlbumDialogWindow
        isOpenAddAllbumDialogWindow={isOpenAddAllbumDialogWindow}
        closeAddAlbumDialogWindow={closeAddAlbumDialogWindow}
        uploadAlbumCoverAPIrequest={upload_pl_ba_album_cover}
        onFetchAlbumsHandler={onGetBeforeAfterAlbumsHandler}
        createAlbumAPIrequest={create_pl_ba_album}
        isRoleAdmin={isRoleAdmin}
      />

      <BaseDialogWindow
        onClickCancelButtonHandler={closeDeleteDialogWindow}
        open={isOpenDeleteDialogWindow}
        description={
          <Typography variant="body2">
            Are you sure you want to delete{' '}
            <Typography variant="body2" component="span" fontWeight={600}>
              {actionState.name}
            </Typography>{' '}
            album? This action cannot be undone.
          </Typography>
        }
        title="Delete Album"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Delete Album"
        onClickApproveButtonHandler={onDeleteAlbumHandler}
      />

      <PopoverWrapper variant="filter" open={isFiltersMenuOpen} title="Filter" handleClosePopover={closeFiltersMenu}>
        <FiltersMenuContent appliedFilters={appliedFilters} onApplyFilters={onApplyFilters} />
      </PopoverWrapper>
    </Card>
  );
};
