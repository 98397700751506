import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { useTabs } from 'hooks/useTabs';

export const useBeforeAfterState = () => {
  const { setBeforeAfterActiveLibraryTab, beforeAfterActiveLibraryTab } = useAppStore(
    store => ({
      beforeAfterActiveLibraryTab: store.beforeAfterActiveLibraryTab,
      setBeforeAfterActiveLibraryTab: store.setBeforeAfterActiveLibraryTab,
    }),
    shallow
  );

  const { setTabValue, tabValue } = useTabs(beforeAfterActiveLibraryTab === 'gl' ? 0 : 1);

  const handleSetTabValue = (_: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setTabValue(newValue);
    setBeforeAfterActiveLibraryTab(!newValue ? 'gl' : 'pl');
  };

  return { handleSetTabValue, tabValue };
};
