import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';

const tableHead = {
  styleOverrides: {
    root: {
      backgroundColor: colors.grey[100],
      borderTop: `${borders.borderWidth[1]} solid ${colors.light.main}`,
      borderBottom: `${borders.borderWidth[1]} solid ${colors.light.main}`,
      'tr:first-of-type': {
        cursor: 'inherit',
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
  },
};

export default tableHead;
