import { FC } from 'react';

import { Box, Card, Stack, Typography } from '@mui/material';

import colors from 'assets/theme/base/colors';
import { ClipboardLabel } from 'components/ClipboardLabel';
import { MDChip } from 'components/MDChip';
import { Note } from 'components/Widgets/Note';
import type { NoteProps } from 'components/Widgets/Note/types';

import { ActionMenu } from './ActionMenu';

interface Props {
  icon?: string;
  title: string;
  hardness?: number;
  id?: string;
  notes?: Pick<NoteProps, 'note' | 'noteAvatar' | 'noteDate' | 'noteAuthor'>[];
  mt?: number;
  context?: Parameters<typeof ActionMenu>[0];
  texts?: Record<string, string>;
}

export const Diagnosis: FC<Props> = props => {
  return (
    <Card
      sx={theme => ({
        padding: '10px',
        gap: '10px',
        boxShadow: 'none',
        mt: 1,
        '&:hover .patient-diagnosis-action-menu': {
          opacity: 1,
          pointerEvents: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
          '.patient-diagnosis-action-menu': {
            opacity: 1,
            pointerEvents: 'auto',
          },
        },
      })}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          justifyContent="space-between"
          width={1}
          gap={2}
        >
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            justifyContent={{ xs: 'center', sm: 'flex-start' }}
            gap={2}
          >
            {props.icon && <img src={props.icon} alt={props.icon} style={{ maxHeight: '20px' }} />}
            <Typography sx={{ fontSize: '14px' }}>{props.title}</Typography>
            {typeof props.hardness === 'number' ? (
              <MDChip
                size="small"
                variant="soft"
                color={props.hardness === 1 ? 'success' : props.hardness === 2 ? 'warning' : 'primary'}
                label={props.hardness === 1 ? 'Moderate' : props.hardness === 2 ? 'Severe' : 'Mild'}
              />
            ) : null}
            {props.id && <ClipboardLabel label={props.id} />}
          </Stack>
          {props.context && (
            <ActionMenu
              {...props.context}
              className="patient-diagnosis-action-menu"
              sx={{ opacity: 1, pointerEvents: 'none', transition: '.25s opacity' }}
            />
          )}
        </Stack>
      </Box>
      {props.texts && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
          {Object.entries(props.texts).map(([key, value]) => (
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }} key={key}>
              <Typography variant="caption" fontSize={14} color={colors.grey[600]}>
                {key}:
              </Typography>
              <Typography variant="caption" fontSize={14}>
                {value}
              </Typography>
            </Box>
          ))}
        </Box>
      )}

      {props.notes?.map(note => (
        <Note {...note} sx={{ minWidth: 'unset' }} key={note.noteDate} />
      ))}
    </Card>
  );
};
