import { ExpandMoreRounded } from '@mui/icons-material';
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteProps,
} from '@mui/material/Autocomplete';
import { DictionaryItem, MedicalCondition, PatientEnum } from 'apiServices';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { MDInput } from 'components/MDInput';
import { genericMemo, getElementById } from 'utils/helpers';

type RHFAutocompleteFieldProps<
  OptionType extends { id: string } | DictionaryItem | PatientEnum | MedicalCondition,
  TField extends FieldValues
> = {
  control: Control<TField>;
  name: Path<TField>;
  placeholder?: string;
  label?: string;
  onChangeHandler?: (
    option: OptionType | (string | OptionType)[] | NonNullable<string | OptionType>,
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<OptionType>
  ) => void | Promise<void>;
  isRequired?: boolean;
  groupBy?: AutocompleteProps<OptionType, boolean | undefined, boolean | undefined, boolean | undefined>['groupBy'];
  renderGroup?: AutocompleteProps<
    OptionType,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  >['renderGroup'];
} & Omit<AutocompleteProps<OptionType, boolean | undefined, boolean | undefined, boolean | undefined>, 'renderInput'>;

export const RHFAutocompleteField = genericMemo(
  <OptionType extends { id: string } | PatientEnum | DictionaryItem | MedicalCondition, TField extends FieldValues>(
    props: RHFAutocompleteFieldProps<OptionType, TField>
  ) => {
    const {
      placeholder,
      onChangeHandler,
      name,
      control,
      isRequired,
      freeSolo = false,
      getOptionLabel,
      isOptionEqualToValue,
      options = [],
      multiple = false,
      loading,
      disabled,
      label,
      sx,
      groupBy,
      renderGroup,
      ...others
    } = props;

    const emptyValue: [] | null = multiple ? [] : null;

    return (
      <Controller
        name={name}
        control={control}
        rules={{
          required: {
            value: isRequired || false,
            message: 'This field is required',
          },
        }}
        render={({ field, fieldState: { error } }) => {
          const { onChange, value, ref } = field;
          const currentValue = Array.isArray(value) ? value : getElementById(value, [...options]);

          const onChangeAutocompleteHandler = (
            _: React.SyntheticEvent<Element, Event>,
            newValue: (string | OptionType)[] | OptionType | NonNullable<string | OptionType> | null,
            reason: AutocompleteChangeReason,
            details: AutocompleteChangeDetails<OptionType>
          ) => {
            onChangeHandler?.(newValue, reason, details);
            if (Array.isArray(newValue) || typeof newValue === 'string') {
              onChange(newValue);
              return;
            }

            if (!newValue) {
              onChange(null);
              return;
            }

            if ('icd10Code' in newValue) {
              onChange(newValue.icd10Code);
            } else if ('id' in newValue) {
              onChange(newValue.id);
            } else if ('isoCode' in newValue) {
              onChange(newValue.isoCode);
            } else if ('value' in newValue) {
              onChange(newValue.value);
            }
          };

          return (
            <Autocomplete
              multiple={multiple}
              freeSolo={freeSolo}
              value={currentValue || emptyValue}
              getOptionLabel={getOptionLabel}
              isOptionEqualToValue={isOptionEqualToValue}
              onChange={onChangeAutocompleteHandler}
              disabled={disabled}
              loading={loading}
              options={options}
              popupIcon={<ExpandMoreRounded />}
              sx={{ width: 1, ...sx }}
              groupBy={groupBy}
              renderGroup={renderGroup}
              renderInput={params => (
                <MDInput
                  placeholder={placeholder}
                  label={label}
                  isLoading={loading}
                  required={isRequired}
                  error={!!error?.message}
                  helperText={error?.message}
                  inputRef={ref}
                  {...params}
                />
              )}
              {...others}
            />
          );
        }}
      />
    );
  }
);
