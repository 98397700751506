import { FC } from 'react';

import { RefreshRounded } from '@mui/icons-material';
import { Box, Card, Grid } from '@mui/material';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { EmptyStateWithAction } from 'components/EmptyState';
import { FormActions } from 'components/Form';
import { FormProvider } from 'components/HookForm';
import { getActivateMenuItem } from 'components/MenuPopover';
import { EntityHeader, EntityHeaderSkeleton } from 'components/Widgets';
import { ROUTES } from 'constants/routes';

import { FormContentSkeleton, UserForm } from './components';
import { useUserProfileState } from './useUserProfile.state';

export const UserProfile: FC = () => {
  const {
    user,
    formMethods,
    isSubmitting,
    isSubmitButtonDisabled,
    onFormSubmitHandler,
    isLoading,
    onGetUserHandler,
    isNoUser,
    isHideRoleField,
    isDirty,
    isActivateDialogOpen,
    isActionPending,
    isDeactivateDialogOpen,
    openActivateDialog,
    openDeactivateDialog,
    onActivateUserHandler,
    onDeactivateUserHandler,
    closeActivateDialog,
    closeDeactivateDialog,
    name,
    onUploadAvatarHandler,
    onDeleteAvatarHandler,
    avatarSrc,
    isCanEditAvatar,
  } = useUserProfileState();

  return (
    <Box
      component="section"
      sx={{
        display: 'flex',
        height: 1,
        ...(isNoUser && {
          flex: 1,
        }),
      }}
    >
      <Grid container spacing={2}>
        {isNoUser ? (
          <EmptyStateWithAction
            title="Failed to get user"
            subtitle="Please use the button below to try once again."
            actionButtonProps={{
              title: 'Get user',
              onClickHandler: onGetUserHandler,
              buttonProps: { startIcon: <RefreshRounded fontSize="medium" />, isLoading, disabled: isLoading },
            }}
          />
        ) : (
          <Grid item xs={12}>
            <Card
              sx={{
                ...(!isLoading && {
                  height: 1,
                }),
              }}
            >
              {isLoading ? (
                <>
                  <EntityHeaderSkeleton isActiveStatus isActionButton />
                  <FormContentSkeleton rows={7} />
                </>
              ) : (
                <>
                  <EntityHeader
                    title={name}
                    role={user?.role}
                    activeStatusProps={{
                      isActive: user?.isActive,
                    }}
                    avatarProps={{
                      src: avatarSrc,
                      isDisabled: !isCanEditAvatar,
                      initialPhotoUrl: user?.photoUrl,
                      onCreateFileHandler: onUploadAvatarHandler,
                      onDeleteFileHandler: onDeleteAvatarHandler,
                    }}
                    actionMenuItems={[
                      getActivateMenuItem(user?.isActive, user?.isActive ? openDeactivateDialog : openActivateDialog),
                    ]}
                  />
                  <FormProvider
                    formProps={{ sx: { position: 'relative', flex: 1 } }}
                    methods={formMethods}
                    onSubmit={onFormSubmitHandler}
                  >
                    <UserForm isRoleField={!isHideRoleField} />
                  </FormProvider>
                  <FormActions
                    isDisabled={isSubmitButtonDisabled}
                    isLoading={isSubmitting}
                    onFormSubmitHandler={onFormSubmitHandler}
                    isDirtyForm={isDirty}
                    backRoute={ROUTES.users}
                  />
                </>
              )}
            </Card>
          </Grid>
        )}
      </Grid>

      <BaseDialogWindow
        open={isDeactivateDialogOpen}
        onClickCancelButtonHandler={closeDeactivateDialog}
        description="Are you sure you want to inactivate this user?"
        title="Inactivate user"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Inactivate user"
        onClickApproveButtonHandler={onDeactivateUserHandler}
      />

      <BaseDialogWindow
        open={isActivateDialogOpen}
        onClickCancelButtonHandler={closeActivateDialog}
        description="Are you sure you want to activate this user?"
        title="Activate user"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Activate user"
        onClickApproveButtonHandler={onActivateUserHandler}
      />
    </Box>
  );
};
