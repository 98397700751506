import { FC } from 'react';

import { Box, Grid } from '@mui/material';

import { MDTabs } from 'components/MDTabs';

import { TABS } from './config';
import { GlobalLibraryTagList, PracticeLibraryTagList } from './pages';
import { useTagManagementState } from './useTagManagement.state';

function renderTabValueContent(tabValue: number) {
  switch (tabValue) {
    case 1:
      return <PracticeLibraryTagList />;

    default:
      return <GlobalLibraryTagList />;
  }
}

export const TagManagement: FC = () => {
  const { handleSetTabValue, tabValue } = useTagManagementState();

  return (
    <Box component="section">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ maxWidth: 400, width: 1 }}>
            <MDTabs styleVariant="folder" value={tabValue} tabsData={TABS} handleSetTabValue={handleSetTabValue} />
          </Box>
          {renderTabValueContent(tabValue)}
        </Grid>
      </Grid>
    </Box>
  );
};
