import { FC } from 'react';

import { Stack } from '@mui/material';

import { UploadAvatar } from 'components/UploadAvatar';

import { Note } from '../Note';
import { EntityHeaderActions, EntityHeaderDetails, EntityHeaderMainWrapper, EntityHeaderRoot } from './components';
import { EntityHeaderProps } from './types';

export const EntityHeader: FC<EntityHeaderProps> = ({
  noteProps,
  title,
  subtitle,
  tabs,
  id,
  avatarProps,
  activeStatusProps,
  role,
  publishStutusProps,
  actionMenuItems,
}) => {
  return (
    <EntityHeaderRoot tabs={tabs}>
      <EntityHeaderActions activeStatusProps={activeStatusProps} actionMenuItems={actionMenuItems} />
      <EntityHeaderMainWrapper>
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={2} alignItems={{ xs: 'center', sm: 'flex-start' }}>
          {avatarProps && <UploadAvatar avatarSize="3xl" {...avatarProps} />}
          <EntityHeaderDetails
            title={title}
            subtitle={subtitle}
            id={id}
            role={role}
            publishStutusProps={publishStutusProps}
          />
        </Stack>

        {noteProps && <Note {...noteProps} />}
      </EntityHeaderMainWrapper>
    </EntityHeaderRoot>
  );
};
