import { FC, SVGProps } from 'react';

import { Box, SxProps, Theme } from '@mui/material';

import { ReactComponent as add } from 'assets/icons/add_icon.svg';
import { ReactComponent as arrowLeftIcon } from 'assets/icons/arrow_left_icon.svg';
import { ReactComponent as beforeAfterFilled } from 'assets/icons/before_after_filled_icon.svg';
import { ReactComponent as beforeAfter } from 'assets/icons/before_after_icon.svg';
import { ReactComponent as bmiIcon } from 'assets/icons/bmi_icon.svg';
import { ReactComponent as boltIcon } from 'assets/icons/bolt_icon.svg';
import { ReactComponent as calendar } from 'assets/icons/calendar_icon.svg';
import { ReactComponent as catalog } from 'assets/icons/catalog_icon.svg';
import { ReactComponent as chatRoundedDotsIcon } from 'assets/icons/chat_round_dots_icon.svg';
import { ReactComponent as checkmarkIcon } from 'assets/icons/checkmark_icon.svg';
import { ReactComponent as chestSizeIcon } from 'assets/icons/chest_size_icon.svg';
import { ReactComponent as closeIcon } from 'assets/icons/close_icon.svg';
import { ReactComponent as closeSquareIcon } from 'assets/icons/close_square_icon.svg';
import { ReactComponent as checked } from 'assets/icons/components/checkbox/checked.svg';
import { ReactComponent as unChecked } from 'assets/icons/components/checkbox/unchecked.svg';
import { ReactComponent as filter } from 'assets/icons/components/filter.svg';
import { ReactComponent as home } from 'assets/icons/components/home.svg';
import { ReactComponent as page } from 'assets/icons/components/page.svg';
import { ReactComponent as patients } from 'assets/icons/components/patients.svg';
import { ReactComponent as practices } from 'assets/icons/components/practices.svg';
import { ReactComponent as radioChecked } from 'assets/icons/components/radio/checked.svg';
import { ReactComponent as radioUnChecked } from 'assets/icons/components/radio/unchecked.svg';
import { ReactComponent as tag_managment } from 'assets/icons/components/tag_managment.svg';
import { ReactComponent as toastError } from 'assets/icons/components/toast/toast_error.svg';
import { ReactComponent as toastSuccess } from 'assets/icons/components/toast/toast_success.svg';
import { ReactComponent as toastWarning } from 'assets/icons/components/toast/toast_warning.svg';
import { ReactComponent as users } from 'assets/icons/components/users.svg';
import { ReactComponent as copy } from 'assets/icons/copy_icon.svg';
import { ReactComponent as dropFileIcon } from 'assets/icons/drop_file_icon.svg';
import { ReactComponent as editPen } from 'assets/icons/edit_pen_icon.svg';
import { ReactComponent as email } from 'assets/icons/email_icon.svg';
import { ReactComponent as eyeClosed } from 'assets/icons/eye_closed_icon.svg';
import { ReactComponent as eyeOpened } from 'assets/icons/eye_open_icon.svg';
import { ReactComponent as flagIcon } from 'assets/icons/flag_icon.svg';
import { ReactComponent as forwardIcon } from 'assets/icons/forward_icon.svg';
import { ReactComponent as galleryIcon } from 'assets/icons/gallery_icon.svg';
import { ReactComponent as groupAmountIcon } from 'assets/icons/group_amount_icon.svg';
import { ReactComponent as groupIcon } from 'assets/icons/group_icon.svg';
import { ReactComponent as heightIcon } from 'assets/icons/height_icon.svg';
import { ReactComponent as hipSizeIcon } from 'assets/icons/hip_size.svg';
import { ReactComponent as leftArmSizeIcon } from 'assets/icons/left_arm_size.svg';
import { ReactComponent as leftThighSizeIcon } from 'assets/icons/left_thigh_size.svg';
import { ReactComponent as linkedBuilding } from 'assets/icons/linked/linked_building.svg';
import { ReactComponent as linkedContentBeforeAfter } from 'assets/icons/linked/linked_content_before_after.svg';
import { ReactComponent as linkedContentImage } from 'assets/icons/linked/linked_content_image.svg';
import { ReactComponent as linkedContentPage } from 'assets/icons/linked/linked_content_page.svg';
import { ReactComponent as linkedContentText } from 'assets/icons/linked/linked_content_text.svg';
import { ReactComponent as linkedContentVideo } from 'assets/icons/linked/linked_content_video.svg';
import { ReactComponent as linkedMedical } from 'assets/icons/linked/linked_medical.svg';
import { ReactComponent as lockIcon } from 'assets/icons/lock_icon.svg';
import { ReactComponent as moveIcon } from 'assets/icons/move_icon.svg';
import { ReactComponent as extremelySeverePain } from 'assets/icons/pain/extremely_severe_pain_icon.svg';
import { ReactComponent as impossiblePain } from 'assets/icons/pain/impossible_pain.svg';
import { ReactComponent as mildPain } from 'assets/icons/pain/mild_pain_icon.svg';
import { ReactComponent as mildToModeratePain } from 'assets/icons/pain/mild_to_moderate_pain_icon.svg';
import { ReactComponent as moderatePain } from 'assets/icons/pain/moderate_pain_icon.svg';
import { ReactComponent as moderateToSeverePain } from 'assets/icons/pain/moderate_to_severe_pain_icon.svg';
import { ReactComponent as noPain } from 'assets/icons/pain/no_pain_icon.svg';
import { ReactComponent as severePain } from 'assets/icons/pain/severe_pain_icon.svg';
import { ReactComponent as veryMildPain } from 'assets/icons/pain/very_mild_pain_icon.svg';
import { ReactComponent as verySeverePain } from 'assets/icons/pain/very_severe_pain_icon.svg';
import { ReactComponent as worstPain } from 'assets/icons/pain/worst_pain.svg';
import { ReactComponent as phoneIcon } from 'assets/icons/phone_icon.svg';
import { ReactComponent as refreshIcon } from 'assets/icons/refresh_icon.svg';
import { ReactComponent as rightArmSizeIcon } from 'assets/icons/right_arm_size.svg';
import { ReactComponent as rightThighSizeIcon } from 'assets/icons/right_thigh_size.svg';
import { ReactComponent as rocketIcon } from 'assets/icons/rocket_icon.svg';
import { ReactComponent as searchIcon } from 'assets/icons/search_icon.svg';
import { ReactComponent as settingsIcon } from 'assets/icons/settings_icon.svg';
import { ReactComponent as sidebarClose } from 'assets/icons/sidebar_close.svg';
import { ReactComponent as starsIcon } from 'assets/icons/stars_icon.svg';
import { ReactComponent as stethoscopeIcon } from 'assets/icons/stethoscope_icon.svg';
import { ReactComponent as trashBin } from 'assets/icons/trash_bin_icon.svg';
import { ReactComponent as uploadIcon } from 'assets/icons/upload_icon.svg';
import { ReactComponent as userIcon } from 'assets/icons/user_icon.svg';
import { ReactComponent as userSpeakIcon } from 'assets/icons/user_speak_icon.svg';
import { ReactComponent as waistIcon } from 'assets/icons/waist_icon.svg';
import { ReactComponent as website } from 'assets/icons/website_icon.svg';
import { ReactComponent as weightIcon } from 'assets/icons/weight_icon.svg';

const ICONS = {
  userIcon,
  userSpeakIcon,
  stethoscopeIcon,
  settingsIcon,
  flagIcon,
  lockIcon,
  rocketIcon,
  searchIcon,
  checkmarkIcon,
  boltIcon,
  chatRoundedDotsIcon,
  forwardIcon,
  arrowLeftIcon,
  phoneIcon,
  groupAmountIcon,
  closeSquareIcon,
  groupIcon,
  closeIcon,
  starsIcon,
  linkedContentText,
  moveIcon,
  refreshIcon,
  dropFileIcon,
  galleryIcon,
  uploadIcon,
  eyeOpened,
  editPen,
  add,
  trashBin,
  copy,
  eyeClosed,
  beforeAfterFilled,
  beforeAfter,
  email,
  website,
  filter,
  catalog,
  home,
  page,
  patients,
  practices,
  tag_managment,
  users,
  checked,
  unChecked,
  radioUnChecked,
  radioChecked,
  toastWarning,
  toastError,
  toastSuccess,
  linkedMedical,
  linkedContentVideo,
  linkedContentPage,
  linkedContentBeforeAfter,
  linkedContentImage,
  linkedBuilding,
  calendar,
  sidebarClose,
  noPain,
  veryMildPain,
  mildPain,
  mildToModeratePain,
  moderatePain,
  moderateToSeverePain,
  severePain,
  verySeverePain,
  extremelySeverePain,
  worstPain,
  impossiblePain,
  weightIcon,
  heightIcon,
  bmiIcon,
  chestSizeIcon,
  waistIcon,
  hipSizeIcon,
  rightArmSizeIcon,
  leftArmSizeIcon,
  rightThighSizeIcon,
  leftThighSizeIcon,
};

export type TIconType = keyof typeof ICONS;

interface IIconProps extends SVGProps<SVGSVGElement> {
  type: TIconType;
  sx?: SxProps<Theme>;
}

export const Icon: FC<IIconProps> = props => {
  const { type, sx, ...other } = props;
  const NewIcon = ICONS[type];

  return (
    <Box component="span" display="flex" sx={sx}>
      <NewIcon {...other} />
    </Box>
  );
};
