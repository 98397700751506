import { FC } from 'react';

import { ArrowBackIosNewRounded, CheckRounded } from '@mui/icons-material';
import { Divider, DialogActions as MUIdialogActions, Stack } from '@mui/material';

import MDButton from 'components/MDButton';

import { DialogActionsProps } from '../types';

export const DialogActions: FC<DialogActionsProps> = ({
  isDivider = true,
  approveButtonProps,
  cancelButtonProps,
  sx,
  ...other
}) => {
  return (
    <Stack>
      {isDivider && <Divider />}
      <MUIdialogActions sx={{ ...(!isDivider && { pt: 0 }), ...sx }} {...other}>
        <Stack
          width={1}
          direction={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <MDButton
            size="medium"
            startIcon={<ArrowBackIosNewRounded fontSize="medium" sx={{ fontSize: '16px !important' }} />}
            variant="outlined"
            sx={{ width: { xs: 1, sm: 'auto' }, ...cancelButtonProps?.sx }}
            {...cancelButtonProps}
          />

          <MDButton
            size="medium"
            startIcon={<CheckRounded fontSize="medium" />}
            sx={{ width: { xs: 1, sm: 'auto' }, ...approveButtonProps?.sx }}
            {...approveButtonProps}
          />
        </Stack>
      </MUIdialogActions>
    </Stack>
  );
};
