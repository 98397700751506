import React from 'react';

import { AutocompleteRenderOptionState, Box, Checkbox } from '@mui/material';
import { ExpertRoleEnum } from 'apiServices';

import { MDUserRoleChip } from 'components/MDChip';

export const UserRoleRenderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: ExpertRoleEnum,
  ownerState: AutocompleteRenderOptionState
) => (
  <Box component="li" {...props} gap={1}>
    <Checkbox checked={ownerState.selected} />
    <MDUserRoleChip size="small" userRole={option.value} />
  </Box>
);
