import { FC, useEffect } from 'react';

import { Box, Card, Dialog, Grid, Table, TableBody, TableContainer } from '@mui/material';

import { PopoverWrapper } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import {
  CustomTableHeader,
  CustomTablePagination,
  TableFiltersPanel,
  TableNoData,
  TableSkeleton,
} from 'components/Table';

import { AddPatientDialogWindowForm, FiltersMenuContent, PatientsTableRow } from './components';
import { PATIENTS_TABLE_HEAD } from './table.config';
import { usePatientsState } from './usePatients.state';

export const Patients: FC = () => {
  const {
    isLoading,
    onGetPatientsHandler,
    page,
    patients,
    total,
    rowsPerPage,
    onChangeRowsPerPage,
    isFiltersMenuOpen,
    openFilterMenu,
    closeFiltersMenu,
    onChangeSearchValueHandler,
    searchValue,
    debouncedSearchValue,
    appliedFilters,
    setAppliedFilters,
    onChangePageHandler,
    onSort,
    orderBy,
    order,
    isOpenAddPatientDialogWindow,
    openAddPatientDialogWindow,
    closeAddPatientDialogWindow,
    isRoleAdmin,
  } = usePatientsState();

  useEffect(() => {
    onGetPatientsHandler({});
  }, [debouncedSearchValue, rowsPerPage, order, orderBy]);

  return (
    <Box component="section" mb={2} sx={{ display: 'flex' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ borderRadius: 2, overflow: 'hidden' }}>
            <TableFiltersPanel
              searchInputProps={{
                value: searchValue,
                placeholder: 'Search by Name',
                onChange: onChangeSearchValueHandler,
              }}
              actionButtonProps={{ children: 'Add Patient', onClick: openAddPatientDialogWindow }}
              onOpenFilterMenuHandler={openFilterMenu}
            />

            <TableContainer sx={{ position: 'relative' }}>
              <Scrollbar>
                <Table size="medium" sx={{ minWidth: 800 }}>
                  <CustomTableHeader headLabel={PATIENTS_TABLE_HEAD} onSort={onSort} orderBy={orderBy} order={order} />

                  <TableBody>
                    {isLoading ? (
                      <TableSkeleton cellsAmount={6} />
                    ) : (
                      patients.map(patient => (
                        <PatientsTableRow
                          key={patient?.id}
                          birthDate={patient?.birthDate}
                          id={patient?.id}
                          firstName={patient?.person?.firstName}
                          lastName={patient?.person?.lastName}
                          middleName={patient?.person?.middleName}
                          ethnicGroup={patient?.ethnicGroup}
                          gender={patient?.gender}
                        />
                      ))
                    )}
                    <TableNoData isNotFound={!isLoading && !patients.length} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
            <CustomTablePagination
              count={total}
              page={page - 1}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePageHandler}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </Card>
        </Grid>
      </Grid>

      <PopoverWrapper
        open={isFiltersMenuOpen}
        title="Filter by Patients"
        handleClosePopover={closeFiltersMenu}
        variant="filter"
      >
        <FiltersMenuContent
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          onGetPatientsHandler={onGetPatientsHandler}
        />
      </PopoverWrapper>

      <Dialog
        open={isOpenAddPatientDialogWindow}
        onClose={closeAddPatientDialogWindow}
        PaperProps={{
          sx: { width: 1, maxWidth: 750 },
        }}
      >
        <AddPatientDialogWindowForm
          isPracticeField={isRoleAdmin}
          onCloseDialogHandler={closeAddPatientDialogWindow}
          onGetPatientHandler={onGetPatientsHandler}
        />
      </Dialog>
    </Box>
  );
};
