import { FC } from 'react';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Skeleton,
  Stack,
  Switch,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import QRCode from 'react-qr-code';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { OTPinput } from 'components/Form';
import { Loader } from 'components/Loader';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';

import { useMFAprovidersState } from './hooks/useMFAproviders.state';

export const MFAProviders: FC = () => {
  const {
    onClickDisableTOTPButtonHandler,
    isDisablingTOTP,
    onSwitchTOTPhandler,
    isDisableTOTPdialogOpen,
    onCloseDisableTOTPdialog,
    onVerifyTOTPcodeHandler,
    TOTP_QrCode_URL,
    isMFASettingsLoading,
    control,
    isValid,
    isSubmitting,
    isTOTPEnabled,
    onClickNextButtonHandler,
    isConfrimTOTPcodeDialogOpen,
    isGeneratingQRcode,
    onCloseConfrimTOTPcodeDialog,
    onCloseScanQRcodeDialog,
    isScanQRcodeDialogPopen,
  } = useMFAprovidersState();

  return (
    <Card
      sx={{
        ...(isMFASettingsLoading && {
          minHeight: 250,
        }),
        height: 1,
      }}
    >
      <CardHeader titleTypographyProps={{ component: 'h3' }} title="TOTP Authenticator App" />
      {isMFASettingsLoading ? (
        <Loader />
      ) : (
        <CardContent sx={{ height: 1 }}>
          <Stack spacing={1}>
            <MDTypography variant="body2" color="text">
              TOTP (Temporary One Time Password) is a Two-Factor Authentication (2FA) mechanism where the user scans a
              QR code using a mobile app like Google Authenticator
            </MDTypography>

            <Box sx={{ display: 'flex' }}>
              <FormControlLabel
                label="Enable TOTP MFA"
                checked={isTOTPEnabled}
                control={<Switch onChange={onSwitchTOTPhandler} />}
              />
            </Box>
          </Stack>
        </CardContent>
      )}

      <Dialog open={isScanQRcodeDialogPopen} onClose={onCloseScanQRcodeDialog}>
        <DialogTitle sx={{ textAlign: 'center', pb: 0, position: 'relative', zIndex: 10 }}>Scan QR code</DialogTitle>

        <DialogContent sx={{ mt: 1, px: 2.5, py: 1.5, position: 'relative' }}>
          <Stack width={1} alignItems="center" pt={0.5}>
            {isGeneratingQRcode ? (
              <Skeleton variant="rounded" sx={{ width: 256, height: 256 }} />
            ) : (
              <QRCode value={TOTP_QrCode_URL} />
            )}
          </Stack>
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'center', position: 'relative', zIndex: 10 }}>
          <Stack
            width={1}
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <MDButton onClick={onCloseScanQRcodeDialog} variant="outlined" color="error">
              Cancel
            </MDButton>
            <MDButton disabled={isGeneratingQRcode} onClick={onClickNextButtonHandler} autoFocus>
              Next
            </MDButton>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog open={isConfrimTOTPcodeDialogOpen} onClose={onCloseConfrimTOTPcodeDialog}>
        <DialogTitle sx={{ textAlign: 'center', pb: 0 }}>Enter the TOTP verification code</DialogTitle>

        <MDBox component="form" display="flex" flexDirection="column" onSubmit={onVerifyTOTPcodeHandler}>
          <DialogContent sx={{ mt: 1, px: 2.5, py: 1.5 }}>
            <Controller
              control={control}
              name="TOTPverificationCode"
              render={({ field }) => {
                return <OTPinput {...field} containerStyle={{ justifyContent: 'center' }} />;
              }}
            />
          </DialogContent>

          <DialogActions sx={{ justifyContent: 'center' }}>
            <Stack
              width={1}
              direction={{ xs: 'column', sm: 'row' }}
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <MDButton onClick={onCloseConfrimTOTPcodeDialog} variant="outlined">
                Cancel
              </MDButton>
              <MDButton type="submit" isLoading={isSubmitting} disabled={isSubmitting || !isValid} autoFocus>
                Verify
              </MDButton>
            </Stack>
          </DialogActions>
        </MDBox>
      </Dialog>

      <BaseDialogWindow
        onClickCancelButtonHandler={onCloseDisableTOTPdialog}
        open={isDisableTOTPdialogOpen}
        description="Are you sure you want to inactivate TOTP MFA?"
        title="Inactivating TOTP MFA"
        approveButtonTitle="Inactivate"
        onClickApproveButtonHandler={onClickDisableTOTPButtonHandler}
        isApproveButtonDisabled={isDisablingTOTP}
        isApproveButtonLoading={isDisablingTOTP}
      />
    </Card>
  );
};
