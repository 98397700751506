import { FC } from 'react';

import { Chip } from '@mui/material';

export type ActiveStatusChipProps = {
  isActive: boolean;
};

export const ActiveStatusChip: FC<ActiveStatusChipProps> = ({ isActive }) => (
  <Chip
    variant="soft"
    color={isActive ? 'primary' : 'default'}
    label={isActive ? 'Active' : 'Deactivated'}
    sx={{
      height: 26,
      '.MuiChip-label': {
        px: '10px',
      },
      alignSelf: { xs: 'center', sm: 'auto' },
    }}
  />
);
