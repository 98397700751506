import { CSSObject, IconButtonProps, alpha } from '@mui/material';

import colors from 'assets/theme/base/colors';

declare module '@mui/material/IconButton' {
  interface IconButtonOwnProps {
    variant?: 'square';
  }
}

const COLORS: Extract<TypeColor, 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error'>[] = [
  'primary',
  'secondary',
  'info',
  'success',
  'warning',
  'error',
];

const rootStyle = (ownerState: IconButtonProps) => {
  const squareVariant = ownerState.variant === 'square';

  const defaultStyles: CSSObject = {
    ...(squareVariant && {
      borderRadius: '6px',
      '& .MuiTouchRipple-child': { borderRadius: '6px' },
    }),
  };

  const colorStyle = COLORS.map(color => {
    const colorValue = colors[color]?.main || colors.primary.main;
    return {
      ...(ownerState.color === color && {
        ...(squareVariant && {
          backgroundColor: colorValue,
          color: colors.white.main,
          '&:hover': {
            backgroundColor: alpha(colorValue, 0.8),
          },
        }),
      }),
    };
  });

  const defaultColorStyles = ownerState.color === 'default' && {
    ...(squareVariant && {
      backgroundColor: colors.white.main,
      color: colors.dark.main,
      fontWeight: 500,
      '&:hover': {
        backgroundColor: colors.grey[100],
      },
    }),
  };

  return [...colorStyle, defaultColorStyles, defaultStyles];
};

const iconButton = {
  styleOverrides: {
    root: ({ ownerState }: { ownerState: IconButtonProps }) => rootStyle(ownerState),
  },
};

export default iconButton;
