import { FC, memo } from 'react';

import { Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import { FormDialogWindowContent } from 'components/BaseDialogWindow';
import { RHFAutocompleteField, RHFTextField } from 'components/HookForm';
import { ToastType, notice } from 'components/ToastNotification';

import { CreateConcernFormSchema, concernFormDefaultValues } from './form.config';
import { AestheticIssueOption, AnatomicalAreaOption, aestheticIssueOptions, anatomicalAreaOptions } from './options';

type AddConcernDialogWindowFormProps = {
  onCloseDialogHandler: VoidFunction;
};

export const AddConcernDialogWindowForm: FC<AddConcernDialogWindowFormProps> = memo(({ onCloseDialogHandler }) => {
  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitting, errors },
  } = useForm<CreateConcernFormSchema>({
    mode: 'onTouched',
    defaultValues: {
      ...concernFormDefaultValues,
    },
  });

  const onFormSubmitHandler = handleSubmit(async formData => {
    console.log('Form Data:', formData);
    notice(ToastType.INFO, 'Feature not implemented');
    onCloseDialogHandler();
  });

  const isSubmitButtonDisabled = !isValid || isSubmitting;

  return (
    <FormDialogWindowContent
      onFormSubmitHandler={onFormSubmitHandler}
      headerProps={{
        title: 'Add Concern',
        onClickCancelButtonHandler: onCloseDialogHandler,
      }}
      actionProps={{
        approveButtonProps: { disabled: isSubmitButtonDisabled, isLoading: isSubmitting, children: 'Submit' },
      }}
    >
      <Stack spacing={2} height={1}>
        <RHFAutocompleteField<AestheticIssueOption, CreateConcernFormSchema>
          control={control}
          name="aestheticIssue"
          isRequired
          label="Aesthetic Issue"
          placeholder="Select Aesthetic Issue"
          options={aestheticIssueOptions}
          groupBy={option => option.group}
          getOptionLabel={option => {
            if (typeof option === 'string') return option;
            return option.label;
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderGroup={params => (
            <li key={params.group}>
              <Typography variant="subtitle2" sx={{ color: theme => theme.palette.dark.main }}>
                <b>{params.group}</b>
              </Typography>
              <ul style={{ padding: 0 }}>{params.children}</ul>
            </li>
          )}
        />

        <RHFAutocompleteField<AnatomicalAreaOption, CreateConcernFormSchema>
          control={control}
          name="anatomicalIssue"
          isRequired
          label="Anatomical Area"
          placeholder="Select Anatomical Area"
          options={anatomicalAreaOptions}
          groupBy={option => option.group}
          getOptionLabel={option => {
            if (typeof option === 'string') return option;
            return option.label;
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderGroup={params => (
            <li key={params.group}>
              <Typography variant="subtitle2" sx={{ color: theme => theme.palette.dark.main }}>
                <b>{params.group}</b>
              </Typography>
              <ul style={{ padding: 0 }}>{params.children}</ul>
            </li>
          )}
        />

        <RHFTextField
          register={control.register}
          registerName="desiredOutcome"
          registerErrors={errors?.desiredOutcome?.message}
          fullWidth
          label="Desired Outcome"
          placeholder="Enter Desired Outcome"
        />
      </Stack>
    </FormDialogWindowContent>
  );
});
