import React, { FC, useEffect } from 'react';

import { Box, Stack, Typography, alpha } from '@mui/material';
import { Accept, DropzoneOptions, FileWithPath, useDropzone } from 'react-dropzone';

import { Icon } from 'components/Icon';
import MDButton, { MDButtonProps } from 'components/MDButton';
import { ToastType, notice } from 'components/ToastNotification';
import { ERROR_MESSAGES, UNSUPPORTED_FILE_TYPE_TITLE } from 'constants/strings';

type DropZoneProps = {
  uploadButtonProps?: MDButtonProps;
  onUploadFileHandler: (file: readonly FileWithPath[]) => void;
  buttonTitle: string;
  options?: DropzoneOptions;
  dropzoneChildren?: React.ReactNode;
  unsupportedFileFormatMessage?: string;
};

const getAllowedFormats = (acceptOptions: Accept): string => {
  if (!acceptOptions) return '';
  const fileFormats = Object.values(acceptOptions).flat();
  return fileFormats.map(format => format.replace('.', '').toUpperCase()).join(', ') + '.';
};

export const DropZone: FC<DropZoneProps> = ({
  buttonTitle,
  uploadButtonProps,
  onUploadFileHandler,
  options,
  dropzoneChildren,
  unsupportedFileFormatMessage = ERROR_MESSAGES.unsupportedFileFormat,
}) => {
  const { open, isDragActive, acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    noClick: true,
    multiple: false,
    ...options,
  });

  useEffect(() => {
    if (acceptedFiles.length) onUploadFileHandler(acceptedFiles);
  }, [acceptedFiles]);

  useEffect(() => {
    if (fileRejections?.length) {
      fileRejections.forEach(fileRejection => {
        fileRejection.errors.forEach(error => {
          if (error.code === 'file-invalid-type') {
            const allowedFormats = getAllowedFormats(options?.accept);

            const errorMessage = `${unsupportedFileFormatMessage} ${allowedFormats}`;

            notice(ToastType.ERROR, errorMessage, {
              title: UNSUPPORTED_FILE_TYPE_TITLE,
            });
          } else {
            notice(ToastType.ERROR, error.message);
          }
        });
      });
    }
  }, [fileRejections]);

  return (
    <Stack alignItems="center" width={1} height={1} justifyContent="center">
      <Box
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          p: 2.5,
          outlineWidth: '2px',
          borderRadius: ({ borders }) => borders.borderRadius.lg,
          display: 'flex',
          justifyContent: 'center',
          width: 1,
          height: 1,
          outlineStyle: 'dashed',
          outlineColor: ({ palette }) => palette.grey[300],
          ...(isDragActive && {
            outlineColor: ({ palette }) => palette.primary.main,
            backgroundColor: ({ palette }) => alpha(palette.primary.main, 0.1),
          }),
          color: 'text',
        }}
        {...getRootProps({ className: 'dropzone' })}
      >
        <input {...getInputProps()} />
        {dropzoneChildren && dropzoneChildren}
        <Stack alignItems="center" textAlign="center">
          <Icon type="dropFileIcon" />
          <Typography variant="body2" fontWeight={600} fontSize={14} mt={1.5}>
            Drop your image here to upload
          </Typography>
          <Typography fontSize={14} variant="body2" color="text.main">
            Works with any .JPG, .JPEG, .PNG files
          </Typography>
        </Stack>

        <Box mt={1.5} display="flex" justifyContent="center">
          <MDButton {...uploadButtonProps} onClick={open}>
            {buttonTitle}
          </MDButton>
        </Box>
      </Box>
    </Stack>
  );
};
