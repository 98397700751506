import { ComponentsOverrides, ComponentsProps, Theme, colors } from '@mui/material';

import { Icon } from 'components/Icon';

const tableSortLabel: {
  defaultProps?: ComponentsProps['MuiTableSortLabel'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTableSortLabel'];
} = {
  defaultProps: {
    IconComponent: props => (
      <Icon
        {...props}
        type="arrowLeftIcon"
        sx={{
          transform: 'rotate(-90deg)',
          svg: { color: `${colors.grey[800]} !important` },
        }}
      />
    ),
  },
  styleOverrides: {
    root: {
      color: 'inherit !important',
    },
  },
};

export default tableSortLabel;
