import { FC } from 'react';

import { Box, Card, Grid, Stack } from '@mui/material';

import { MDTabs } from 'components/MDTabs';
import { EntityHeaderActions, EntityHeaderRoot } from 'components/Widgets';

import { GeneralTabContent } from './GeneralTabContent';
import { RelatedVisitsTabContent } from './RelatedVisitsTabContent';
import { PATIENT_CONCERN_PROFILE_TAB_VALUES, usePatientConcernProfileState } from './usePatientConcernProfile.state';

const renderTabContent = (tabValue: number) => {
  switch (tabValue) {
    case 0:
      return <GeneralTabContent />;
    case 1:
      return <RelatedVisitsTabContent />;
    default:
      return null;
  }
};

export const PatientConcernProfile: FC = () => {
  const { handleSetTabValue, tabValue } = usePatientConcernProfileState();

  return (
    <Stack component="section">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ height: 1 }}>
            <EntityHeaderRoot
              tabs={
                <Box
                  display="flex"
                  justifyContent={{ xs: 'center', sm: 'flex-start' }}
                  maxWidth={{ xs: 1, sm: 400 }}
                  width={1}
                  mt={1}
                >
                  <MDTabs
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    value={tabValue}
                    tabsData={PATIENT_CONCERN_PROFILE_TAB_VALUES}
                    handleSetTabValue={handleSetTabValue}
                  />
                </Box>
              }
            >
              <EntityHeaderActions activeStatusProps={{ isActive: true }} />
            </EntityHeaderRoot>

            {renderTabContent(tabValue)}
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
};
