import { FC } from 'react';

import { Popover, PopoverOrigin } from '@mui/material';

import { StyledArrow } from './MenuPopover.styles';
import { getPosition } from './getPosition';
import { MenuPopoverProps } from './types';

export const MenuPopover: FC<MenuPopoverProps> = ({
  open,
  children,
  arrow = 'top-right',
  disabledArrow,
  sx,
  slotProps,
  paperSx,
  ...other
}) => {
  const { style, anchorOrigin, transformOrigin } = getPosition(arrow, disabledArrow);

  return (
    <Popover
      open={Boolean(open)}
      anchorEl={open}
      anchorOrigin={anchorOrigin as PopoverOrigin}
      transformOrigin={transformOrigin as PopoverOrigin}
      slotProps={{
        ...slotProps,
        paper: {
          ...slotProps?.paper,
          sx: {
            background: theme => theme.palette.common.white,
            border: theme => `${theme.borders.borderWidth[1]} solid ${theme.borders.borderColor}`,
            p: 0,
            overflow: 'hidden',
            ...style,
            ...paperSx,
          },
        },
      }}
      sx={sx}
      {...other}
    >
      {!disabledArrow && <StyledArrow arrow={arrow} />}
      {children}
    </Popover>
  );
};
