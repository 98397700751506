import { FC, memo } from 'react';

import { TableCell, TableRow } from '@mui/material';

import { MDChip } from 'components/MDChip';
import { getActivateMenuItem, getEditMenuItem } from 'components/MenuPopover';
import { PriorityIndicator } from 'components/PriorityIndicator';
import { ThreeDotsTableCell } from 'components/Table';
import { usePopover, useRouter } from 'hooks';

type ConcernsTableRowProps = {
  id: string;
};

export const ConcernsTableRow: FC<ConcernsTableRowProps> = memo(({ id }) => {
  const { navigate, params } = useRouter();

  const onClickRowHandler = () => {
    if (!params?.id) return;

    navigate(`/patients/${params.id}/${id}`);
  };

  const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

  const onCloseThreeDotsMenuHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleClosePopover();
  };

  const onClickThreeDotsMenuButtonHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    handleOpenPopover(event);
  };

  const onClickDeleteButtonHandler = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();

    handleClosePopover();
  };

  let isActive = true;

  return (
    <TableRow onClick={onClickRowHandler}>
      <TableCell>April 12, 2024</TableCell>

      <TableCell>Breast Augmentation</TableCell>

      <TableCell>Periorbital area; Eyebrows full; Upper eyelids</TableCell>

      <TableCell>
        <MDChip isRounded={true} size="small" variant="soft" color="warning" label="Severe" />
      </TableCell>

      <TableCell>
        <PriorityIndicator priorityType="primary" label="Primary" />
      </TableCell>

      <TableCell>Dr. Sarah Johnson</TableCell>

      <ThreeDotsTableCell
        open={openPopover}
        onClickButtonHandler={onClickThreeDotsMenuButtonHandler}
        onClickDeleteMenuItemHandler={onClickDeleteButtonHandler}
        onClose={onCloseThreeDotsMenuHandler}
        menuItems={[
          getEditMenuItem(onCloseThreeDotsMenuHandler),
          getActivateMenuItem(isActive, onCloseThreeDotsMenuHandler),
        ]}
      />
    </TableRow>
  );
});
