import { FC } from 'react';

import { ArrowBackIosRounded, MenuOpenRounded, MenuRounded } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { useRouter } from 'hooks/useRouter';

import { BREADCRUMBS_ROUTES } from './breadcrumbs.config';
import { MobileBreadCrumbsProps } from './types';

export const MobileBreadCrumbs: FC<MobileBreadCrumbsProps> = ({
  isMobileScreen,
  isOpenMobileNavBar,
  onOpenSideNav,
}) => {
  const { back } = useRouter();

  const breadcrumbs = useBreadcrumbs(BREADCRUMBS_ROUTES);

  return (
    <Stack width={1} direction="row" spacing={1} alignItems="center" justifyContent="space-between">
      <IconButton size="small" onClick={back}>
        <ArrowBackIosRounded fontSize="small" />
      </IconButton>
      <Typography variant="body2" fontWeight={400} textAlign="center" fontSize={14} noWrap>
        {breadcrumbs[breadcrumbs.length - 1]?.breadcrumb || ''}
      </Typography>
      <Box display={isMobileScreen ? 'block' : 'none'}>
        <IconButton onClick={onOpenSideNav}>{isOpenMobileNavBar ? <MenuOpenRounded /> : <MenuRounded />}</IconButton>
      </Box>
    </Stack>
  );
};
