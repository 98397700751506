import { CSSObject, Tabs, alpha, styled } from '@mui/material';

import rgba from 'assets/theme/functions/rgba';

export type StyledTabsWrapperOwnerState = {
  styleVariant?: 'rounded' | 'folder' | 'table';
};

export const StyledTabsWrapper = styled(Tabs, {
  shouldForwardProp: prop => prop !== 'styleVariant',
})<StyledTabsWrapperOwnerState>(({ theme, styleVariant, orientation }) => {
  const {
    palette,
    borders: { borderRadius, borderWidth },
    typography,
    boxShadows,
  } = theme;

  const isRounded = styleVariant === 'rounded';

  const isFolder = styleVariant === 'folder';

  const isTable = styleVariant === 'table';

  const roundedStyles = (): CSSObject => {
    return {
      position: 'relative',
      backgroundColor: palette.grey[100],
      borderRadius: borderRadius.xl,
      minHeight: 'unset',
      padding: 4,
      width: '100%',
      overflow: 'visible',
      '.MuiTabs-scroller, .MuiButtonBase-root': { overflow: 'visible !important' },

      '.MuiTabs-flexContainer': {
        height: '100%',
        position: 'relative',
        zIndex: 10,
      },

      '.MuiButtonBase-root': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        flex: '1 1 auto',
        textAlign: 'center',
        maxWidth: 'unset !important',
        minWidth: 'unset !important',
        minHeight: 'unset !important',
        fontSize: typography.size.sm,
        lineHeight: 'inherit',
        padding: 4,
        borderRadius: borderRadius.lg,
        color: `${palette.grey[500]} !important`,

        opacity: '1 !important',

        '& .material-icons, .material-icons-round': {
          marginBottom: '0 !important',
          marginRight: 6,
        },

        '& svg': {
          marginBottom: '0 !important',
          marginRight: 6,
        },
      },

      '.Mui-selected': {
        color: `${palette.grey[600]} !important`,
      },

      '.MuiTabs-indicator': {
        height: '100%',
        borderRadius: borderRadius.lg,
        backgroundColor: palette.white.main,
        boxShadow: boxShadows.tabsBoxShadow.indicator,
        transition: 'all 500ms ease',
        ...(orientation === 'vertical' && { width: '100%' }),
      },
    };
  };

  const folderStyles = (): CSSObject => {
    const border = `${borderWidth[1]} solid ${rgba(palette.black.main, 0.125)}`;
    const zIndex = 10;
    const height = 42;

    return {
      position: 'relative',
      marginBottom: -0.8,
      zIndex,
      minHeight: height,
      height,
      '.MuiButtonBase-root': {
        padding: '12px 14px',
        borderRadius: `${borderRadius.xl} ${borderRadius.xl} 0 0`,
        height,
        minHeight: 'unset',
        border: `${borderWidth[1]} solid transparent`,
        borderBottom: 0,
      },

      '.Mui-selected': {
        backgroundColor: palette.common.white,
        border,
        borderBottom: 0,
      },
      '.MuiTabs-indicator': {
        display: 'none',
      },
    };
  };

  const tableStyles = (): CSSObject => {
    return {
      padding: 1,
      '.MuiButtonBase-root': {
        padding: '10px 12px',
        borderRadius: borderRadius.md,
      },
      '.MuiTab-root': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      '.MuiBox-root': {
        marginRight: '5px',
      },

      '.Mui-selected': {
        backgroundColor: alpha(palette.primary.light, 0.1),
        color: palette.primary.main,
      },
      '.MuiTabs-indicator': {
        display: 'none',
      },
    };
  };

  return {
    ...(isRounded && roundedStyles()),
    ...(isFolder && folderStyles()),
    ...(isTable && tableStyles()),
  };
});
