import { FC } from 'react';

import { Chip, Stack } from '@mui/material';

import { Icon } from 'components/Icon';
import { PriorityIndicator } from 'components/PriorityIndicator';
import { dateToCustomFormat } from 'utils/helpers';

import { DetailItem } from '../components';

export const ConcernDetails: FC = () => {
  const formattedDate = dateToCustomFormat('12-20-2024');

  return (
    <Stack
      maxWidth={{ xs: 1, md: 420 }}
      width={1}
      p={{ xs: 3, lg: 4 }}
      spacing={1}
      sx={({ breakpoints, borders }) => ({
        borderLeft: `${borders.borderWidth[1]} solid ${borders.borderColor}`,
        borderBottom: 0,
        [breakpoints.down('md')]: {
          borderLeft: 0,
          borderBottom: `${borders.borderWidth[1]} solid ${borders.borderColor} `,
        },
      })}
    >
      <DetailItem icon={<Icon type="calendar" />} label="Date" value={formattedDate} />

      <DetailItem icon={<Icon type="calendar" />} label="Provider" value="Dr. Michael Lee" />

      <DetailItem
        icon={<Icon type="boltIcon" />}
        label="Severity"
        value={<Chip variant="soft" label="Severe" color="warning" />}
      />

      <DetailItem
        icon={<Icon type="flagIcon" />}
        label="Priority"
        value={<PriorityIndicator priorityType="primary" label="Primary" />}
      />
    </Stack>
  );
};
