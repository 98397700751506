import { FC } from 'react';

import { Stack, Typography } from '@mui/material';

import { ClipboardLabel } from 'components/ClipboardLabel';
import { MDUserRoleChip, PublishStatusChip } from 'components/MDChip';

import { EntityHeaderDetailsProps } from '../types';

export const EntityHeaderDetails: FC<EntityHeaderDetailsProps> = ({
  title,
  subtitle,
  id,
  role,
  publishStutusProps,
}) => {
  return (
    <Stack sx={{ position: 'relative', flexGrow: 1 }} justifyContent="space-between">
      <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={{ xs: 1, sm: 1.5 }}>
        {title && (
          <Typography textAlign={{ xs: 'center', sm: 'start' }} fontSize={{ xs: 18, md: 22 }} fontWeight={500}>
            {title}
          </Typography>
        )}
        {publishStutusProps && <PublishStatusChip {...publishStutusProps} />}
        {role && <MDUserRoleChip userRole={role} />}
        {id && <ClipboardLabel label={`ID ${id}`} />}
      </Stack>
      {subtitle && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={{ xs: 'center', sm: 'flex-start' }}
          spacing={1}
          mt={{ xs: 1, sm: 0 }}
        >
          {subtitle}
        </Stack>
      )}
    </Stack>
  );
};
