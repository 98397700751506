import { CreateGlobalLibraryTagCategoryPayload } from 'apiServices';
import * as yup from 'yup';

import { Shape } from 'types/RHF-types';
import { PracticeIdValidationSchema } from 'utils/formValidation';

export type CreateTagCategoryFormSchema = Pick<
  CreateGlobalLibraryTagCategoryPayload,
  'name' | 'serviceName' | 'practiceId' | 'isPublished'
>;

export const createTagCategoryValidationSchema = yup
  .object()
  .shape<Shape<CreateTagCategoryFormSchema>>({
    name: yup.string().required().label('Tag Category Name').trim(),
    serviceName: yup.string().optional().label('Tag Category Service Name').nullable().trim(),
    isPublished: yup.boolean().optional().label('Is Published Tag').nullable(),
    practiceId: PracticeIdValidationSchema,
  })
  .required();

export const createTagCategoryDefaultValues: CreateTagCategoryFormSchema = {
  name: '',
  serviceName: '',
  practiceId: '',
  isPublished: false,
};

export type CreateTagFormSchema = {
  name: string;
};

export const createTagValidationSchema = yup
  .object()
  .shape<Shape<CreateTagFormSchema>>({
    name: yup.string().required().label('Tag Name').trim(),
  })
  .required();

export const createTagDefaultValues: CreateTagFormSchema = {
  name: '',
};
