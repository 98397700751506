import { FC } from 'react';

import { Box, Divider } from '@mui/material';

import { EntityHeaderRootProps } from '../types';

export const EntityHeaderRoot: FC<EntityHeaderRootProps> = ({ children, tabs }) => (
  <>
    <Box sx={{ p: 2, ...(tabs && { pb: 0 }) }}>
      {children}

      {tabs}
    </Box>
    <Divider sx={{ mt: tabs ? '-1px' : 0 }} />
  </>
);
