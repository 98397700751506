import { FC, memo } from 'react';

import { Box, Divider, Stack, Typography } from '@mui/material';

import logo from 'assets/icons/aesthetics360_logo.svg';
import { Scrollbar } from 'components/ScrollBar';
import { ROUTES } from 'constants/routes';
import { useRouter } from 'hooks/useRouter';

import { NavAccount } from './NavAccount';
import { VerticalNavSection } from './VerticalNavSection';
import { createTransition } from './helpers';

type NavVerticalRenderContentProps = {
  isNavMini: boolean;
};

export const NavVerticalRenderContent: FC<NavVerticalRenderContentProps> = memo(({ isNavMini }) => {
  const { navigate } = useRouter();

  const navigateHomeHandler = () => navigate(ROUTES.home);

  return (
    <Stack
      sx={{
        height: 1,
        px: 1,
        pb: 2,
        position: 'relative',
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: '18.5px',
          pb: 2,
          px: 1,
          flexShrink: 0,
        }}
      >
        <Stack direction="row" justifyContent={isNavMini ? 'flex-start' : 'space-between'} alignItems="center">
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            onClick={navigateHomeHandler}
            sx={{ ':hover': { cursor: 'pointer' } }}
          >
            <Box component="img" src={logo} alt="company logo" width={20} height={20} />
            <Typography
              variant="button"
              fontSize={12}
              fontWeight={600}
              sx={{
                width: 1,
                transition: theme => createTransition(theme, 'all'),
                ...(isNavMini && { width: 0, overflow: 'hidden' }),
              }}
            >
              Aesthetics360
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Divider />

      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {
            height: 1,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <VerticalNavSection isNavMini={isNavMini} />
      </Scrollbar>

      <Divider />

      <NavAccount isNavMini={isNavMini} />
    </Stack>
  );
});
