import { FC } from 'react';

import { Divider, Stack, Typography } from '@mui/material';

type FormItemProps = {
  title?: string;
  children: React.ReactNode;
  subtitle?: string;
  isDivider?: boolean;
  isDisableSpace?: boolean;
  actionButton?: React.ReactNode;
  isRequired?: boolean;
};

export const FormItem: FC<FormItemProps> = ({
  actionButton,
  isDisableSpace,
  title,
  subtitle,
  children,
  isDivider = true,
  isRequired,
}) => {
  return (
    <Stack>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={{ xs: 1, md: 3 }}
        mr={{ xs: 0, sm: 2, md: 6, lg: 8 }}
        sx={{
          ...(isDisableSpace && { mr: 0 }),
        }}
      >
        <Stack direction={{ xs: 'column', sm: 'row' }} width={1} justifyContent="space-between">
          <Stack width={{ xs: 1, sm: '50%' }} textAlign={{ xs: actionButton ? 'center' : 'left', sm: 'left' }}>
            {title && (
              <Typography variant="body2" fontWeight={600} display="flex">
                {title}{' '}
                {isRequired && (
                  <Typography variant="caption" color="error.main" fontSize={14}>
                    *
                  </Typography>
                )}
              </Typography>
            )}

            <Typography variant="body2" sx={{ color: theme => theme.palette.grey[600] }}>
              {subtitle}
            </Typography>
          </Stack>
          <Stack
            sx={{
              maxWidth: { xs: 1, md: 450 },
              width: 1,
              alignItems: 'center',
              '& > div': {
                width: 1,
              },
            }}
            spacing={2}
          >
            {actionButton && (
              <Stack direction="row" mb={2} justifyContent={{ xs: 'center', sm: 'flex-end' }} width={1}>
                {actionButton}
              </Stack>
            )}
            {children}
          </Stack>
        </Stack>
      </Stack>
      {isDivider && <Divider sx={{ my: 2 }} />}
    </Stack>
  );
};
