import { FC } from 'react';

import { Box, Card, CardContent, Stack, Typography } from '@mui/material';

import { Icon, TIconType } from 'components/Icon';
import { Scrollbar } from 'components/ScrollBar';

type HistoryContentCardProps = {
  label: string;
  content: string;
  iconType: TIconType;
};

export const HistoryContentCard: FC<HistoryContentCardProps> = ({ content, label, iconType }) => {
  return (
    <Card
      key={label}
      sx={{
        maxWidth: { xs: 1, lg: 580 },
        width: 1,
        height: '140px',
        overflowY: 'hidden',
        boxShadow: 'none',
      }}
    >
      <CardContent
        sx={{
          p: 1.5,
          '&:last-child': {
            pb: 0,
          },
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stack direction="row" gap={0.5} alignItems="center">
          <Icon type={iconType} />
          <Typography variant="caption" fontWeight={400} lineHeight="unset">
            {label}
          </Typography>
        </Stack>

        <Scrollbar sx={{ maxHeight: 100, height: 1, display: 'flex' }}>
          <Box sx={{ height: 1, whiteSpace: 'pre-wrap', fontSize: 12, fontWeight: 400, color: 'text.secondary' }}>
            {content || 'No data'}
          </Box>
        </Scrollbar>
      </CardContent>
    </Card>
  );
};
