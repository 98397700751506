import { useCallback } from 'react';

import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { UserRoles } from 'types/enums';

export const useUserProfile = () => {
  const { setCognitoProfile, cognitoUser, practiceProfile, getProfileLegalDocsHandler, setUserProfile } = useAppStore(
    store => ({
      setUserProfile: store.setUserProfile,
      setCognitoProfile: store.setCognitoProfile,
      cognitoUser: store.cognitoUser,
      practiceProfile: store.practiceProfile,
      getProfileLegalDocsHandler: store.getProfileLegalDocsHandler,
    }),
    shallow
  );

  const memoizedGetProfileLegalDocsHandler = useCallback(getProfileLegalDocsHandler, []);

  const userRole = practiceProfile?.role || cognitoUser?.roles?.[0];

  const userRoles = {
    isRoleAdmin: userRole === UserRoles.ROLE_ADMIN,
    isRolePracticeAdmin: userRole === UserRoles.ROLE_PRACTICE_ADMIN,
    isRolePracticeUser: [
      UserRoles.ROLE_PRACTICE_OFFICE_ADMIN,
      UserRoles.ROLE_PRACTICE_DOCTOR,
      UserRoles.ROLE_PRACTICE_SECRETARY,
    ].includes(userRole),
  };

  const personId = practiceProfile?.person?.id;
  const practiceId = practiceProfile?.practice?.id;

  return {
    setCognitoProfile,
    cognitoUser,
    personId,
    userRoles,
    practiceProfile,
    userRole,
    getProfileLegalDocsHandler: memoizedGetProfileLegalDocsHandler,
    setUserProfile,
    practiceId,
  };
};
