import { FC, useState } from 'react';

import { Stack } from '@mui/material';

import MDButton from 'components/MDButton';
import { MDInput } from 'components/MDInput';

import { SubmitFeedbackHandlerProps } from '../useTestingTabContent.state';
import { RatingItem } from './RatingItem';

interface FeedbackContentProps {
  submitFeedbackHandler: (payload: SubmitFeedbackHandlerProps) => Promise<void>;
}

export type RatingKey = Extract<
  keyof SubmitFeedbackHandlerProps,
  'coherence_stars' | 'relevancy_stars' | 'accuracy_stars'
>;

type RatingRow = {
  key: RatingKey;
  label: string;
};

const ratingRows: RatingRow[] = [
  { key: 'accuracy_stars', label: 'Accuracy' },
  { key: 'relevancy_stars', label: 'Relevance' },
  { key: 'coherence_stars', label: 'Coherence' },
];

type RatingsInitialState = { [P in RatingKey]: number };

export const FeedbackContent: FC<FeedbackContentProps> = ({ submitFeedbackHandler }) => {
  const [comments, setComments] = useState('');
  const [expectedResponse, setExpectedResponse] = useState('');

  const onChangeCommentsHandler = (event: React.ChangeEvent<HTMLInputElement>) => setComments(event.target.value);

  const onChangeExpectedResponseHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
    setExpectedResponse(event.target.value);

  const [ratings, setRatings] = useState<RatingsInitialState>({
    accuracy_stars: 0,
    relevancy_stars: 0,
    coherence_stars: 0,
  });

  const handleRatingChange = (ratingName: RatingKey, newValue: number | null) => {
    setRatings(prevRatings => ({
      ...prevRatings,
      [ratingName]: newValue !== null ? newValue : prevRatings[ratingName],
    }));
  };

  const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);

  const onSubmitFeedbackHandler = async () => {
    setIsSubmittingFeedback(true);
    await submitFeedbackHandler({ ...ratings, comment: comments, preferred_output: expectedResponse });
    setIsSubmittingFeedback(false);
  };

  return (
    <Stack px={2.5} py={2} spacing={2} height={1}>
      <Stack spacing={2.5} flex={1}>
        <MDInput
          label="General Comments"
          placeholder="Enter Comments"
          multiline
          rows={4}
          value={comments}
          onChange={onChangeCommentsHandler}
        />

        <MDInput
          label="Expected Response"
          placeholder="Enter Response"
          multiline
          rows={4}
          value={expectedResponse}
          onChange={onChangeExpectedResponseHandler}
        />

        <Stack spacing={2}>
          {ratingRows.map(({ key, label }) => (
            <RatingItem
              key={key}
              ratingValue={ratings[key]}
              name={label}
              ratingKey={key}
              onChange={handleRatingChange}
            />
          ))}
        </Stack>
      </Stack>
      <MDButton
        onClick={onSubmitFeedbackHandler}
        disabled={isSubmittingFeedback}
        size="medium"
        isLoading={isSubmittingFeedback}
      >
        Submit Feedback
      </MDButton>
    </Stack>
  );
};
