import { FC } from 'react';

import { Stack } from '@mui/material';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { EmptyContent } from 'components/EmptyState';
import { Scrollbar } from 'components/ScrollBar';

import { EvaluationHistoryCard, EvaluationHistoryCardSkeleton } from './components';
import { useEvaluationHistoryTabContentState } from './useEvaluationHistoryTabContent.state';

export const EvaluationHistoryTabContent: FC = () => {
  const {
    evaluationHistory,
    isLoading,
    handleDeleteEvaluationItem,
    isActionPending,
    isOpenDeleteDialogWindow,
    closeDeleteDialogWindow,
    onClickActionOptionHandler,
  } = useEvaluationHistoryTabContentState();

  return (
    <>
      <Stack flex={1} sx={{ position: 'relative', overflowY: 'auto' }}>
        <Stack
          sx={{
            position: 'absolute',
            width: 1,
            height: 1,
            top: 0,
            left: 0,
            overflow: 'hidden',
            borderRadius: ({ borders }) => borders.borderRadius.lg,
          }}
        >
          {isLoading ? (
            <Scrollbar
              sx={{
                height: 1,
              }}
            >
              <EvaluationHistoryCardSkeleton />
              <EvaluationHistoryCardSkeleton />
              <EvaluationHistoryCardSkeleton isDividerVisible={false} />
            </Scrollbar>
          ) : evaluationHistory?.length ? (
            <Scrollbar
              sx={{
                height: 1,
              }}
            >
              {evaluationHistory?.map(history => (
                <EvaluationHistoryCard
                  key={history?.id}
                  {...history}
                  onClickActionOptionHandler={onClickActionOptionHandler}
                  lastCardRef={null}
                />
              ))}
            </Scrollbar>
          ) : (
            <EmptyContent justifyContent="center" />
          )}
        </Stack>
      </Stack>

      <BaseDialogWindow
        onClickCancelButtonHandler={closeDeleteDialogWindow}
        open={isOpenDeleteDialogWindow}
        description="Are you sure you want to delete prompt? This action cannot be undone."
        title="Delete Prompt"
        isApproveButtonDisabled={isActionPending}
        isApproveButtonLoading={isActionPending}
        approveButtonTitle="Delete Prompt"
        onClickApproveButtonHandler={handleDeleteEvaluationItem}
      />
    </>
  );
};
