import { FC } from 'react';

import { Chip, ChipProps } from '@mui/material';

import { UserRoleLabels } from 'constants/roles';
import { UserRoles } from 'types/enums';

export const UserRoleColors: Partial<Record<UserRoles, ChipProps['color']>> = {
  [UserRoles.ROLE_ADMIN]: 'secondary',
  [UserRoles.ROLE_PRACTICE]: 'default',
  [UserRoles.ROLE_AI_TESTER]: 'error',
  [UserRoles.ROLE_SERVICE]: 'warning',
  [UserRoles.ROLE_PRACTICE_ADMIN]: 'success',
  [UserRoles.ROLE_PRACTICE_OFFICE_ADMIN]: 'info',
  [UserRoles.ROLE_PRACTICE_DOCTOR]: 'primary',
  [UserRoles.ROLE_PRACTICE_SECRETARY]: 'error',
};

type MDUserRoleChipProps = ChipProps & {
  userRole: UserRoles;
};

export const MDUserRoleChip: FC<MDUserRoleChipProps> = ({ userRole, ...chipProps }) => (
  <Chip variant="soft" color={UserRoleColors[userRole] || 'primary'} label={UserRoleLabels[userRole]} {...chipProps} />
);
