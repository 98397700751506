import React, { FC, useEffect, useMemo, useState } from 'react';

import { Autocomplete, AutocompleteInputChangeReason, Box, FormControlLabel, Stack, Switch } from '@mui/material';
import { ExpertRoleEnum, OfficeListItem, PracticeListItem } from 'apiServices';

import { MDAutocomplete, UserRoleRenderOption } from 'components/MDAutocomplete';
import { MDInput } from 'components/MDInput';
import { FilterMenuPopoverButtons } from 'components/MenuPopover';
import { UserRoleLabels } from 'constants/roles';
import { useDebounce } from 'hooks/useDebounce';
import { useOptions } from 'hooks/useOptions';
import { usePracticeEnums } from 'hooks/usePracticeEnums';
import { useUserProfile } from 'hooks/useUserProfile';
import { getElementById, getFilterApplyButtonStatus, getFilterResetButtonStatus } from 'utils/helpers';

import { FiltersInitialState, FiltersMenuContentProps } from '../types';
import { FiltersInitialStateValue } from '../useUsers.state';

type FiltersValueType<K extends keyof FiltersInitialState> = FiltersInitialState[K];

export const FiltersMenuContent: FC<FiltersMenuContentProps> = ({ appliedFilters, onApplyFilters }) => {
  const { fetchOffices, offices, isOfficeLoading, fetchPractices, isPracticeLoading, practices } = useOptions({});

  const isRoleAdmin = useUserProfile().userRoles.isRoleAdmin;

  const [filters, setFilters] = useState(appliedFilters);

  const onClickResetFiltersButtonHandler = () => setFilters(FiltersInitialStateValue);

  const onClickApplyFiltersButtonHandler = async () => onApplyFilters(filters);

  const onChangeStateHandler = <K extends keyof FiltersInitialState>(key: K, value: FiltersValueType<K>) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  const onChangeSwitchHandler = (_: React.SyntheticEvent<Element, Event>, checked: boolean) =>
    onChangeStateHandler('isShowDeactivated', checked);

  const onChagePracticeValueHandler = async (_: React.SyntheticEvent<Element, Event>, value: PracticeListItem) =>
    onChangeStateHandler('practice_id', value?.id);

  const onChageOfficeValueHandler = async (_: React.SyntheticEvent<Element, Event>, value: OfficeListItem) =>
    onChangeStateHandler('office_id', value?.id);

  const onChageRolesValueHandler = async (_: React.SyntheticEvent<Element, Event>, roles: ExpertRoleEnum[]) =>
    onChangeStateHandler('roles', roles);

  const [practiceSearchValue, setPracticeSearchValue] = useState('');

  const debouncedPracticeSearchValue = useDebounce(practiceSearchValue);

  const onChangePracticeInputValueHandler = (
    _: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'input' || reason === 'clear') {
      setPracticeSearchValue(value || '');
    }
  };

  const [officeSearchValue, setOfficeSearchValue] = useState('');

  const debouncedOfficeSearchValue = useDebounce(officeSearchValue);

  const onChangeOfficeInputValueHandler = (
    _: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'input' || reason === 'clear') {
      setOfficeSearchValue(value || '');
    }
  };

  useEffect(() => {
    if (isRoleAdmin)
      fetchPractices({
        page: 1,
        size: 100,
        ...(debouncedPracticeSearchValue && { search: debouncedPracticeSearchValue }),
      });
  }, [debouncedPracticeSearchValue]);

  useEffect(() => {
    if (isRoleAdmin)
      fetchOffices({ page: 1, size: 100, ...(debouncedOfficeSearchValue && { search: debouncedOfficeSearchValue }) });
  }, [debouncedOfficeSearchValue]);

  const { practiceRolesEnum, fetchPracticeRolesEnum, isPracticeRolesEnumLoading } = usePracticeEnums();

  const userRoles = practiceRolesEnum.slice(3);

  useEffect(() => {
    fetchPracticeRolesEnum();
  }, []);

  const filterEntries = Object.entries(filters);

  const isApplyButtonDisabled = useMemo(
    () => getFilterApplyButtonStatus({ appliedFilters, filterEntries }),
    [filters, appliedFilters]
  );

  const isResetButtonDisabled = useMemo(
    () => getFilterResetButtonStatus({ filterEntries, defaultFilters: FiltersInitialStateValue }),
    [filters]
  );

  const renderAutocomplete = <TOption,>({
    getOptionLabel,
    isLoading,
    isOptionEqualToValue,
    label,
    onChange,
    onInputChange,
    options,
    placeholder,
    value,
    isDisabled,
  }: {
    value: TOption;
    onChange: (event: React.SyntheticEvent<Element, Event>, value: TOption) => void;
    options: TOption[];
    label: string;
    placeholder: string;
    isLoading: boolean;
    isOptionEqualToValue: (option: TOption, value: TOption) => boolean;
    getOptionLabel: (option: TOption) => string;
    onInputChange?: (
      _: React.SyntheticEvent<Element, Event>,
      value: string,
      reason: AutocompleteInputChangeReason
    ) => Promise<void> | void;
    isDisabled?: boolean;
  }) => (
    <Autocomplete
      value={value}
      onChange={onChange}
      onInputChange={onInputChange}
      getOptionLabel={getOptionLabel}
      options={options}
      freeSolo={false}
      isOptionEqualToValue={isOptionEqualToValue}
      disableClearable={false}
      disabled={isDisabled}
      renderInput={params => (
        <MDInput
          isLoading={isLoading}
          {...params}
          label={label}
          placeholder={placeholder}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      )}
    />
  );

  const practiceSelectedValue = useMemo(
    () => getElementById(filters?.practice_id, practices),
    [filters?.practice_id, practices]
  );

  const officeSelectedValue = useMemo(() => getElementById(filters?.office_id, offices), [filters?.office_id, offices]);

  return (
    <Stack position="relative">
      <Stack height={1} spacing={2} py={1}>
        {isRoleAdmin &&
          renderAutocomplete({
            options: practices,
            isOptionEqualToValue(option, value) {
              return option?.id === value?.id;
            },
            getOptionLabel(option) {
              return option?.name;
            },
            isLoading: isPracticeLoading,
            label: 'Practice',
            placeholder: 'Select Practice',
            onInputChange: onChangePracticeInputValueHandler,
            onChange: onChagePracticeValueHandler,
            value: practiceSelectedValue,
          })}

        {isRoleAdmin &&
          renderAutocomplete({
            options: offices,
            isOptionEqualToValue(option, value) {
              return option?.id === value?.id;
            },
            getOptionLabel(option) {
              return option?.label;
            },
            isLoading: isOfficeLoading,
            label: 'Office',
            placeholder: 'Select Office',
            onInputChange: onChangeOfficeInputValueHandler,
            onChange: onChageOfficeValueHandler,
            value: officeSelectedValue,
          })}

        <MDAutocomplete
          inputProps={{
            label: 'Role',
            placeholder: 'Select Role',
          }}
          freeSolo={false}
          multiple
          options={userRoles}
          getOptionLabel={option => UserRoleLabels[option?.value] || ''}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          loading={isPracticeRolesEnumLoading}
          onChange={onChageRolesValueHandler}
          value={isPracticeRolesEnumLoading ? [] : filters.roles}
          renderOption={UserRoleRenderOption}
        />

        <Box>
          <FormControlLabel
            onChange={onChangeSwitchHandler}
            checked={filters.isShowDeactivated}
            control={<Switch />}
            label="Show deactivated"
            labelPlacement="start"
          />
        </Box>
      </Stack>
      <FilterMenuPopoverButtons
        isClearButtonDisabled={isResetButtonDisabled}
        onClickClearButtonHandler={onClickResetFiltersButtonHandler}
        isApplyButtonDisabled={isApplyButtonDisabled}
        onClickApplyButtonHandler={onClickApplyFiltersButtonHandler}
      />
    </Stack>
  );
};
