import { FC } from 'react';

import { Box, Stack, Typography, alpha } from '@mui/material';

import { FormHelperText } from 'components/Form';

import { EditorContainer, EditorInput, InputBorder } from './TagEditorField.style';

export type TagEditorFieldProps = {
  placeholder?: string;
  label?: string;
  isRequired?: boolean;
  errorMessage?: string;
  onClickLabelHandler: () => void;
  refHandler: (node: HTMLDivElement) => void;
  handlePaste: (event: React.ClipboardEvent<HTMLDivElement>) => void;
  onInputChangeHandler: (event: React.FormEvent<HTMLDivElement>) => void;
  onInputBlurHandler: (event: React.FocusEvent<HTMLDivElement>) => void;
  storeCursorPosition: () => void;
};

export const TagEditorField: FC<TagEditorFieldProps> = ({
  isRequired,
  label,
  placeholder,
  errorMessage = '',
  onClickLabelHandler,
  refHandler,
  handlePaste,
  onInputChangeHandler,
  onInputBlurHandler,
  storeCursorPosition,
}) => {
  return (
    <EditorContainer>
      <Stack height={1} spacing={0.5}>
        {label && (
          <Typography
            textTransform="capitalize"
            textAlign="left"
            component="label"
            variant="caption"
            fontWeight={400}
            color="text.main"
            onClick={onClickLabelHandler}
          >
            {label}
            {isRequired && (
              <Typography variant="caption" color="error.main" fontSize={13}>
                *
              </Typography>
            )}
          </Typography>
        )}

        <Stack width={1} height={1}>
          <Box
            position="relative"
            width={1}
            height={1}
            display="inline-flex"
            sx={{
              backgroundColor: 'transparent',
              ...(errorMessage && {
                backgroundColor: ({ palette }) => alpha(palette.error.main, 0.1),
              }),
              borderRadius: ({ borders }) => borders.borderRadius.lg,
            }}
          >
            <EditorInput
              ref={refHandler}
              contentEditable
              role="textbox"
              suppressContentEditableWarning
              onBlur={onInputBlurHandler}
              onInput={onInputChangeHandler}
              onPaste={handlePaste}
              onClick={storeCursorPosition}
              placeholder={placeholder}
              isError={!!errorMessage}
            />

            <InputBorder className="input-border" isError={!!errorMessage} />
          </Box>

          {errorMessage && <FormHelperText error={!!errorMessage} text={errorMessage} />}
        </Stack>
      </Stack>
    </EditorContainer>
  );
};
