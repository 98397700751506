import { StateCreator } from 'zustand';

import { sliceResetFns } from '../../Store';
import { TagManagementInitialState, TagManagementSliceType } from './types';

const initialState: TagManagementInitialState = {
  globalLibraryTagCategories: {
    page: null,
    data: [],
    total: null,
    size: null,
    pages: null,
  },
  practiceLibraryTagCategories: {
    page: null,
    data: [],
    total: null,
    size: null,
    pages: null,
  },
  globalLibraryTagCategoryData: {
    category: null,
    tags: {
      page: null,
      data: [],
      total: null,
      size: null,
      pages: null,
    },
  },
  practiceLibraryTagCategoryData: {
    category: null,
    tags: {
      page: null,
      data: [],
      total: null,
      size: null,
      pages: null,
    },
  },
  breadcrumbData: {
    categoryId: '',
    name: '',
  },
  tagManagementActiveLibraryTab: 'gl',
};

export const TagManagementSlice: StateCreator<
  TagManagementSliceType,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  TagManagementSliceType
> = (set, get) => {
  sliceResetFns.add(() => set(initialState));
  return {
    ...initialState,
    setBreadcrumbData: (id, name) => {
      set(state => {
        if (state.breadcrumbData.categoryId !== id) {
          state.breadcrumbData.categoryId = id;
          state.breadcrumbData.name = name;
        }
      });
    },
    setGlobalLibraryTagCategories: data => {
      set(state => {
        state.globalLibraryTagCategories = data;
      });
    },
    setPracticeLibraryTagCategories: data => {
      set(state => {
        state.practiceLibraryTagCategories = data;
      });
    },
    setGlobalLibraryTagCategory: item => {
      set(state => {
        state.globalLibraryTagCategoryData.category = item;
      });

      get().setBreadcrumbData(item?.id, `Global Library - ${item?.name}`);
    },

    setGlobalLibraryTags: data => {
      set(state => {
        state.globalLibraryTagCategoryData.tags = data;
      });
    },

    setPracticeLibraryTagCategory: item => {
      set(state => {
        state.practiceLibraryTagCategoryData.category = item;
      });

      get().setBreadcrumbData(item?.id, `Practice Library - ${item?.name}`);
    },

    setPracticeLibraryTags: data => {
      set(state => {
        state.practiceLibraryTagCategoryData.tags = data;
      });
    },
    setTagManagementActiveLibraryTab: tab => {
      set(state => {
        state.tagManagementActiveLibraryTab = tab;
      });
    },
  };
};
