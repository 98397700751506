export type AestheticIssueOption = {
  id: string;
  label: string;
  group: string;
};

export type AnatomicalAreaOption = {
  id: string;
  label: string;
  group: string;
};

export const aestheticIssueOptions: AestheticIssueOption[] = [
  // Lines and Wrinkles
  { id: 'wrinkles_general', label: 'General Wrinkles', group: 'Lines and Wrinkles' },
  { id: 'fine_lines', label: 'Fine Lines', group: 'Lines and Wrinkles' },
  { id: 'dynamic_wrinkles', label: 'Dynamic Wrinkles', group: 'Lines and Wrinkles' },
  { id: 'static_wrinkles', label: 'Static Wrinkles', group: 'Lines and Wrinkles' },
  { id: 'deep_wrinkles', label: 'Deep Wrinkles', group: 'Lines and Wrinkles' },
  { id: 'crease_lines', label: 'Crease Lines', group: 'Lines and Wrinkles' },
  { id: 'expression_lines', label: 'Expression Lines', group: 'Lines and Wrinkles' },
  { id: 'sleep_lines', label: 'Sleep Lines', group: 'Lines and Wrinkles' },
  { id: 'crows_feet', label: "Crow's Feet", group: 'Lines and Wrinkles' },

  // Skin Surface
  { id: 'skin_texture_general', label: 'General Skin Texture', group: 'Skin Surface' },
  { id: 'rough_texture', label: 'Rough Texture', group: 'Skin Surface' },
  { id: 'uneven_texture', label: 'Uneven Texture', group: 'Skin Surface' },
  { id: 'enlarged_pores', label: 'Enlarged Pores', group: 'Skin Surface' },
  { id: 'congested_pores', label: 'Congested Pores', group: 'Skin Surface' },
  { id: 'milia', label: 'Milia', group: 'Skin Surface' },
  { id: 'keratosis', label: 'Keratosis', group: 'Skin Surface' },
];

export const anatomicalAreaOptions: AnatomicalAreaOption[] = [
  // Face - General
  { id: 'face_full', label: 'Full Face', group: 'Face - General' },
  { id: 'face_upper_third', label: 'Upper Third', group: 'Face - General' },
  { id: 'face_middle_third', label: 'Middle Third', group: 'Face - General' },
  { id: 'face_lower_third', label: 'Lower Third', group: 'Face - General' },

  // Upper Face Detail
  { id: 'forehead_full', label: 'Full Forehead', group: 'Upper Face Detail' },
  { id: 'forehead_central', label: 'Central Forehead', group: 'Upper Face Detail' },
  { id: 'forehead_lateral', label: 'Lateral Forehead', group: 'Upper Face Detail' },
  { id: 'temples', label: 'Temples', group: 'Upper Face Detail' },
  { id: 'glabella', label: 'Glabella', group: 'Upper Face Detail' },

  // Periorbital Area
  { id: 'periorbital_area', label: 'Periorbital Area', group: 'Periorbital Area' },
  { id: 'eyebrows_full', label: 'Full Eyebrows', group: 'Periorbital Area' },
  { id: 'eyebrow_lateral', label: 'Lateral Eyebrows', group: 'Periorbital Area' },
  { id: 'eyebrow_medial', label: 'Medial Eyebrows', group: 'Periorbital Area' },
  { id: 'upper_eyelids', label: 'Upper Eyelids', group: 'Periorbital Area' },
  { id: 'lower_eyelids', label: 'Lower Eyelids', group: 'Periorbital Area' },
  { id: 'tear_troughs', label: 'Tear Troughs', group: 'Periorbital Area' },
  { id: 'lateral_canthal_lines', label: 'Lateral Canthal Lines', group: 'Periorbital Area' },
  { id: 'infraorbital_hollow', label: 'Infraorbital Hollow', group: 'Periorbital Area' },
];
