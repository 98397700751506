import { apiClient } from 'lib/api';

import { BASE_PROMPT_FEEDBACKS_API_URL } from '../api.routes';
import { CreateFeedbackPayload, FeedbackCollectionResponse, FeedbackItemResponse, FeedbackPatchRequest } from './types';

const BASE_URL = {
  baseURL: process.env.REACT_APP_BASE_PROMPT_API_URL,
};

export const getFeedbackById = async (feedbackId: string) =>
  await apiClient.get<FeedbackItemResponse>(`${BASE_PROMPT_FEEDBACKS_API_URL}/${feedbackId}`, BASE_URL);

export const createFeedback = async (payload: CreateFeedbackPayload) =>
  await apiClient.post<FeedbackItemResponse>(`/prompt-feedback`, payload, BASE_URL);

export const updateFeedback = async (feedbackId: string, payload: FeedbackPatchRequest) =>
  await apiClient.patch<FeedbackItemResponse>(`${BASE_PROMPT_FEEDBACKS_API_URL}/${feedbackId}`, payload, BASE_URL);

export const deleteFeedback = async (feedbackId: string) =>
  await apiClient.delete(`${BASE_PROMPT_FEEDBACKS_API_URL}/${feedbackId}`, BASE_URL);

export const getFeedbacksByPromptId = async (promptId: string) =>
  await apiClient.get<FeedbackCollectionResponse[]>(`${BASE_PROMPT_FEEDBACKS_API_URL}/${promptId}`, BASE_URL);
