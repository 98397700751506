import { FC, memo } from 'react';

import { InfoOutlined } from '@mui/icons-material';
import {
  FormHelperText as MUIFormHelperText,
  FormHelperTextProps as MUIFormHelperTextProps,
  Typography,
} from '@mui/material';

type FormHelperTextProps = MUIFormHelperTextProps & {
  text: React.ReactNode;
};

export const FormHelperText: FC<FormHelperTextProps> = memo(({ text, ...muiFormHelperTextProps }) => (
  <MUIFormHelperText
    component="p"
    sx={{ mt: '5px', alignItems: 'center', display: 'flex' }}
    {...muiFormHelperTextProps}
  >
    <InfoOutlined sx={{ mr: 1, width: 18, height: 18 }} />
    <Typography
      variant="button"
      sx={{
        mt: '1px',
        whiteSpace: 'initial',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: 'unset',
      }}
    >
      {text}
    </Typography>
  </MUIFormHelperText>
));
