import { AxiosError } from 'axios';

import { ToastType, notice } from 'components/ToastNotification';
import { ERROR_MESSAGES } from 'constants/strings';

import { APIerror, ErrorLocation } from './types';

type BaseBackendErrorHandlerConfig = {
  customErrorHandler?: (error: unknown) => void;
  customErrorMessage?: string;
};

const ERROR_LOCATION: ErrorLocation[] = ['body', 'query', 'path'];

export const backendErrorHandler = ({ error, config }: { error: unknown; config?: BaseBackendErrorHandlerConfig }) => {
  const { customErrorHandler, customErrorMessage } = config || {};

  console.error(error);

  if (customErrorHandler) return customErrorHandler(error);

  if (!(error instanceof AxiosError)) return notice(ToastType.ERROR, ERROR_MESSAGES.smt_went_wrong);

  const errorResponse = error?.response?.data as APIerror;

  if (!errorResponse || !('detail' in errorResponse))
    return notice(ToastType.ERROR, customErrorMessage || ERROR_MESSAGES.smt_went_wrong);

  if (error?.response?.status === 422 && Array.isArray(errorResponse.detail)) {
    return errorResponse.detail.forEach(err => {
      const errorLocation = err?.loc?.[0];
      const isValidLocation = ERROR_LOCATION.includes(errorLocation);

      if (isValidLocation) {
        return notice(ToastType.ERROR, err.msg || '');
      }
    });
  }

  if (error?.response?.status === 401) {
    return;
  }

  if (typeof errorResponse.detail === 'string') {
    return notice(ToastType.ERROR, errorResponse.detail);
  } else return notice(ToastType.ERROR, customErrorMessage || ERROR_MESSAGES.smt_went_wrong);
};
