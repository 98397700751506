import { FC, memo } from 'react';

import { Box, Chip, IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { GlobalLibraryTag, PracticeLibraryTag } from 'apiServices';

import { Icon } from 'components/Icon';
import { PublishStatusChip } from 'components/MDChip';
import { HoverMenuPopover, getActivateMenuItem, getEditMenuItem } from 'components/MenuPopover';
import { ThreeDotsTableCell } from 'components/Table';
import { Tag } from 'components/Tag';
import { ActionDataInitialState } from 'hooks/useActionDialogManagement';
import { usePopover } from 'hooks/usePopover';
import { useRouter } from 'hooks/useRouter';

type TagListTableRowProps = {
  isPracticeUser?: boolean;
  onClickActivationOptionHandler: (activationStateData: ActionDataInitialState) => void;
  tagCategoryName: string;
  tagCategoryServiceName?: string;
  categoryTags: (GlobalLibraryTag | PracticeLibraryTag)[];
  id: string;
  isActive: boolean;
  isPublished?: boolean;
  isGlobalLibrary?: boolean;
  isViewOnly: boolean;
};

export const TagCategoryListTableRow: FC<TagListTableRowProps> = memo(
  ({
    onClickActivationOptionHandler,
    isActive,
    isPracticeUser,
    tagCategoryName,
    categoryTags,
    id,
    isGlobalLibrary,
    isPublished,
    isViewOnly,
  }) => {
    const tagLabel = `${categoryTags.length} tag${categoryTags.length > 1 ? 's' : ''}`;

    const { navigate } = useRouter();

    const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

    const onCloseThreeDotsMenuHandler = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      handleClosePopover();
    };

    const onClickThreeDotsMenuButtonHandler = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      handleOpenPopover(event);
    };

    const onClickDeleteButtonHandler = (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();

      handleClosePopover();
      onClickActivationOptionHandler({
        id,
        name: tagCategoryName,
        actionType: 'delete',
      });
    };

    const onClickActivationButtonHandler = (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();

      handleClosePopover();
      onClickActivationOptionHandler({
        id,
        name: tagCategoryName,
        actionType: isActive ? 'deactivate' : 'activate',
      });
    };

    const params = `library=${isGlobalLibrary ? 'global' : 'practice'}`;

    const subPath = isGlobalLibrary ? 'gl' : 'pl';
    const onClickTableRowHandler = () => {
      navigate(`/tag-management/${subPath}/${id}`);
    };

    const onClickTagItemHandler = (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();

      navigate(`/tag-management/${id}?${params}&tag=${event.currentTarget.id}`);
    };

    const onClickCopyButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      onClickActivationOptionHandler({
        id,
        name: tagCategoryName,
        actionType: 'copy',
      });
    };

    const renderActionCell = () => {
      if (isViewOnly) return null;

      if (isPracticeUser) {
        return (
          <TableCell sx={{ textAlign: 'center' }}>
            <IconButton onClick={onClickCopyButtonHandler}>
              <Icon type="copy" sx={{ svg: { width: 16, height: 16 }, color: theme => theme.palette.grey[700] }} />
            </IconButton>
          </TableCell>
        );
      }

      return (
        <ThreeDotsTableCell
          open={openPopover}
          tableCellSX={{ textAlign: 'center' }}
          onClickButtonHandler={onClickThreeDotsMenuButtonHandler}
          onClose={onCloseThreeDotsMenuHandler}
          // TODO: uncomment after backend implementation
          // onClickDeleteMenuItemHandler={onClickDeleteButtonHandler}
          menuItems={[
            getEditMenuItem(onClickTableRowHandler),
            getActivateMenuItem(isActive, onClickActivationButtonHandler),
          ]}
        />
      );
    };

    return (
      <TableRow
        onClick={onClickTableRowHandler}
        sx={{
          ...(!isActive && { opacity: '40%' }),
        }}
      >
        <TableCell>
          <Typography noWrap variant="body2">
            {tagCategoryName}
          </Typography>
        </TableCell>

        <TableCell>
          <PublishStatusChip isPublished={isPublished} />
        </TableCell>

        <TableCell>
          {categoryTags?.length ? (
            <HoverMenuPopover
              renderItem={bindedState => (
                <Box {...bindedState}>
                  <Chip variant="soft" color="primary" label={tagLabel} />
                </Box>
              )}
            >
              <Stack direction="row" component="ul" flexWrap="wrap" ml={-1} alignItems="center" maxWidth={203} p={1}>
                {categoryTags?.map((el, index) => {
                  return (
                    <Stack
                      sx={{ ':hover': { cursor: 'pointer' } }}
                      id={el?.name}
                      onClick={onClickTagItemHandler}
                      component="li"
                      pl={1}
                      pb={1}
                      key={el.id}
                    >
                      <Tag
                        size="small"
                        sx={{ '.MuiChip-label': { maxWidth: 175, width: 1 } }}
                        label={el?.name}
                        itemIndex={index}
                      />
                    </Stack>
                  );
                })}
              </Stack>
            </HoverMenuPopover>
          ) : null}
        </TableCell>

        {renderActionCell()}
      </TableRow>
    );
  }
);
