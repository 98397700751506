import { FC } from 'react';

import { Box, Card, Dialog, Divider, Grid, Stack, Table, TableBody, TableContainer } from '@mui/material';
import { getGlobalLibraryTag } from 'apiServices';

import { BaseDialogWindow } from 'components/BaseDialogWindow';
import { FormActions } from 'components/Form';
import { MDInput, MDInputSkeleton } from 'components/MDInput';
import { PopoverWrapper, getActivateMenuItem, getPublishMenuItem } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import {
  CustomTableHeader,
  CustomTablePagination,
  TableFiltersPanel,
  TableNoData,
  TableSkeleton,
} from 'components/Table';
import { EntityHeader, EntityHeaderSkeleton } from 'components/Widgets';
import {
  CategoryTagsFilterPopoverContent,
  CreateTagMenuContent,
  Dialogs,
  EditTagMenuContent,
  TAG_CATEGORY_TAGS_TABLE_HEADER,
  TagsTableRow,
} from 'pages/TagManagement';

import { useGLtagCategoryProfile } from './useGLtagCategoryProfile.state';

export const GlobalLibraryTagCategoryProfile: FC = () => {
  const {
    onChangeSearchInputValue,
    openDeleteDialogWindow,
    searchValue,
    tags,
    register,
    isSubmitButtonDisabled,
    onFormSubmitHandler,
    isSubmitting,
    isLoading,
    isLoadingTags,
    total,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    appliedFilters,
    onApplyFilters,
    isFiltersMenuOpen,
    closeFiltersMenu,
    openFilterMenu,
    actionState,
    closeActivateDialog,
    closeDeactivateDialog,
    isActivateDialogOpen,
    isActionPending,
    isDeactivateDialogOpen,
    onClickActionOptionHandler,
    onActivateTagHandler,
    onDeactivateTagHandler,
    isAddTagMenuOpen,
    openAddTagMenu,
    closeAddTagMenu,
    isEditTagMenuOpen,
    closeEditTagMenu,
    onClickEditOptionMenuHandler,
    selectedTagId,
    isFiltersApplied,
    createTagHandler,
    editTagHandler,
    order,
    orderBy,
    onSort,
    category,
    isDirty,
    tagCategoryActionState,
    isTagCategoryPublished,
    isTagCategoryActive,
    isRoleAdmin,
    onActivateTagCategoryHandler,
    onDeactivateTagCategoryHandler,
    onPublishTagCategoryHandler,
    onUnpublishTagCategoryHandler,
  } = useGLtagCategoryProfile();

  return (
    <Card component="section">
      <Grid container>
        <Grid item xs={12}>
          {isLoading ? (
            <EntityHeaderSkeleton isActiveStatus isActionButton isAvatar={false} />
          ) : (
            <EntityHeader
              title={category?.name}
              publishStutusProps={{
                isPublished: category?.isPublished,
              }}
              activeStatusProps={{
                isActive: category?.isActive,
              }}
              actionMenuItems={
                isRoleAdmin
                  ? [
                      getPublishMenuItem(
                        isTagCategoryPublished,
                        isTagCategoryPublished
                          ? tagCategoryActionState.openUnpublishDialogWindow
                          : tagCategoryActionState.openPublishDialogWindow
                      ),
                      getActivateMenuItem(
                        isTagCategoryActive,
                        isTagCategoryActive
                          ? tagCategoryActionState.openDeactivateDialog
                          : tagCategoryActionState.openActivateDialog
                      ),
                    ]
                  : []
              }
            />
          )}
          <Box component="form">
            <Stack
              width={1}
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2}
              justifyContent="space-between"
              sx={{ '.MuiStack-root': { width: 1 } }}
              p={{ xs: 2, sm: 3, md: 3.5, lg: 4 }}
            >
              {isLoading ? (
                <MDInputSkeleton />
              ) : (
                <MDInput disabled={!isRoleAdmin} required label="Name" placeholder="Enter name" {...register('name')} />
              )}
            </Stack>
            <Divider />

            <Stack px={{ xs: 2, sm: 3, md: 3.5, lg: 4 }} py={{ xs: 2, sm: 3, md: 3.5, lg: 4 }}>
              <Card sx={{ boxShadow: 'none', overflow: 'hidden' }}>
                <TableFiltersPanel
                  searchInputProps={{
                    value: searchValue,
                    placeholder: 'Search by Name',
                    onChange: onChangeSearchInputValue,
                  }}
                  actionButtonProps={isRoleAdmin && { children: 'Add tag', onClick: openAddTagMenu }}
                  onOpenFilterMenuHandler={openFilterMenu}
                  isFiltersApplied={isFiltersApplied}
                />

                <TableContainer sx={{ position: 'relative' }}>
                  <Scrollbar>
                    <Table size="medium" sx={{ width: 1, minWidth: 500 }}>
                      {!isLoadingTags && (
                        <colgroup>
                          <Box component="col" width={1} />
                          <Box component="col" width="20%" />
                        </colgroup>
                      )}
                      <CustomTableHeader
                        headLabel={TAG_CATEGORY_TAGS_TABLE_HEADER.slice(0, isRoleAdmin ? 2 : 1)}
                        order={order}
                        orderBy={orderBy}
                        onSort={onSort}
                      />

                      <TableBody>
                        {isLoadingTags ? (
                          <TableSkeleton cellsAmount={isRoleAdmin ? 2 : 1} />
                        ) : (
                          tags?.map((tag, index) => (
                            <TagsTableRow
                              key={tag?.id}
                              tagName={tag?.name}
                              index={index}
                              isActive={tag?.isActive}
                              id={tag?.id}
                              openDeleteDialogWindow={openDeleteDialogWindow}
                              onClickEditOptionMenuHandler={onClickEditOptionMenuHandler}
                              onClickActivationOptionHandler={onClickActionOptionHandler}
                              isViewOnly={!isRoleAdmin}
                            />
                          ))
                        )}
                        <TableNoData isNotFound={!isLoadingTags && !tags.length} />
                      </TableBody>
                    </Table>
                  </Scrollbar>
                </TableContainer>

                <CustomTablePagination
                  count={total}
                  page={page - 1}
                  rowsPerPage={rowsPerPage}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />
              </Card>
            </Stack>

            <FormActions
              backRoute="auto"
              isDirtyForm={isDirty}
              isDisabled={isSubmitButtonDisabled}
              isLoading={isSubmitting}
              onFormSubmitHandler={onFormSubmitHandler}
            />
          </Box>
        </Grid>
      </Grid>

      {/* TODO after BE implementation */}
      {/* <BaseDialogWindow
        onClickCancelButtonHandler={closeDeleteDialogWindow}
        open={isOpenDeleteDialogWindow}
        description="Are you sure you want to delete tag category?"
        title="Deleting tag category"
        isApproveButtonDisabled={false}
        isApproveButtonLoading={false}
        approveButtonTitle="Delete tag category"
        onClickApproveButtonHandler={() => {}}
      /> */}

      <BaseDialogWindow
        open={tagCategoryActionState.isDeactivateDialogOpen}
        onClickCancelButtonHandler={tagCategoryActionState.closeDeactivateDialog}
        description="Are you sure you want to inactivate tag category?"
        title="Inactivate Tag Category"
        isApproveButtonDisabled={tagCategoryActionState.isActionPending}
        isApproveButtonLoading={tagCategoryActionState.isActionPending}
        approveButtonTitle="Inactivate Tag Category"
        onClickApproveButtonHandler={onDeactivateTagCategoryHandler}
      />

      <BaseDialogWindow
        open={tagCategoryActionState.isActivateDialogOpen}
        onClickCancelButtonHandler={tagCategoryActionState.closeActivateDialog}
        description="Are you sure you want to activate tag category?"
        title="Activate Tag Category"
        isApproveButtonDisabled={tagCategoryActionState.isActionPending}
        isApproveButtonLoading={tagCategoryActionState.isActionPending}
        approveButtonTitle="Activate Tag Category"
        onClickApproveButtonHandler={onActivateTagCategoryHandler}
      />

      <BaseDialogWindow
        open={tagCategoryActionState.isOpenPublishDialogWindow}
        onClickCancelButtonHandler={tagCategoryActionState.closePublishDialogWindow}
        description="Are you sure you want to publish tag category?"
        title="Publish Tag Category"
        isApproveButtonDisabled={tagCategoryActionState.isActionPending}
        isApproveButtonLoading={tagCategoryActionState.isActionPending}
        approveButtonTitle="Publish Tag Category"
        onClickApproveButtonHandler={onPublishTagCategoryHandler}
      />

      <BaseDialogWindow
        open={tagCategoryActionState.isOpenUnpublishDialogWindow}
        onClickCancelButtonHandler={tagCategoryActionState.closeUnpublishDialogWindow}
        description="Are you sure you want to unpublish tag category?"
        title="Unpublish Tag Category"
        isApproveButtonDisabled={tagCategoryActionState.isActionPending}
        isApproveButtonLoading={tagCategoryActionState.isActionPending}
        approveButtonTitle="Unpublish Tag Category"
        onClickApproveButtonHandler={onUnpublishTagCategoryHandler}
      />

      <Dialogs
        isDeactivateDialogOpen={isDeactivateDialogOpen}
        closeDeactivateDialog={closeDeactivateDialog}
        isActivateDialogOpen={isActivateDialogOpen}
        closeActivateDialog={closeActivateDialog}
        isLoading={isActionPending}
        name={actionState.name}
        onActivateTagHandler={onActivateTagHandler}
        onDeactivateTagHandler={onDeactivateTagHandler}
      />

      <PopoverWrapper
        open={isFiltersMenuOpen}
        title="Filter by Tags"
        handleClosePopover={closeFiltersMenu}
        variant="filter"
      >
        <CategoryTagsFilterPopoverContent appliedFilters={appliedFilters} onApplyFilters={onApplyFilters} />
      </PopoverWrapper>

      <Dialog open={isAddTagMenuOpen} onClose={closeAddTagMenu} fullWidth>
        <CreateTagMenuContent createTagHandler={createTagHandler} closeMenuHandler={closeAddTagMenu} />
      </Dialog>

      <Dialog open={isEditTagMenuOpen} onClose={closeEditTagMenu} fullWidth>
        <EditTagMenuContent
          getItemFc={getGlobalLibraryTag}
          editTagHandler={editTagHandler}
          closeMenuHandler={closeEditTagMenu}
          tagId={selectedTagId}
        />
      </Dialog>
    </Card>
  );
};
