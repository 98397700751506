import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

export const SideMenuRoot = styled(Drawer, {
  shouldForwardProp: prop => prop !== 'customWidth',
})<{ customWidth?: number }>(({ theme, customWidth, open }) => {
  const { boxShadows, transitions } = theme;

  const configuratorWidth = customWidth || 400;
  const { lg } = boxShadows;

  const drawerOpenStyles = () => ({
    maxWidth: configuratorWidth,
    width: '100%',
    transition: transitions.create('right', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.short,
    }),
  });

  const drawerCloseStyles = () => ({
    transition: transitions.create('all', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.short,
    }),
  });

  return {
    '& .MuiBackdrop-root': { opacity: '0.4' },
    '& .MuiDrawer-paper': {
      height: '100svh',
      margin: 0,
      padding: 0,
      borderRadius: 0,
      boxShadow: lg,
      overflowY: 'auto',
      ...(open ? drawerOpenStyles() : drawerCloseStyles()),
    },
  };
});
