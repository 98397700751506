import { FC } from 'react';

import { MoreHorizOutlined } from '@mui/icons-material';
import { Button, Divider, Stack } from '@mui/material';

import { Icon } from 'components/Icon';
import MDButton from 'components/MDButton';
import { ActiveStatusChip } from 'components/MDChip';
import { ActionMenuList } from 'components/MenuPopover';
import { usePopover, useRouter } from 'hooks';

import { EntityHeaderActionsProps } from '../types';

export const EntityHeaderActions: FC<EntityHeaderActionsProps> = ({ actionMenuItems, activeStatusProps }) => {
  const { back } = useRouter();

  const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

  const isActionMenuItems = !!actionMenuItems?.length;

  return (
    <>
      <Stack
        mb={1.5}
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        gap={1}
        alignItems="center"
      >
        <Button
          onClick={back}
          variant="text"
          sx={{ fontWeight: 500, color: theme => theme.palette.common.black, fontSize: 14, px: 0.5 }}
          startIcon={<Icon type="arrowLeftIcon" />}
        >
          Back
        </Button>

        <Stack
          gap={1}
          sx={theme => ({
            [theme.breakpoints.up('xs')]: {
              flexDirection: 'row',
            },
          })}
          alignItems="center"
        >
          {activeStatusProps && <ActiveStatusChip {...activeStatusProps} />}

          {isActionMenuItems && activeStatusProps && (
            <Divider sx={{ mx: 0.5, height: 18, alignSelf: 'center' }} flexItem orientation="vertical" />
          )}

          {isActionMenuItems ? (
            <MDButton
              sx={{ minWidth: 'unset', maxWidth: 30, maxHeight: 32 }}
              size="small"
              variant="outlined"
              color="dark"
              onClick={handleOpenPopover}
            >
              <MoreHorizOutlined fontSize="medium" />
            </MDButton>
          ) : null}
        </Stack>
      </Stack>

      <ActionMenuList onClose={handleClosePopover} open={openPopover} menuItems={actionMenuItems} />
    </>
  );
};
