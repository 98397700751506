import { FC } from 'react';

import { MenuItem, MenuItemProps, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

export type PopoverMenuItemProps = MenuItemProps & {
  title: string;
  titleVariant?: Variant;
  element?: React.ReactNode;
  iconEnd?: JSX.Element;
  icon?: JSX.Element;
  itemId?: string;
};

export const PopoverMenuItem: FC<PopoverMenuItemProps> = ({
  title,
  icon,
  titleVariant = 'subtitle2',
  itemId,
  sx,
  element,
  iconEnd,
  ...rest
}) => {
  return (
    <MenuItem
      sx={{
        p: '8px 10px',
        borderRadius: 0,
        svg: {
          width: 16,
          height: 16,
        },
        ...sx,
      }}
      id={itemId}
      {...rest}
    >
      {icon && icon}

      {element ?? (
        <Typography
          variant={titleVariant}
          sx={{
            textTransform: 'capitalize',
            fontSize: 14,
            fontWeight: 400,
            ml: icon ? 1 : 0,
            mr: '2px',
            whiteSpace: 'initial',
          }}
        >
          {title}
        </Typography>
      )}

      {iconEnd && iconEnd}
    </MenuItem>
  );
};
