import { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mui/material';
import { ContactPerson, getPracticeContactPerson, patchPracticeContactPerson } from 'apiServices';
import { Resolver, useForm } from 'react-hook-form';

import { RHFAutocompleteField, RHFPhoneTextField } from 'components/HookForm';
import { MDInput, MDInputSkeleton } from 'components/MDInput';
import { FormMenuPopoverButton } from 'components/MenuPopover';
import { Scrollbar } from 'components/ScrollBar';
import { ToastType, notice } from 'components/ToastNotification';
import { usePracticeEnums } from 'hooks/usePracticeEnums';
import { backendErrorHandler, formErrorHandler } from 'utils/errorHanders';

import {
  CreateContactPersonFormSchema,
  createContactPersonValidationSchema,
} from '../CreateContactPersonMenuContent/form.config';

type EditContactPersonMenuContentProps = {
  closeMenuHandler: VoidFunction;
  practiceId: string;
  fetchPractice: (patientId: string) => Promise<void>;
  contactPersonId: string;
};

export const EditContactPersonMenuContent: FC<EditContactPersonMenuContentProps> = ({
  closeMenuHandler,
  practiceId,
  fetchPractice,
  contactPersonId,
}) => {
  const { practicePersonTitlesEnum, fetchPracticePersonTitlesEnum, isPracticePersonTitlesEnumLoading } =
    usePracticeEnums();

  const [contactPerson, setContactPerson] = useState<ContactPerson | null>(null);

  const [isLoading, setIsLoading] = useState(true);

  const onGetContactPersonHandler = async () => {
    try {
      const { data } = await getPracticeContactPerson(practiceId, contactPersonId);

      setContactPerson(data);
    } catch (error) {
      console.error(error);

      backendErrorHandler({ error, config: { customErrorMessage: 'Failed to get contact person, please try again!' } });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetContactPersonHandler();
    fetchPracticePersonTitlesEnum();
  }, []);

  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors, isValid, isSubmitting, isDirty },
  } = useForm<CreateContactPersonFormSchema>({
    resolver: yupResolver(createContactPersonValidationSchema) as Resolver<CreateContactPersonFormSchema>,
    mode: 'onTouched',
    values: {
      ...contactPerson,
      middleName: contactPerson?.middleName ?? '',
      position: contactPerson?.position ?? '',
    },
  });

  const onFormSubmitHandler = handleSubmit(async ({ contact, ...otherFormDataFields }) => {
    try {
      await patchPracticeContactPerson(practiceId, contactPersonId, {
        ...otherFormDataFields,

        contact: { ...contact, phone: contact?.phone.length > 1 ? `+${contact.phone}` : null },
      });

      await fetchPractice(practiceId);

      notice(ToastType.SUCCESS, 'Contact person has been successfully edited!');
      closeMenuHandler();
    } catch (error) {
      formErrorHandler({
        error,
        customErrorMessage: 'Failed to edit contact person, please try again!',
        config: { formError: { setError } },
      });
    }
  });

  return (
    <Box
      component="form"
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.stopPropagation();
        onFormSubmitHandler(e);
      }}
      sx={{
        height: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Scrollbar style={{ maxHeight: 380 }}>
        {isLoading ? (
          <Stack spacing={2} height={1} py={2}>
            {Array.from({ length: 8 }).map((_, index) => (
              <MDInputSkeleton key={index} />
            ))}
          </Stack>
        ) : (
          <Stack flex={1} spacing={2}>
            <MDInput
              fullWidth
              label="First name"
              placeholder="Enter First Name"
              {...register('firstName')}
              error={!!errors?.firstName?.message}
              helperText={errors?.firstName?.message}
            />

            <MDInput
              fullWidth
              label="Middle name"
              placeholder="Enter Middle Name"
              defaultValue={contactPerson?.middleName}
              {...register('middleName')}
              error={!!errors?.middleName?.message}
              helperText={errors?.middleName?.message}
            />

            <MDInput
              fullWidth
              label="Last name"
              placeholder="Enter Last Name"
              {...register('lastName')}
              error={!!errors?.lastName?.message}
              helperText={errors?.lastName?.message}
            />

            <MDInput
              {...register('position')}
              placeholder="Enter Position"
              fullWidth
              error={!!errors?.position?.message}
              helperText={errors?.position?.message}
              label="Position"
            />

            <RHFAutocompleteField
              control={control}
              name="title"
              label="Title"
              placeholder="Choose Title"
              options={practicePersonTitlesEnum}
              getOptionLabel={option => {
                if (typeof option === 'string') return option;
                return option.value;
              }}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              loading={isPracticePersonTitlesEnumLoading}
            />

            <Stack spacing={2} width={1}>
              <MDInput
                {...register('contact.email')}
                fullWidth
                error={!!errors?.contact?.email?.message}
                helperText={errors?.contact?.email?.message}
                label="Email"
                placeholder="Enter Email"
              />

              <RHFPhoneTextField
                control={control}
                registerErrors={errors}
                registerName="contact.phone"
                country="us"
                label="Phone Number"
                MUITextFieldProps={{ fullWidth: true, sx: { width: 1 } }}
              />

              <MDInput
                {...register(`contact.website`)}
                fullWidth
                error={!!errors?.contact?.website?.message}
                helperText={errors?.contact?.website?.message}
                defaultValue={contactPerson?.contact?.website}
                label="Website"
                placeholder="Enter Website"
              />
            </Stack>
          </Stack>
        )}
      </Scrollbar>

      {!isLoading && (
        <FormMenuPopoverButton disabled={isSubmitting || !isValid || !isDirty} isLoading={isSubmitting} type="submit" />
      )}
    </Box>
  );
};
