import { FC, memo } from 'react';

import { TableCell, TableRow } from '@mui/material';

import { MDChip } from 'components/MDChip';
import { getEditMenuItem } from 'components/MenuPopover';
import { PriorityIndicator } from 'components/PriorityIndicator';
import { ThreeDotsTableCell } from 'components/Table';
import { usePopover } from 'hooks';

type RelatedVisitsTableRowProps = {
  id: string;
  onClickTableRow: () => void;
  onClickEditMenuItem: () => void;
};

export const RelatedVisitsTableRow: FC<RelatedVisitsTableRowProps> = memo(
  ({ id, onClickTableRow, onClickEditMenuItem }) => {
    const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

    const onCloseThreeDotsMenuHandler = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      handleClosePopover();
    };

    const onClickThreeDotsMenuButtonHandler = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      handleOpenPopover(event);
    };

    const onClickDeleteButtonHandler = (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();

      handleClosePopover();
    };

    let isActive = true;

    const onClickEditMenuItemHandler = (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();

      handleClosePopover();
      onClickEditMenuItem();
    };

    return (
      <TableRow onClick={onClickTableRow}>
        <TableCell>April 12, 2024</TableCell>

        <TableCell>Dr. Sarah Johnson</TableCell>

        <TableCell>
          <MDChip isRounded={true} size="small" variant="soft" color="warning" label="Severe" />
        </TableCell>

        <TableCell>
          <PriorityIndicator label="Primary" priorityType="primary" />
        </TableCell>

        <TableCell>Expressed</TableCell>

        <TableCell>Patient consultation initiated</TableCell>

        <ThreeDotsTableCell
          open={openPopover}
          onClickButtonHandler={onClickThreeDotsMenuButtonHandler}
          onClickDeleteMenuItemHandler={onClickDeleteButtonHandler}
          onClose={onCloseThreeDotsMenuHandler}
          menuItems={[getEditMenuItem(onClickEditMenuItemHandler)]}
        />
      </TableRow>
    );
  }
);
