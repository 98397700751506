import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAppStore } from 'store';
import { shallow } from 'zustand/shallow';

import { useCognitoAuthContext } from 'context';
import { useRouter, useUserProfile } from 'hooks';
import { SignInFormSchema, firebaseSignInFormValidationSchema } from 'pages/SignIn';
import { AUTH_STEPS } from 'pages/SignIn/hooks/useSignIn.state';

export const useRecentlyLoggedInUsersSignInState = () => {
  const { signInCognito, getPracticeProfileAndLegalDocsHandler, getCognitoCurrentUser } = useCognitoAuthContext();
  const { cognitoUser } = useUserProfile();

  const { back } = useRouter();

  useEffect(() => {
    if (!selectedRecentlyLoggedInUser || cognitoUser) back();
  }, []);

  const { selectedRecentlyLoggedInUser, setSelectedRecentlyLoggedInUser } = useAppStore(
    store => ({
      setSelectedRecentlyLoggedInUser: store.setSelectedRecentlyLoggedInUser,
      selectedRecentlyLoggedInUser: store.selectedRecentlyLoggedInUser,
    }),
    shallow
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    reset,
  } = useForm<Pick<SignInFormSchema, 'password'>>({
    mode: 'onTouched',
    resolver: yupResolver(firebaseSignInFormValidationSchema.omit(['email'])),
    defaultValues: { password: '' },
  });

  const [authStep, setAuthStep] = useState<AUTH_STEPS>(AUTH_STEPS.SIGN_IN);

  const resetAuthStep = () => setAuthStep(AUTH_STEPS.SIGN_IN);

  const removeRecentlyLoggedInUser = () => setSelectedRecentlyLoggedInUser(null);

  const onFormSubmitHandler = handleSubmit(async ({ password }) => {
    await signInCognito({
      email: selectedRecentlyLoggedInUser?.email,
      password,
      onTOTPRequiedHandler: () => {
        setAuthStep(AUTH_STEPS.VERIFY_TOTP_CODE);
        reset();
      },
      onSuccessfulSignInHandler: removeRecentlyLoggedInUser,
    });
  });

  return {
    getPracticeProfileAndLegalDocsHandler,
    getCognitoCurrentUser,
    onFormSubmitHandler,
    authStep,
    resetAuthStep,
    errors,
    isValid,
    isSubmitting,
    register,
    selectedRecentlyLoggedInUser,
    removeRecentlyLoggedInUser,
  };
};
