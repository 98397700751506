import { FC, memo } from 'react';

import { TableCell, TableRow } from '@mui/material';

import { getActivateMenuItem, getEditMenuItem } from 'components/MenuPopover';
import { ThreeDotsTableCell } from 'components/Table';
import { Tag } from 'components/Tag';
import { ActionDataInitialState } from 'hooks/useActionDialogManagement';
import { usePopover } from 'hooks/usePopover';

type TagsTableRowProps = {
  id: string;
  tagName: string;
  index: number;
  isActive: boolean;
  openDeleteDialogWindow: () => void;
  onClickActivationOptionHandler: (activationStateData: ActionDataInitialState) => void;
  onClickEditOptionMenuHandler: (tagId: string) => void;
  isViewOnly: boolean;
};

export const TagsTableRow: FC<TagsTableRowProps> = memo(
  ({
    id,
    openDeleteDialogWindow,
    isActive,
    onClickActivationOptionHandler,
    tagName,
    index,
    onClickEditOptionMenuHandler,
    isViewOnly,
  }) => {
    const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

    const onCloseThreeDotsMenuHandler = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      handleClosePopover();
    };

    const onClickThreeDotsMenuButtonHandler = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      handleOpenPopover(event);
    };

    const onClickDeleteButtonHandler = (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();

      handleClosePopover();
      openDeleteDialogWindow();
    };

    const onClickActivationButtonHandler = (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();

      handleClosePopover();
      onClickActivationOptionHandler({
        id,
        name: tagName,
        actionType: isActive ? 'deactivate' : 'activate',
      });
    };

    const onClickEditButtonHandler = () => {
      onClickEditOptionMenuHandler(id);
      handleClosePopover();
    };

    return (
      <TableRow
        sx={{
          ':hover': {
            cursor: 'default',
          },
          ...(!isActive && { opacity: '40%' }),
        }}
      >
        <TableCell>
          <Tag size="small" label={tagName} itemIndex={index} />
        </TableCell>

        {/* TODO finish after Backend implementation*/}
        {/* <TableCell>
        <LinkedCatalogItems />
      </TableCell>

      <TableCell>
        <LinkedContentStatistic />
      </TableCell> */}

        {!isViewOnly && (
          <ThreeDotsTableCell
            open={openPopover}
            onClickButtonHandler={onClickThreeDotsMenuButtonHandler}
            onClose={onCloseThreeDotsMenuHandler}
            // TODO finish after Backend implementation
            // onClickDeleteMenuItemHandler={onClickDeleteButtonHandler}
            menuItems={[
              getEditMenuItem(onClickEditButtonHandler),
              getActivateMenuItem(isActive, onClickActivationButtonHandler),
            ]}
          />
        )}
      </TableRow>
    );
  }
);
