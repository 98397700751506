import { FC } from 'react';

import { MDChip, MDChipProps } from '../MDChip';

export type PublishStatusChipProps = MDChipProps & {
  isPublished: boolean;
};

export const PublishStatusChip: FC<PublishStatusChipProps> = ({ isPublished, ...chipProps }) => {
  return (
    <MDChip
      size="small"
      variant="soft"
      color={isPublished ? 'success' : 'default'}
      label={isPublished ? 'Published' : 'Unpublished'}
      isRounded={false}
      {...chipProps}
    />
  );
};
